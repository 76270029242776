import PropTypes from 'prop-types';
import React, { useEffect, useRef, useCallback } from 'react';
import { Group, Layer, Stage as KonvaStage } from 'react-konva';
import { clampNumber } from '../../utils';
import { ModeIcon } from '../mode-icon';
//import { SpinnerShape } from '../spinner-shape';
import { MODE } from '../view-loader';
import { StageModeDisplayStyled } from './stage.styled';

const MODE_ICON_SIZE = 14;

const ZOOM_THRESHOLD = {
  min: 0.2,
  max: 6.0,
};

const Stage = ({
  zoom,
  mode,
  isLoading,
  dragStage,
  width,
  height,
  zoomByScale,
  onStageWheel,
  onStageMouseDown,
  onStageMouseUp,
  onStageMouseMove,
  onStageMouseLeave,
  onStageContextMenu,
  onZoom,
  ...props
}) => {
  const stageRef = useRef(null);
  const groupRef = useRef(null);
  const cursorRef = useRef('auto');

  const zoomGroup = (event) => {
    if (mode === MODE.zoom) {
      event.evt.preventDefault();
      const scaleBy = 1.03;
      const stage = stageRef.current;
      const group = groupRef.current;
      const oldScale = group.scaleX();

      const pointer = stage.getPointerPosition();

      const mousePointTo = {
        x: (pointer.x - group.x()) / oldScale,
        y: (pointer.y - group.y()) / oldScale,
      };

      const newScale =
        event.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;

      const clampedScale = clampNumber(
        newScale,
        ZOOM_THRESHOLD.min,
        ZOOM_THRESHOLD.max
      );

      const newPos = {
        x: pointer.x - mousePointTo.x * clampedScale,
        y: pointer.y - mousePointTo.y * clampedScale,
      };

      // If there is a zoom handler, use it
      if (onZoom) {
        // Report back the new zoom level, position and event that triggered it.
        onZoom(clampedScale, newPos, event);
      } else {
        scaleGroup(stage, group, clampedScale, newPos);
      }
    }
  };

  const zoomGroupToScale= (scale, point) =>{
    console.log('zooming to:', scale, point);
    const stage = stageRef.current;
    const group = groupRef.current;
    const oldScale = group.scaleX();

    const pointTo = {
      x: (point.x - group.x()) / oldScale,
      y: (point.y - group.y()) / oldScale
    };


    const clampedScale = clampNumber(scale, ZOOM_THRESHOLD.min, ZOOM_THRESHOLD.max);
    const newPos = {
      x:point.x - pointTo.x*clampedScale,
      y:point.y - pointTo.y*clampedScale
    };
    scaleGroup(stage, group, clampedScale, newPos);
  }

  useEffect(()=>{
    if(!zoomByScale) return;
    zoomGroupToScale(zoomByScale.scale, zoomByScale.point);
  }, [zoomByScale]);

  const scaleGroup = (stage, group, scale, position) => {
    // Update the group based on the new zoom level and position
    group.scale({ x: scale, y: scale });
    group.position(position);
    // rate limited redraw
    stage.batchDraw();
  };

  const handleDragStart = useCallback(()=>{
    if(mode === MODE.zoom){
      const stageContainer = stageRef.current.container();
      cursorRef.current = stageContainer.style.cursor;
      stageContainer.style.cursor = 'grabbing';
    }
  },[mode, stageRef, cursorRef]);

  const handleDragEnd = useCallback(()=>{
    if(mode === MODE.zoom) stageRef.current.container().style.cursor = cursorRef.current;
  },[mode, stageRef, cursorRef]);

  useEffect(() => {
    if (mode === MODE.zoom && zoom) {
      const stage = stageRef.current;
      const group = groupRef.current;
      scaleGroup(stage, group, zoom.zoomLevel, zoom.position);
    }
  }, [zoom, mode]);

  useEffect(()=>{
    if(!groupRef.current || !stageRef.current) return;
    if(dragStage){
      const stageContainer = stageRef.current.container();
      cursorRef.current = stageContainer.style.cursor;
      stageContainer.style.cursor = 'grabbing';
      groupRef.current.startDrag()
    }
    else{
      stageRef.current.container().style.cursor = cursorRef.current;
      groupRef.current.stopDrag()
    }
  },[dragStage,groupRef,stageRef])

  return (
    <>
      <div style={{position:'absolute',zIndex:100}}>
        <StageModeDisplayStyled hidden={mode === null}>
          <ModeIcon mode={mode} size={MODE_ICON_SIZE} />
        </StageModeDisplayStyled>
      </div>
      <KonvaStage
        width={width}
        height={height}
        onWheel={onStageWheel}
        onMouseDown={onStageMouseDown}
        onMouseUp={onStageMouseUp}
        onMouseMove={onStageMouseMove}
        onMouseLeave={onStageMouseLeave}
        onContextMenu={onStageContextMenu}
        ref={stageRef}
      >
        <Layer>
          <Group
            ref={groupRef}
            draggable={mode===MODE.zoom}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onWheel={zoomGroup}
          >
            {props.children}
          </Group>
          {/*
          {isLoading && <SpinnerShape x={width / 2} y={height / 2} />}
          */}
        </Layer>
      </KonvaStage>
    </>
  );
};

Stage.propTypes = {
  mode: PropTypes.string,
  width: PropTypes.number.isRequired, // KonvaStage component requires a width
  height: PropTypes.number.isRequired, // KonvaStage component requires a height
  zoom: PropTypes.shape({
    zoomLevel: PropTypes.number,
    position: PropTypes.shape({ x: PropTypes.number, y: PropTypes.number }),
  }),
  isLoading: PropTypes.bool,
  onStageWheel: PropTypes.func,
  onStageMouseDown: PropTypes.func,
  onStageMouseUp: PropTypes.func,
  onStageMouseMove: PropTypes.func,
  onStageMouseLeave: PropTypes.func,
  onStageContextMenu: PropTypes.func,
  onZoom: PropTypes.func,
};

Stage.defaultProps = {
  width: 400,
  height: 400,
  isLoading: false,
};

export { Stage };
