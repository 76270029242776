import React from 'react';
import { Popup } from 'semantic-ui-react'
import * as math from 'mathjs';
var THREE = window.THREE = require('three');

/**
 * Function returns pointer position relative to the passed node
 * @param {Konva.Node} node - Konva.Node
 * @returns {{ x: number, y: number}} pos - relative point
 */
export function getRelativePointerPosition(node) {
  const transform = node.getAbsoluteTransform().copy();
  // to detect relative position we need to invert transform
  transform.invert();
  // get pointer (say mouse or touch) position
  const pos = node.getStage().getPointerPosition();
  // now we can find relative point
  return transform.point(pos);
}

export function isCanvas(el) {
  return el instanceof HTMLCanvasElement;
}

export function voxelsToMm(spacing, obj){
	if(spacing.length !== 3) return console.error("invalid spacing sent to voxelsToMm");
	if(Array.isArray(obj)){
		let rv = [];
		if(Object.keys(obj[0]).length !== 0){
			for(let i = 0; i < obj.length; i++){
				rv.push(voxelsToMm(spacing,obj[keys[i]]));
			}
		}
		else{
			if(obj.length !== 3) return console.error("invalid field in voxelsToMm");
			for(let i = 0; i < 3; i++){
				rv.push(obj[i]*spacing[i]);
			}
		}
		return rv;
	}
	let keys = Object.keys(obj);
	//error if primitive
	if(keys.length === 0) return console.error("invalid object sent to voxelsToMm");
	let rv = {};
	if(Object.keys(obj[keys[0]]).length !== 0){
		for(let i = 0; i < keys.length; i++){
			rv[keys[i]] = voxelsToMm(spacing,obj[keys[i]]);
		}
	}
	else{
		if(keys.length !== 3) return console.error("invalid field in voxelsToMm");
		for(let i = 0; i < 3; i++){
			rv[keys[i]] = obj[i]*spacing[i];
		}
	}
	return rv;
}

export function mmToVoxels(spacing, obj){
	if(spacing.length !== 3) return console.error("invalid spacing sent to mmToVoxels");
	if(Array.isArray(obj)){
		let rv = [];
		if(Object.keys(obj[0]).length !== 0){
			for(let i = 0; i < obj.length; i++){
				rv.push(mmToVoxels(spacing,obj[keys[i]]));
			}
		}
		else{
			if(obj.length !== 3) return console.error("invalid field in mmToVoxels");
			for(let i = 0; i < 3; i++){
				rv[keys[i]] = obj[i]/spacing[i];
			}
		}
		return rv;
	}
	let keys = Object.keys(obj);
	//error if primitive
	if(keys.length === 0) return console.error("invalid object sent to mmToVoxels");
	let rv = {};
	if(Object.keys(obj[keys[0]]).length !== 0){
		for(let i = 0; i < keys.length; i++){
			rv[keys[i]] = mmToVoxels(spacing,obj[keys[i]]);
		}
	}
	else{
		if(keys.length !== 3) return console.error("invalid field in mmToVoxels");
		for(let i = 0; i < 3; i++){
			rv.push(obj[i]/spacing[i]);
		}
	}
	return rv;
}

export function sortDct(dct){
	let keys = dct.sort()
	let sorted = {}
	for (let i=0; i<keys.length; i++){
		sorted[keys[i]] = dct[keys[i]]
	}
	return sorted
}

export function len(dct){
	return(
		Object.keys(dct).length
	)
}

export function getPopup(trigger, content, params){
	params = (params) ? params : {}
	params.inverted = true
	params.on = (params.on) ? params.on:'hover'
	params.trigger = trigger
	params.content = content
	if (!content){
		return(trigger)
	}
	else {
		return(
			<Popup {...params}/>
		)
	}
}

export function getDdOptions(k, options, type, selected){
	let configured = []
	selected = (selected) ? selected : [] //make list if null for .includes
	if (type === "list"){
		configured = options.map((opt) => (
			{
				key:opt, text:opt, value:opt,
				className:"ddItem A LIST",
				active:false,
				selected:selected.includes(opt),
				//selected:false, //these mess with styling otherwise
			}
		))
	}
	else {
		Object.entries(options).map(([opt, v]) => {
			let text
			if (false){//TODO (v.hover){
				text = getPopup(v.ui_name, 'TESTING')
			}
			else {
				text = v.ui_name
			}
			configured.push(
				{
					key:`${opt}${k}`, text:text, value:opt,
					className:"ddItem",
					active:false,
					selected: selected.includes(opt)
					//selected:false, //these mess with styling otherwise
				}
			)
		})
	}
	return configured
}

var BASE64_MARKER = ';base64,';

export function convertDataURIToBinary(str) {
	var buf = new ArrayBuffer(str.length*2); // 2 bytes for each char
	var bufView = new Uint16Array(buf);
	for (var i=0, strLen=str.length; i < strLen; i++) {
	bufView[i] = str.charCodeAt(i);
	}
	return buf;
}


// determine whether a chracter is a letter or number
export function isLetter(char){
char = String(char)
return (char.toUpperCase() !== char.toLowerCase())
}


// determine whether a chracter is a lower case letter
export function isLowerCaseLetter(char){
char = String(char)
return (isLetter(char) && (char === char.toLowerCase()))
}


// get the base name of a vessel ID - EG: AM1 -> AM
export function vesselBaseName(vesselID){
const lastChar = vesselID.slice(vesselID.length - 1)
if (isLowerCaseLetter(lastChar) || (!isLetter(lastChar))){
	return vesselBaseName(vesselID.slice(0, -1))
}
else {
	return vesselID
}
}

// Creates a map between a provided 1D range and corresponding colours at
// points in that range (used to colour different types of risk differently)
export function linear1dColorMap (colorProfile, value) {
for (var i = 0; i < colorProfile.length - 1; i++) {
	// This is the colour range that the provided colour could be in
	let start = colorProfile[i];
	let end   = colorProfile[i + 1];

	// Is it in this range? If so provide interpolated colour
	if (start.value <= value && value < end.value) {
	// Calculate amount to lerp from start colour to end then lerp
	let interval = (value - start.value) / (end.value - start.value);
	// Copy, don't mutate starting color, and don't lerp HSL, unless you
	// want to go disco
	let s = new THREE.Color(start.color);
	let e = new THREE.Color(end.color);
	let interp = s.lerp(e, interval);
	/*
	// For debugging with colour in console
	console.log(start.value, value, end.value, interval);
	console.log("%cS", 'color:#' + s.getHexString())
	console.log("%cM", 'color:#' + interp.getHexString())
	console.log("%cE", 'color:#' + e.getHexString())
	*/
	return interp;
	}
}
console.log("Warning, provided value ", value, " outside of provided color map value range, returning closest");
if (value <= colorProfile[0].value) {
	return new THREE.Color(colorProfile[0].color);
} else if (value >= colorProfile[colorProfile.length - 1].value) {
	return new THREE.Color(colorProfile[colorProfile.length - 1].color);
}
console.log("Error, provided value ", value, " not compatible, returning null")
return null;
}

// Simple reshaper, useful for reshaping flattened h5s for example
export function reshapeArray1d (matrix, dimensions) {
return math.reshape(matrix, dimensions)
}

// Simple matrix transposer, useful for flipping image dimensions
export function transposeMatrix2d (matrix) {
// This implementation should return original matrix after 2 tranposes, not
// rot by pi
return matrix[0].map((col, i) => matrix.map(row => row[i]));
}

// Instantiates a simple all zeros 2d matrix
export function createZeros2d (rows, cols) {
return Array(rows).fill().map(() => Array(cols).fill(0));
}

// Slice a 3D volume wrt to a given axis, at a given slice index
// TODO there must be a better way to account for different axes/slice ordering
export function sliceMatrix3d (matrix, axis, sliceidx) {
if (sliceidx === null) {
	console.log("Error: slice index undefined");
	return;
}

let res = null;
let dims = [matrix.length, matrix[0].length, matrix[0][0].length];
if (axis === 0) {
	if (sliceidx >= 0 && sliceidx < dims[0]) {
	res = createZeros2d(dims[1], dims[2]);
	for (var i = 0; i < res.length; i++) {
		for (var j = 0; j < res[0].length; j++) {
		res[i][j] = matrix[sliceidx][i][j];
		}
	}

	}
	else {
	console.log("Error: slice index " + sliceidx + " outside of range for matrix "
		+ "with dimensions " + dims);
	}

}
else if (axis === 1) {
	if (sliceidx >= 0 && sliceidx < dims[1]) {
	res = createZeros2d(dims[2], dims[0]);
	for (var i = 0; i < res.length; i++) {
		for (var j = 0; j < res[0].length; j++) {
		res[i][j] = matrix[j][sliceidx][i];
		}
	}

	}
	else {
	console.log("Error: slice index " + sliceidx + " outside of range for matrix "
		+ "with dimensions " + dims);
	}

}
else if (axis === 2) {
	if (sliceidx >= 0 && sliceidx < dims[2]) {
	res = createZeros2d(dims[0], dims[1]);
	for (var i = 0; i < res.length; i++) {
		for (var j = 0; j < res[0].length; j++) {
		res[i][j] = matrix[i][j][sliceidx];
		}
	}

	}
	else {
	console.log("Error: slice index " + sliceidx + " outside of range for matrix "
		+ "with dimensions " + dims);
	}

}
else {
	console.log("Error: " + axis + " is not a valid axis in range [0,2]")
}

if (!res) {
	console.log("Error: there was an error during slicing which produced an undefined result: " + res)
}
return res;
}

// Slice a 3D volume parallel to the zeroth axis around some angle. Return a
// width x height matrix. Angle must be in radians
// TODO take into account aggregate/overwritten sampling
export function sliceMatrix3dAtAngle (matrix, angle, width) {
// Height is axis length in zeroth direction
let height = matrix.length;

// Will be written into
let res = createZeros2d(height, width);

// Consider the slicing origin and angle per slice
let halfX = matrix[0].length    / 2.0;
let halfY = matrix[0][0].length / 2.0;
let grad = Math.tan(angle); // Every one across, is this many up
//console.log(grad);

// Sampling plane should always be same length, to avoid squishing, this is
// the max we can get for all orientations:
let hyp = halfX;
// This handles directional discontinuity introduced from a pure gradient
// based method
let rateX = 2 * (Math.cos(angle) * hyp) / width;
let rateY = 2 * (Math.sin(angle) * hyp) / width;

// Fill out result matrix
for (var i = 0; i < height; i++) {
	for (var j = 0; j < width; j++) {
	// Get sampling position in input matrix for desired
	// point in output matrix
	let p = j - width / 2.0;
	let x = Math.floor(halfX + p * rateX);
	let y = Math.floor(halfY + p * rateY);
	if (x >= 0 && x < matrix[0].length && y >= 0 && y < matrix[0][0].length) {
		res[i][j] = matrix[i][x][y];
	}
	}
}
return res;
}

// Returns an empty buffer of a given shape for writing to
export function	getEmptyBuffer(width, height = 1) {
	return new Uint8ClampedArray(width * height * 4);
}

// Places a greyscale matrix into a given buffer (which must have matching
// dimensions) after windowing into an 8 bit range [0, 255]
export function addWindowedMatrix2dToBuffer(buffer, matrix, wl, ww) {
// TODO check matching dimensions first

// Prepare for windowing
	var wmin = wl - ww / 2.0;
	var wmax = wl + ww / 2.0;

	// Draw matrix into buffer in range [0, 255] depending on windowing
let shape = [matrix.length, matrix[0].length];
	for(var r = 0; r < shape[0]; r++) {
	for(var c = 0; c < shape[1]; c++) {
		// Position in volume array based on row and col index
		var volpos = (r * shape[1] + c);
		var bufpos = volpos * 4;

		// Windowed value
		var wvalue = ((matrix[r][c] - wmin) / (wmax - wmin)) * (255 - 0) + 0;

		// RGBA values respectively
		buffer[bufpos+0] = wvalue;
		buffer[bufpos+1] = wvalue;
		buffer[bufpos+2] = wvalue;
		buffer[bufpos+3] = 255;
	}
	}

	return buffer;
}

// Scales up a matrix by a given factor and returns the scaled matrix, concat
// shown experimentally to complete this operation the fastest
// TODO implement NN and Bilinear Interp
export function resizeMatrix(array, factor) {
let res = createZeros2d(
	Math.ceil(array.length * factor),
	Math.ceil(array[0].length * factor)
);
for (var i = 0; i < res.length; i++) {
	for (var j = 0; j < res[0].length; j++) {
	res[i][j] = array[Math.floor(i/factor)][Math.floor(j/factor)];
	}
}
return res;
}

// Translates elements of matrix across and up/down by dx and dy
export function translateMatrix(matrix, dx, dy) {
// Copy, not by reference
let res = matrix.map(o => [...o]);
while (dx > 0) {
	res.forEach(function (a) {
	a.pop();
	a.unshift(null);
	});
	dx--;
}
while (dx < 0) {
	res.forEach(function (a) {
	a.shift();
	a.push(null);
	});
	dx++;
}
while (dy > 0) {
	res.unshift(res.pop().map(function () { return null; }));
	dy--;
}
while (dy < 0) {
	res.push(res.shift().map(function () { return null; }));
	dy++;
}
return res;
}

// Zoom in, zoom out on the centre of a matrix, but do not resize
export function scaleMatrixContents(matrix, zoom) {
// Write into matrix of same size - this greatly improves speed in our
// application, and the region outside the matrix bounds is never rendered
// anyway
let res = createZeros2d(matrix.length, matrix[0].length);

// Scale around centre
let offsetX = matrix.length    / 2.0;
let offsetY = matrix[0].length / 2.0;

// Calculate how many onsecutive elements to write into when zooming in
let cons = Math.ceil(1.0 / zoom);

// Write into result matrix
for (var i = 0; i < res.length; i++) {
	for (var j = 0; j < res[0].length; j++) {
	// Fill in consecutive elements if their are any
	for (var k = 0; k < cons; k++) {
		for (var l = 0; l < cons; l++) {
		// Get region which we'll write matrix data into
		let x = Math.floor(((i - offsetX) / zoom) + offsetX) + k;
		let y = Math.floor(((j - offsetY) / zoom) + offsetY) + l;

		// Write into result if in range, and we need to interpolate empties
		if (x >= 0 && x < res.length && y >= 0 && y < res[0].length) {
			res[x][y] = matrix[i][j];
		}
		}
	}
	}
}
return res;
}

// A helper method which writes a given colour into a buffer at some given set
// of positions
export function writeToBufferAtPositions(buffer, rows, cols, positions,
										colhex, radius=0) {
let col = new THREE.Color(colhex);

// TODO bounds check
for (var i = 0; i < positions.length; i++) {
	let x = positions[i][0];
	let y = positions[i][1];

	// Fill in the requested 'radius'
	for (var j = -Math.floor(radius); j < Math.ceil(radius + 1); j++) {
	for (var k = -Math.floor(radius); k < Math.ceil(radius + 1); k++) {
		let xx = x + j;
		let yy = y + k;

		// Position in volume array based on row and col index
		let volpos = (yy * cols + xx);
		let bufpos = volpos * 4;

		// Don't draw if out of range of buffer or matrix - to preven wrap
		// around
		if (bufpos >= 0 && bufpos < buffer.length
			&& xx >= 0  && x < cols
			&& yy >=0   && y < rows) {
		// RGBA values respectively written in
		buffer[bufpos+0] = col.r * 255;
		buffer[bufpos+1] = col.g * 255;
		buffer[bufpos+2] = col.b * 255;
		buffer[bufpos+3] = 255; // TODO add alpha option
		}
	}
	}
}

return buffer;
}

// A helper method which writes a given colour into a buffer at points
// interpolated between a given ordered set of positions
export function writeToBufferBetweenPositions(buffer, rows, cols, positions,
											colhex,
											numBetween=3, closed=false, radius=0) {
// If there's only one point then just plot it and be done
if (positions.length === 0) {
	console.log("Warning: 0 positions fed to 'writeToBufferBetweenPositions'")
	return buffer;
} else if (positions.length === 1) {
	return writeToBufferAtPositions(buffer, rows, cols,
									positions, colhex, radius);
}

// If closed then we want the spline to wrap around, so drop the first in
// last
if (closed) {
	positions.push(positions[0]);
}

// Fit a spline curve to points and resample to get a nice smooth line
let vec2s = [];
for (var i = 0; i < positions.length; i++) {
	vec2s.push(new THREE.Vector2( positions[i][0], positions[i][1] ));
}
var curve = new THREE.SplineCurve(vec2s);

// Resample the curve and discretise
var resampled = curve.getPoints(numBetween * positions.length);
var resampledPositions = [];
for (var i = 0; i < resampled.length; i++) {
	resampledPositions.push([
	Math.floor(resampled[i].x),
	Math.floor(resampled[i].y)
	]);
}

// Feed resampled to a base function which will write in data at points
return writeToBufferAtPositions(buffer, rows, cols,
								resampledPositions, colhex, radius);
}
