import styled from 'styled-components';
import { colors, timingsMs } from '../../styles';

export const StageModeDisplayStyled = styled.span`
  background-color: ${colors.black30};
  color: ${colors.white};
  pointer-events: none;
  /*below are effectively all magic numbers*/
  padding-top:4px;
  padding-left:2px;
  padding-right:2px;
  position:relative;
  top:3px;
  left:0;
`;

export const StageWrapperStyled = styled.div`
  box-shadow: inset 0 0 0 1px #ccc;
  min-height: 200px;
  position: relative;
  width: 200px;

  ${StageModeDisplayStyled} {
    border-top-left-radius: 3px;
    opacity: 0;
    position: absolute;
    bottom: 1px; /* avoid overlapping the box-shadow */
    right: 1px; /* avoid overlapping the box-shadow */
    transition: opacity ${timingsMs.fast} ease-in-out;
    z-index: 1;
  }

  &:hover ${StageModeDisplayStyled} {
    opacity: 1;
    transition-duration: ${timingsMs.faster};
  }
`;

export const StageDebugStyled = styled.span`
  &::before {
    background-color: ${colors.black20};
    color: ${colors.white};
    content: attr(data-slice-num);
    font-variant-numeric: tabular-nums;
    padding: 0 4px;
    pointer-events: none;
    position: absolute;
    right: 1px; /* avoid overlapping the box-shadow */
    top: 1px; /* avoid overlapping the box-shadow */
    z-index: 1;
  }
`;
