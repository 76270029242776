import React from 'react';
import Amplify, { Auth, AWSS3 } from 'aws-amplify';
import { ContrastScanSlicer, NonContrastScanSlicer, CprSlicer, MprSlicer, SmprSlicer } from './components/slicer'
import { SmartSlicer } from './components/smart_slicer'
import { ContrastSmartSlicer, NonContrastSmartSlicer } from './components/smart_slicer'
import Model from './components/model'
import Legend from './components/legend'
import { SimpleWindower } from './components/windower'
import { pdfReport } from './components/report'
import { PDFViewer } from '@react-pdf/renderer';


class C extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
	}

	componentDidMount(){
	}

  render(){
    return <div>
      <ContrastSmartSlicer
        patientID={"ENV_000262"}
        runID={"20200318_1"}
        local={false}
        availableVessels={["LAD", "RCA", "D1", "LCX"]}
        windowlevel={740}
        windowwidth={2000}
      />
    </div>
    /*
    return (
      <PDFViewer style={{height: "1000px", width: "700px"}}>
        {pdfReport(
          {
            title: 'test'
          }, null,
          {
            LAD: {
              stats: {
                "CAD-RADS": "1",
                "Calcium Score": "~",
                "Leiden Score": "1.1",
                "Maximum Stenosis": "< 25%",
                "Plaque Volume": "11.91mm",
                "Vulnerable Plaque": "Absent"
              }
            },
            LCX: {
              stats: {
                "CAD-RADS": "1",
                "Calcium Score": "~",
                "Leiden Score": "1.1",
                "Maximum Stenosis": "< 25%",
                "Plaque Volume": "11.91mm",
                "Vulnerable Plaque": "Absent"
              }
            },
            RCA: {
              stats: {
                "CAD-RADS": "1",
                "Calcium Score": "~",
                "Leiden Score": "1.1",
                "Maximum Stenosis": "< 25%",
                "Plaque Volume": "11.91mm",
                "Vulnerable Plaque": "Absent"
              }
            },
            LM: {
              stats: {
                "CAD-RADS": "1",
                "Calcium Score": "~",
                "Leiden Score": "1.1",
                "Maximum Stenosis": "< 25%",
                "Plaque Volume": "11.91mm",
                "Vulnerable Plaque": "Absent"
              }
            }
          }
        )}
      </PDFViewer>
    )
    */
  }


}

export default C
