import React, { Component } from 'react';
import Amplify from 'aws-amplify';
import { Icon, Button } from 'semantic-ui-react';
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import * as utils from './utils';
import { clampNumber } from '../utils'

class SimpleWindower extends Component {
  constructor (props) {
    super(props);

    // Setup some constants here
    // Bounds on sliders
    this.wlMin = -100;
    this.wlMax = 1000;
    this.wwMin = 100;
    this.wwMax = 1500;

    // Maintain a state of the current windowing parameters

	// For console testing
	window.test_simple_windower = this;

  }

  render () {
    return (
        <div className="windower">

            <span>Window level:</span>
            <input
				type="number"
				min={this.wlMin}
				max={this.wlMax}
				value={this.props.windowLevel}
				onChange={(e) =>{
					let val = parseInt(e.target.value);
					if(Number.isNaN(val)) return;
					val = clampNumber(val, this.wlMin, this.wlMax);
					this.props.changeStateDct({'windowLevel': val});
				}}
            />

            <Slider
				horizontal
				value={this.props.windowLevel}
				min={this.wlMin}
				max={this.wlMax}
				onChange={(e) => this.props.changeStateDct({'windowLevel': e})}
            />

            <span>Window width:</span>
            <input
				type="number"
				min={this.wwMin}
				max={this.wwMax}
				value={this.props.windowWidth}
				onChange={(e) =>{
					let val = parseInt(e.target.value);
					if(Number.isNaN(val)) return;
					val = clampNumber(val, this.wwMin, this.wwMax);
					this.props.changeStateDct({'windowWidth': val});
				}}
            />

			<Slider
				horizontal
				value={this.props.windowWidth}
				min={this.wwMin}
				max={this.wwMax}
				onChange={(e) => this.props.changeStateDct({'windowWidth': e})}
			/>

      </div>
    )

  }
}

export {SimpleWindower};
