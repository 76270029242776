import React, { Component } from 'react';
import { getH5Data } from './h5'
import Amplify from 'aws-amplify';
import Legend from './legend'
import * as utils from './utils'
import TWEEN from '@tweenjs/tween.js';
import { Dropdown } from 'semantic-ui-react'

var THREE = window.THREE = require('three');
require('three/examples/js/controls/OrbitControls')
require('three/examples/js/modifiers/SimplifyModifier');
require('three/examples/js/loaders/PLYLoader')

// Shows helpers and other useful visual debugging info
const DEBUG_MODE = false;

// UI camera repositioning
const TIME_TARGET_TWEEN 	= 2000;	// Actually doesn't matter in this build
const TIME_POSITION_TWEEN = 1500;

const COL_LIGHT_DIR  = '#FFFFFF';
const COL_LIGHT_AMB  = '#404040';
const INT_LIGHT_DIR = 0.05;
const INT_LIGHT_AMB = 0.1;

// TODO precalculate lookup tables for speed
// Profiles are points in RGB space and are interpolated between so
// they look smooth, not piecewise
var   COL_MAP_STENOSIS = null; // Loaded from props
var   COL_MAP_LEIDEN = null;
const COL_DEFAULT = '#821F1F';
const COL_PLAQUE = '#CFBF57';
const COL_BACKGROUND = '#131313';  // should match css var(--secondary_bg)
//const COL_BACKGROUND = '#FFFFFF';
const COL_LABEL = '#FFFFFF';
const COL_WIREFRAME = '#E0E0E0';
const COL_ERROR = '#00FFFF';  // If its this pink color, something is wrong!
const COL_SLICER = '#FFFFFF';
const COL_LABEL_LINES = '#FFFFFF';

	// Instantiating directed lights
	// TODO: Should be in a utils library
	function makeDirLight (position, target, intensity) {
	var dirLight = new THREE.DirectionalLight(COL_LIGHT_DIR, intensity);
	dirLight.position.set(position.x, position.y, position.z);
	dirLight.target.position.set(target.x, target.y, target.z);
	return dirLight;
	}

	// Processes centerline coordinates from a flat array into a series of 3d points
	// TODO should be in a utils library
	function reshapeInto3xN (cl) {
	let clreshaped = [];
	for (var i = 0; i < cl.length / 3; i++) {
		let point = [0, 0, 0];
		point[0] = +cl[i * 3 + 0];
		// TODO: flip to account for mirrored/chiral centerlines
		point[1] = +cl[i * 3 + 1];
		point[2] = +cl[i * 3 + 2];
		clreshaped.push(point);
	}
	return clreshaped;
	}

	// TODO should be in a utils library
	// Creates a material using the default color consistently
	function getDefaultMaterial () {
	return new THREE.MeshLambertMaterial({
		color : COL_DEFAULT,
		side : THREE.DoubleSide,  // See inside and outside of vessels
		flatShading : false, // Make it look real purdy
	});
	}

	// TODO should be in a utils library
	// Creates a material using the plaque color consistently
	function getDefaultPlaqueMaterial () {
	return new THREE.MeshLambertMaterial({
		color : COL_PLAQUE,
		side : THREE.DoubleSide,  // See inside and outside of plaque
		flatShading : false, // Make it look real purdy
	});
	}

	class Model extends Component {

	constructor(props){
		super(props)

		// Bind member functions explictly in constructor
		this.update = this.update.bind(this);
		this.onWindowResize = this.onWindowResize.bind(this);
		this.pickHover = this.pickHover.bind(this);
		this.pickClick = this.pickClick.bind(this);
		this.loadAorta = this.loadAorta.bind(this)
		this.loadVessels = this.loadVessels.bind(this)
		this.onMainGeometryLoad = this.onMainGeometryLoad.bind(this)
		this.onPlaqueGeometryLoad = this.onPlaqueGeometryLoad.bind(this)

		// Load up the colourmaps we'll use and other source files
		COL_MAP_STENOSIS = require("../legends/stenosis/" + props.colmapStenosis);
		COL_MAP_LEIDEN = require("../legends/leiden/" + props.colmapLeiden);

		// Default display is stenosis and readout should be renrendered with
		// any change
		this.state = {
			'modelVisualsCode': 'stenosis',
			'queryVessel': null,
			'querySlice' : null,
			'queryStenosis' : null,
			'queryPlaqueInfo' : null,
			'loaded' : false
		}

	}

	initRenderer() {
		// Get reference to DOM node AFTER mounting
		this.container = document.getElementById('scene-container');
		this.width  = this.container.clientWidth;
		this.height = this.container.clientWidth; // TODO: why is the mount height's zero?

		this.renderer = new THREE.WebGLRenderer({ antialias: true, preserveDrawingBuffer: true, alpha: true });
		this.renderer.setSize(this.width, this.height);
		this.renderer.setClearColor( 0x000000, 0 ); // the default
		this.renderer.setPixelRatio(window.devicePixelRatio);
		this.mount.appendChild(this.renderer.domElement);
	}

	initScene() {
		// Create a clock to use for animation and sync
		this.clock = new THREE.Clock();
		this.clock.start();

		// Create a scene object
		this.scene = new THREE.Scene();
		//this.scene.background = new THREE.Color(COL_BACKGROUND);

		// And some containers for organisation - all defaultly off - we turn
		// them on once constructed
		// Holds loading information - the only thing displayed on initialization
		this.groupLoading = new THREE.Group();
		this.groupLoading.name = "groupLoading";
		this.groupLoading.rotation.x = 3 * Math.PI / 2;
		this.groupLoading.visible = true;
		// Roughly centers a visible piece of text
		let fontsize = 20;
		this.addTextMesh("Loading", new THREE.Vector3(0, 0, 0), this.groupLoading, fontsize);
		this.scene.add(this.groupLoading);

		// Holds default meshes
		this.groupMeshes = new THREE.Group();
		this.groupMeshes.name = "groupMeshes";
		this.groupMeshes.rotation.x = 3 * Math.PI / 2;
		this.groupMeshes.visible = false;
		this.scene.add(this.groupMeshes);

		// Holds all plaques
		this.groupPlaques = new THREE.Group();
		this.groupPlaques.name = "groupPlaques";
		this.groupPlaques.rotation.x = 3 * Math.PI / 2;
		this.groupPlaques.visible = false;
		this.scene.add(this.groupPlaques);

		// Holds wireframe clones
		this.groupWireframes = new THREE.Group();
		this.groupWireframes.name = "groupWireframes";
		this.groupWireframes.rotation.x = 3 * Math.PI / 2;
		this.groupWireframes.visible = false;
		this.scene.add(this.groupWireframes);

		// Holds text geometry labels
		this.groupLabels = new THREE.Group();
		this.groupLabels.name = "groupLabels";
		this.groupLabels.rotation.x = 3 * Math.PI / 2;
		this.groupLabels.visible = false;
		this.scene.add(this.groupLabels);

		// The 3D volume has a slicer as well, we'll put this in an interactable
		// group with other fun objects
		this.groupInteract = new THREE.Group();
		this.groupInteract.name = "groupInteract";
		this.groupInteract.rotation.x = 3 * Math.PI / 2;
		this.groupInteract.visible = false;
		this.scene.add(this.groupInteract);

		// The first interactible is a plane which slices the 3D model to show the
		// user where the current slice info is coming from
		var slicerGeo = new THREE.Geometry();
		let slicerExtent = 5;
		slicerGeo.vertices.push(
			new THREE.Vector3( +slicerExtent, +slicerExtent, 0 ),
			new THREE.Vector3( +slicerExtent, -slicerExtent, 0 ),
			new THREE.Vector3( -slicerExtent, -slicerExtent, 0 ),
			new THREE.Vector3( -slicerExtent, +slicerExtent, 0 ),
			new THREE.Vector3( +slicerExtent, +slicerExtent, 0 )
		);
		var slicer = new THREE.Line(
			slicerGeo,
			new THREE.LineBasicMaterial({
				color: COL_SLICER,
				linewidth: 2
			})
		);
		slicer.name = "slicer";
		this.groupInteract.add( slicer );

		// Lights! (various direction lights)
		this.scene.add(makeDirLight(new THREE.Vector3(0,0,+1), new THREE.Vector3(0,0,0)), INT_LIGHT_DIR);
		this.scene.add(makeDirLight(new THREE.Vector3(-1,0,0), new THREE.Vector3(0,0,0)), INT_LIGHT_DIR);
		this.scene.add(makeDirLight(new THREE.Vector3(0,0,-1), new THREE.Vector3(0,0,0)), INT_LIGHT_DIR);
		this.scene.add(makeDirLight(new THREE.Vector3(+1,0,0), new THREE.Vector3(0,0,0)), INT_LIGHT_DIR);
		// From top and bottom
		this.scene.add(makeDirLight(new THREE.Vector3(0,+1,0), new THREE.Vector3(0,0,0)), INT_LIGHT_DIR);
		this.scene.add(makeDirLight(new THREE.Vector3(0,-1,0), new THREE.Vector3(0,0,0)), INT_LIGHT_DIR);
		// And ambeint lights
		this.scene.add(new THREE.AmbientLight(0xFFFFFF, INT_LIGHT_AMB));

		// Camera! (perspective camera translated to incude view of entire coronary tree)
		this.camera = new THREE.PerspectiveCamera(
		// TODO softcode FOV, zNear and zFar
		50, this.width / this.height, 0.01, 1000
		);
		this.camera.position.z = 512 // Backup to look at 3D
		this.scene.add(this.camera);

		// Action! (orbit controls with some dampening for smoother movement)
		this.controls = new THREE.OrbitControls(this.camera, this.mount);
		this.controls.enableKeys = true;
		// This precents the camera from being able to move into awkwardly high
		// or low angles
		this.controls.minPolarAngle = + 1 * Math.PI / 4;
		//this.controls.maxPolarAngle = + 3 * Math.PI / 4;

		// For debugging, note this will disable ray trace picking functionality
		// x = red, y = green, z = blue
		if (DEBUG_MODE) {
			this.scene.add(new THREE.AxesHelper(1024));
		}

		// Set up listeners for mouse events
		window.addEventListener('mousemove', this.pickHover);
		window.addEventListener('mousedown', this.pickClick);
		window.addEventListener("resize", this.onWindowResize);

	}

	// Helper to reduce code duplication for AWS or local loading for geometry
	// TODO
	// allow for variable auxiliary callback args
	loadGeometryHelper(url, loader, callback, aux) {
		return new Promise(async (res,rej)=>{
			if (this.props.local) {
				loader.load(
					// Local loads require this address prepended
					`http://localhost:3000/s3_mirror/` + url,
					async function (geometry) {
						const rv = await callback.call(null, geometry, aux);
						res(rv);
					},
					null,
					()=>rej(`${url} failed to load`)
				);
			}
			else {
				loader.load(
					// AWS loads require an await on the url GET request
					await Amplify.Storage.get(url),
					async function (geometry) {
						const rv = await callback.call(null, geometry, aux);
						res(rv);
					},
					null,
					()=>rej(`${url} failed to load`)
				);
			}
		});

	}

	// Helper ot reduce code duplication for AWS or local loading for jsons
	async loadJSONHelper(url) {
		let resp;
		if (this.props.local){
			resp = await fetch(`http://localhost:3000/s3_mirror/` + url);
		}
		else {
			const awsurl = await Amplify.Storage.get(url)
			resp = await fetch(awsurl);
		}
		return await resp.json();
	}

	loadAorta (loader) {
		return this.loadGeometryHelper(
			`${this.props.patientID}/${this.props.runID}/aorta.ply`,
			loader,
			this.onMainGeometryLoad,
			"AORTA"
		);
	}

	loadVessels(loader) {
		// For all available vessels
		const rv = [];
		for (var i = 0; i < this.props.vessels.length; i++) {
			let vesselID = this.props.vessels[i];

			// Get the geometry itself
			rv.push(
				this.loadGeometryHelper(
					`${this.props.patientID}/${this.props.runID}/vessels/${vesselID}/geometry.ply`,
					loader,
					this.onMainGeometryLoad,
					vesselID
				)
			);
		}
		return rv;
	}

	loadPlaques(loader, plaque_infos) {
		// For all available vessels
		const rv = [];
		for(const vesselID of Object.keys(plaque_infos)){
			const infos = plaque_infos[vesselID];
			for(let i = 0; i < infos.length; i++){
				rv.push(
					this.loadGeometryHelper(
						`${this.props.patientID}/${this.props.runID}/vessels/${vesselID}/plaque_geometries/${infos[i].label}.ply`,
						loader,
						this.onPlaqueGeometryLoad,
						infos[i]
					)
				);
			}
		}
		return rv;
	}

	// Swaps between the desired colour for all meshes in the scene depending
	// on the code
	changeVisuals (code) {
		if (code === "default") {
		// All meshes need to go to basic colour (not from a colour buffer)
		// Default colour is defined at the top of the file in a constant
		this.groupMeshes.traverse(function(node){
			if (node instanceof THREE.Mesh) {
				let mesh = node;
				// Swap from vertex to solid colours, and use the same solid colour
				mesh.material.vertexColors = THREE.NoColors;
				mesh.material.color = new THREE.Color(COL_DEFAULT);
				mesh.material.needsUpdate = true;
			}
		});

		// Turn on/off the right groups
		this.groupMeshes.visible = true;
		this.groupWireframes.visible = false;

		} else if (code === "stenosis") {
		// All meshes with stenosis colour buffers need to be switched to using
		// colour buffers with the desired colour profile. All meshes without
		// stenosis colour buffers need to use the first colour in the profile,
		// with a single solid colour
		this.groupMeshes.traverse(function(node){
			if (node instanceof THREE.Mesh) {
			let mesh = node;
			// Swap from solid to vertex colours and set colours depending on
			// available colour buffers
			if (mesh["stenosis"]) {
				// Copy the desired colour attribute into the color attribute
				let colbuffer = mesh.geometry.getAttribute("color");
				colbuffer.copy(mesh.geometry.getAttribute("stenosistexture"));
				colbuffer.needsUpdate = true;

				// Use the vertex colours just assigned in the color attribute
				mesh.material.vertexColors = THREE.VertexColors;
				// Keep this pure white as it will tint the vertex colors
				mesh.material.color = new THREE.Color("#FFFFFF");

			} else {
				// Then can use its vertex colours
				mesh.material.vertexColors = THREE.NoColors;
				// Needs the value, not the string
				mesh.material.color = new THREE.Color(COL_MAP_STENOSIS[0].color);

			}
			// Swap between vertex and flat colours
			mesh.material.needsUpdate = true;

			}
		});

		// Turn on/off the right groups
		this.groupMeshes.visible = true;
		this.groupWireframes.visible = false;

		// TODO functionise this swapping rather than copy paste

		} else if (code === "leiden") {
		this.groupMeshes.traverse(function(node){
			if (node instanceof THREE.Mesh) {
			let mesh = node;
			// Swap from solid to vertex colours and set colours depending on
			// available colour buffers
			if (mesh["leiden"]) {
				// Copy the desired colour attribute into the color attribute
				let colbuffer = mesh.geometry.getAttribute("color");
				colbuffer.copy(mesh.geometry.getAttribute("leidentexture"));
				colbuffer.needsUpdate = true;

				// Use the vertex colours just assigned in the color attribute
				mesh.material.vertexColors = THREE.VertexColors;
				// Keep this pure white as it will tint the vertex colors
				mesh.material.color = new THREE.Color("#FFFFFF");

			} else {
				// Then can use its vertex colours
				mesh.material.vertexColors = THREE.NoColors;
				// Needs the value, not the string
				mesh.material.color = new THREE.Color(COL_MAP_LEIDEN[0].color);

			}
			// Swap between vertex and flat colours
			mesh.material.needsUpdate = true;

			}
		});

		// Turn on/off the right groups
		this.groupMeshes.visible = true;
		this.groupWireframes.visible = false;

		} else if (code === "wireframe") {
		// This one is easy, just turn off the mesh group and turn on the
		// wireframe group
		this.groupMeshes.visible = false;
		this.groupWireframes.visible = true;


		} else if (code === "toggle_plaque") {
		// Plaque and all else aren't mutually exclusive!
		this.groupPlaques.visible = !this.groupPlaques.visible;

		} else if (code === "toggle_labels") {
		// Labels and all else aren't mutually exclusive!
		this.groupLabels.visible = !this.groupLabels.visible;

		} else {
			console.log("Error: unrecognised colour change code requested: " + code);
		}
	}

	// Data loader callback for loading main anatomical structure meshes and
	// ancillary information
	async onMainGeometryLoad (geometry, label) {
		// PLY's defaultly don't have face normals, so they need to
		// be calculated to be outward facing
		geometry.computeFaceNormals();
		geometry.computeVertexNormals();
		// And collision information
		geometry.computeBoundingSphere();
		geometry.computeBoundingBox();

		// Swap between this and default color
		let material = getDefaultMaterial();

		// Add mesh and perform translations to move it into the correct position
		// in R^3
		let mesh = new THREE.Mesh(geometry, material);
		this.groupMeshes.add(mesh);
		mesh.updateMatrixWorld(true)

		// Lighting presets
		mesh.castShadow = false;
		mesh.receiveShadow = false;

		// Make the mesh searchable in the scene tree
		// TODO should move to id or ensure that labels are enforceably unique
		mesh.name = label;

		// If directed to attempt a load of the geometry to slice mapping, then do
		// so, because this is a vessel
		if (label !== 'AORTA') {
			// Piggyback the mesh structure and attach v2s to it for querying - this
			// will exceptionally simplify picking behaviour

			// Now go through and get all geometry mapping data, which can be used
			// to make texture maps
			let v2s = await getH5Data(
				`${this.props.patientID}/${this.props.runID}/vessels/${label}/geometry_mappings.h5`,
				"vertex_index_to_slice_index",
				this.props.local
			);
			mesh["v2s"] = v2s;

			// Also get the centerlines for label placement
			let cl_mm = await getH5Data(
				`${this.props.patientID}/${this.props.runID}/vessels/${label}/mpr.h5`,
				"cl_mm",
				this.props.local
			);
			let norms = await getH5Data(
				`${this.props.patientID}/${this.props.runID}/vessels/${label}/mpr.h5`,
				"norms",
				this.props.local
			);
			mesh["cl_mm"] = reshapeInto3xN(cl_mm);
			mesh["norms"] = reshapeInto3xN(norms);

			// Use the slice mappings and the stenosis values to create a set of
			// vertex colors which can be assigned to the buffer geometry as a
			// list of colours

			// Start by setting up a new attribute and attaching it to the buffer
			// geometry, then getting an editing reference to it
			let buffergeo = mesh.geometry;
			let nverts = buffergeo.getAttribute("position").count;
			// This is the buffer that actually get's drawn, everyone hasAxesHelper one of these
			buffergeo.addAttribute("color",
				new THREE.BufferAttribute(new Float32Array(3 * nverts), 3)
			);

			// STENOSIS

			// Get and commit the stenosis values to the vessel
	/* 		let stenosis = await getH5Data(
				`${this.props.patientID}/${this.props.runID}/vessels/${label}/stenosis.h5`,
				"stenosis",
				this.props.local
			); */
			mesh["stenosis"] = this.props.vesselData[label].stenosis; // from web_data.json

			// Create and write into the stenosis buffer attribute
			buffergeo.addAttribute("stenosistexture",
				new THREE.BufferAttribute(new Float32Array(3 * nverts), 3)
			);
			let stenosistexture = buffergeo.getAttribute("stenosistexture");
			for ( var i = 0; i < nverts; i++ ) {
				// Set the i'th vertex to a given RGB based on the stenosis color at
				// that pointAxesHelper
				let slice = mesh.v2s[i];
				let value = mesh.stenosis[slice];
				let colAtValue = utils.linear1dColorMap(COL_MAP_STENOSIS, value);
						stenosistexture.setXYZ(i, colAtValue.r, colAtValue.g, colAtValue.b);
			}

			// LEIDEN

			// Get and commit the leiden info to the vessel
			let leideninfo = null;
			let leidenkeys = Object.keys(this.leidenScores);
			for (var i = 0; i < leidenkeys.length; i++ ) {
				if (leidenkeys[i] === label) {
					leideninfo = this.leidenScores[leidenkeys[i]];
				}
			}
			mesh["leiden"] = leideninfo;

			// Create and write into the leiden buffer attribute
			buffergeo.addAttribute("leidentexture",
				new THREE.BufferAttribute(new Float32Array(3 * nverts), 3)
			);
			let leidentexture = buffergeo.getAttribute("leidentexture");

			// TODO: need a proper distinction between P, M, D - not just simple
			// thirds
			let numSlices = Math.max(...mesh.v2s);

			// Some vessels we don't have leiden information
			if (leideninfo) {
				// There are only a few options here, either we have some amount
				// of P, M and D in the leiden info, or we have the exact vessel name
				let infokeys = Object.keys(leideninfo);
				if (leideninfo[label]) {
					// If the vessel name is in the leiden information, then we only
					// have one constant value
					let value = leideninfo[label];
					let colAtValue = utils.linear1dColorMap(COL_MAP_LEIDEN, value);
					for ( var i = 0; i < nverts; i++ ) {
								leidentexture.setXYZ(i, colAtValue.r, colAtValue.g, colAtValue.b);
					}
				} else {
					// We must have either P, M or D or some combination of it
					let prox = leideninfo["P"];
					let mid  = leideninfo["M"];
					let dist = leideninfo["D"];
					for ( var i = 0; i < nverts; i++ ) {
						let slice = mesh.v2s[i];

						// Get what third of the vessel we're in, in the range [0, 2]
						let vesselThird = Math.floor(slice / (numSlices / 3.0));
						vesselThird = Math.max(Math.min(vesselThird, 2), 0);

						// Write colour mapping if we have it, otherwise write in the
						// default colour
						// TODO: is the default value zero?
						let defaultLeidenScore = 0.0;
						let colAtValue;
						if (vesselThird === 0) {
							colAtValue = utils.linear1dColorMap(COL_MAP_LEIDEN, (prox ? prox : defaultLeidenScore));
						}
						else if (vesselThird === 1) {
							colAtValue = utils.linear1dColorMap(COL_MAP_LEIDEN, (mid  ? mid  : defaultLeidenScore));
						}
						else if (vesselThird === 2) {
							colAtValue = utils.linear1dColorMap(COL_MAP_LEIDEN, (dist ? dist : defaultLeidenScore));
						}
						else {
							console.log("Error: calculated vessel third outside of range [0, 2]")
						}
						leidentexture.setXYZ(i, colAtValue.r, colAtValue.g, colAtValue.b);
					}
				}
			}

			// TEXTURE MAPPINGS COMPLETE

			// Set an anchor position which is roughly in/on the mesh
			let cl = mesh["cl_mm"];
			let clMid = cl[Math.round(cl.length/2)];
			let anchor = (new THREE.Vector3(clMid[0], clMid[1], clMid[2]));
			mesh['anchor'] = anchor;

		}

		else {

			// Aorta at this time does not have any special attributes or colour
			// attributes

			// Set an anchor position which is roughly in/on the mesh
			let vpos = mesh.geometry.getAttribute("position").array;
			let anchor = (new THREE.Vector3(vpos[0], vpos[1], vpos[2]));
			mesh['anchor'] = anchor;

		}

		// Attach label to mesh
		this.attachLabelToNamedMesh(mesh);
		// Every mesh also has a wireframe mesh copy which is defaultly invisible
		// Simplify the geometry before making a wireframe, for a more low poly look
		// Commented out below as it was leading to 30 second+ loading times
		/*
		var modifier = new THREE.SimplifyModifier();
		let count = Math.floor(geometry.attributes.position.count * 0.3);
		let simpGeo = modifier.modify(geometry, count);
		*/
		let wfGeo = new THREE.WireframeGeometry(geometry);
		let wfLine = new THREE.LineSegments(wfGeo);
		wfLine.material.depthTest   = false;
		wfLine.material.color       = new THREE.Color(COL_WIREFRAME);
		wfLine.material.opacity     = 0.5;
		wfLine.material.transparent = true;
		wfLine.material.linewidth   = 0.5;
		this.groupWireframes.add(wfLine);
		return mesh;
	};

	// Data loader callback for loading plaque volumes
	async onPlaqueGeometryLoad (geometry, info) {
		if (info.composition.calcified < 0.05) {
			//console.log("Culling plaque which did not meet threshold criteria")
			return;
		}

		// PLY's defaultly don't have face normals, so they need to
		// be calculated to be outward facing
		geometry.computeFaceNormals();
		geometry.computeVertexNormals();
		// And collision information
		geometry.computeBoundingSphere();
		geometry.computeBoundingBox();

		// Swap between this and default color
		let material = getDefaultPlaqueMaterial();

		// Add mesh and perform translations to move it into the correct position
		// in R^3
		let mesh = new THREE.Mesh(geometry, material);
		mesh.updateMatrixWorld(true)

		// Lighting presets
		mesh.castShadow = false;
		mesh.receiveShadow = false;

		// Add in metrics/information for now
		mesh.plaqueInfo = info;

		// Add into the plaque's group
		this.groupPlaques.add(mesh);
	}


	// A helper for making text geometry. Position is bottom left of text
	addTextMesh (text, position, container, size=3) {
		// Load font to be used
		let fontjson = require('../fonts/Roboto_Light_Regular.json');
		let font = new THREE.Font(fontjson);

		var textGeometry = new THREE.TextGeometry(text, {
			font: font,
			size: size,
			height: 0.01,
			curveSegments: 12,
			bevelEnabled: false
		});

		// Don't want labels affected by lighting
		var textMaterial = new THREE.MeshBasicMaterial({
			color: COL_LABEL,
			//depthTest: false,
			//depthWrite: false
		});
		var mesh = new THREE.Mesh( textGeometry, textMaterial );
		container.add(mesh);
		// Directly set a vector 3
		mesh.position.set(position.x, position.y, position.z);
		// Account for parent rotation
		//mesh.rotation.z = -Math.PI/2;
		//mesh.rotation.x = - 3 * Math.PI/2;
		// Special flag just for text
		mesh['istext'] = true;
	}

	componentWillUpdate(newProps){
		// Only swap to a new view if we're out of the loading screen and the vessel
		// ID is new
		if (newProps.vesselID){
		if (newProps.vesselID !== this.props.vesselID && !this.groupLoading.visible ){
			this.focusOn(newProps.vesselID)
		}
		}
	}

	async componentDidMount(){
		// Initialize the THREE JS scene and associated member variables. Must be
		// done after mounting to successfully get element by ID. Note that these
		// functions write new member variables to this

		this.initRenderer();
		this.initScene();

		// If we mounted then load up the objects and required auxiliary render
		// data. Note that load behaviour changes depending on if local or off s3
		this.leidenScores = await this.loadJSONHelper(`${this.props.patientID}/${this.props.runID}/ui_leiden.json`);
		console.log('got leiden scores', this.leidenScores);

		// Define a load manager to report progress/have progress bars etc and
		// attach to loader
		let manager = new THREE.LoadingManager();
		manager.onStart = function ( url, itemsLoaded, itemsTotal ) {
			console.log( 'Started loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.' );
		};
		manager.onProgress = function ( url, itemsLoaded, itemsTotal ) {
			console.log( 'Loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.' );
		};
		let _ = this; // To refer in callback
		manager.onLoad = function ( ) {
			console.log( 'Entirety of loading is complete');
		};
		manager.onError = function ( url ) {
			console.log( 'There was an error loading ' + url );
		};

		// Start with geometry loads using a PLY loader (just one - they're
		// expensive). This actually loads our stuff into scene.
		// We'll hold a direct reference to our loaded objects because that
		// will be easier than using the scene tree to locate entities
		let loader = new THREE.PLYLoader(manager);
		// Don't await - the loading manager will block until complete - more
		// important to get everything in the load queue
		// need to block for plaque_info as cannot block once adding urls to the loader
		const plaque_infos = {};
		for(let i = 0; i < this.props.vessels.length; i++){
			plaque_infos[this.props.vessels[i]] = await this.loadJSONHelper(
				`${this.props.patientID}/${this.props.runID}/vessels/${this.props.vessels[i]}/plaque_volumes.json`
			)
		}
		const aortaPromise = this.loadAorta(loader);
		const vesselPromises = this.loadVessels(loader);
		const plaquePromises = this.loadPlaques(loader, plaque_infos);
		try{
			await aortaPromise;
		}
		catch(err){
			console.error(err);
		}
		for(let i = 0; i < vesselPromises.length; i++){
			try{
				await vesselPromises[i];
			}
			catch(err){
				console.error(err);
			}
		}
		for(let i = 0; i < plaquePromises.length; i++){
			try{
				await plaquePromises[i];
			}
			catch(err){
				console.error(err);
			}
		}

		console.log('operations done, changing state');
		// below is a hacky way to avoid the model displaying without stenosis textures
		// uncertain what the root cause of this bug is
		// Calculate and attach camera orientations for looking at all major
		// structures
		_.attachCameraFocusPoses();

		// TODO: reset stenosis once loaded
	  	_.changeVisuals("stenosis")
		// Set to initial camera and orbit orientation once loaded
		let animate = false;
		_.defaultCameraPose(animate);
		// Make default visibilities
		_.groupLoading.visible   	= false;
		_.groupMeshes.visible 		= true;
		_.groupPlaques.visible 		= true;
		_.groupWireframes.visible = false;
		_.groupLabels.visible 		= true;
		_.groupInteract.visible 	= true;
		// Now we're loaded
		_.setState({ "loaded" : true })

		// Enter the rendering loop and show the loading screen
		this.update();

		// For console testing
		window.test_model = this;

	}

	onWindowResize() {
		// Set height and width of renderer, recalc camera aspect, and update
		// projection matrix
		//this.width = this.container.clientWidth;
		//this.height = this.container.clientHeight;
		let w = this.container.clientWidth;
		let h = this.container.clientHeight;
		this.camera.aspect = w / h;
		this.camera.updateProjectionMatrix();
		this.renderer.setSize(w, h);
		this.defaultCameraPose();
	}

	// Helper for returning the closest picked object and associated information
	pickClosestMesh(event, containers) {
		// Attempt to retreive mesh info for the object at the pick
		// Note that renderer is at an offset into the page, and the event is given
		// wrt page coordinates.

		// No picking until loaded - this prevents weird issues with containerisation
		if (this.groupLoading.visible) {
			return null;
		}

		// Get the children of all the provided containers to pick from
		if (containers.length == 0) {
			return null;
		}
		let children = [];	// A branch new array - not a reference to an old one
		for (var i = 0; i < containers.length; i++) {
			// Use spread operator to pass all elements of this conatiners children
			// to push function
			children.push(...containers[i].children);
		}

		//Note that this.container =/= containers!!!
		let offsetRect = this.container.getBoundingClientRect();
		let normDevX = +(((event.clientX - offsetRect.left) / this.width ) * 2 - 1);
		let normDevY = -(((event.clientY - offsetRect.top)  / this.height) * 2 - 1);

		// Setup a raycaster
		let raycaster = new THREE.Raycaster();
		raycaster.setFromCamera(new THREE.Vector2(normDevX, normDevY), this.camera);

		// For debugging
		//this.scene.add(new THREE.ArrowHelper(raycaster.ray.direction, raycaster.ray.origin, 300, 0xff0000) );

		// Perform a raycast all the way to the vertex level. Only select from
		// meshes, not both wireframe and meshes
		let intersects = raycaster.intersectObjects(children);
		if (intersects.length !== 0) {
		// Only return meshes no lights or groups or other garbage
		if (intersects[0].object.type === "Mesh") {
			return intersects[0];
		}
		} else {
		return null;
		}
	}

	pickHover(event) {
		let pick = this.pickClosestMesh(event, [this.groupMeshes]);
		if (pick) {
			// Key things here are the mesh itself, with associated risk info, as
			// well as fine details of the pick, such as:
			// pick.object
			// pick.object.name
			// pick.object.cl_mm
			// pick.object.stenosis
			// pick.object.v2s
			// pick.distance
			// pick.face
			// pick.faceIndex

			// Can only get risk and other details from vessel, not aorta
			let name = pick.object.name;
			if (name !== "AORTA") {
				// You may get an error here because the meshes haven't fully loaded,
				// so we check if defined before querying
				let mesh = pick.object;
				let vidx = pick.face.a; // At a small enough scale that face ~ vertex
				if (mesh.v2s && mesh.stenosis) {
					let sliceidx = mesh.v2s[vidx];
					let stenosisAtPick = mesh.stenosis[sliceidx];

					// Update a graphical screen with all information
					this.setState({
						'queryVessel' : name,
						'querySlice' : sliceidx,
						'queryStenosis' : stenosisAtPick
					});
				}
			} else {
				// Nothing on the aorta, binding on 2, click outta 3...
				this.setState({
					'queryVessel' : null,
					'querySlice' : null,
					'queryStenosis' : null
				});
			}
		} else {
			// Queried nothing
			this.setState({
				'queryVessel' : null,
				'querySlice' : null,
				'queryStenosis' : null
			});
		}
	}

	pickClick(event) {
		// Only accept left clicks
		if (event.which !== 1) {
		console.log("Non left click, discarding")
		return;
		}

		// Attempt to pick an object and select object for scrutiny in the
		// wider application
		let pick = this.pickClosestMesh(event, [this.groupMeshes, this.groupPlaques]);
		if (pick) {
			// Then we want to update slice idxs in rest of page
			if (pick.object.name !== "AORTA") {
				// Check if the object has a plaqueinfo field, in which case its a
				// plaque and we can call this early
				if (pick.object.plaqueInfo) {
					this.setState({
						'queryPlaqueInfo' : pick.object.plaqueInfo
					})
				} else {
					// Use pick information to reposition camera
					this.focusOn(null, pick);

					// You may get an error here because the meshes haven't fully loaded,
					// so we check if defined before querying
					let mesh = pick.object;
					let vidx = pick.face.a; // At a small enough scale that face ~ vertex
					if (mesh.v2s && mesh.stenosis) {
						let sliceidx = mesh.v2s[vidx];
						let stenosisAtPick = mesh.stenosis[sliceidx];

						// Updates the MPR and other slice viewers
						// First update the vessel
						if (pick.object.name){
							if (this.props.vesselID !== pick.object.name){
								let newState = {}
								newState['reportName'] = pick.object.name
								this.props.changeStateDct(newState)
							}
						}
						// Then update the slice
						if (sliceidx){
							let newState = {}
							newState['sliceidx'] = sliceidx
							this.props.changeStateDct(newState)
						}
					}

					// Queried nothing
					this.setState({
						'queryPlaqueInfo' : null
					})
				}
			}
		} else {
			// Queried nothing
			this.setState({
				'queryPlaqueInfo' : null
			})
		}
	}

	// Helper function to move camera based on a target point and an object with
	// a camera focus position
	moveCameraToFit(object, target, animate=true) {
		// Can't move camera if we don't have a focus position, so check this
		// first
		if (!object['cameraFocusPosition']) {
		console.log("Can't focus on object with name '" + object.name + "' as it has no camera focus position calculated")
		return;
		}

		let extents = new THREE.Box3().setFromObject(object);
		let size = extents.max.y - extents.min.y;
		size *= 1.1;  // A buffer to give some margin in the final view

		// For inner scope visibility
		let ctrl = this.controls;

		// Don't need to calulate a pose on the fly, just use the precalculated
		// focus position
		let newpos = (new THREE.Vector3()).copy(object['cameraFocusPosition']);

		// When animating the transition we use tweens, otherwise not
		if (animate) {
			// Move to look at the pick point using a tween which updates the orbit
			// controls target
			let intertarget = (new THREE.Vector3()).copy(this.controls.target);
			var tartween = new TWEEN.Tween(intertarget)
			.to({x:target.x, y:target.y, z:target.z}, TIME_TARGET_TWEEN)
			.easing(TWEEN.Easing.Quadratic.Out)
			.start();
			// We tween on a helper intermediate variable, then set and update this
			// in the tween callback
			tartween.onUpdate(function(t) {
			ctrl.target = t;
			ctrl.update();
			})

			// Using a tween, which works on write protected data!
			var postween = new TWEEN.Tween(this.camera.position)
			.to({x:newpos.x, y:newpos.y, z:newpos.z}, TIME_POSITION_TWEEN)
			.easing(TWEEN.Easing.Quadratic.Out)
			.start();

		} else {
			// Move directly
			ctrl.target = target;
			this.camera.position.set(newpos.x, newpos.y, newpos.z);
		}
	}

	// Helper function which snaps to an object/mesh based on its name
	focusOn(name, pick) {
		if (pick && !name) {
		// Get required elements from pick
		let mesh = pick.object;
		let target = pick.point;
		this.moveCameraToFit(mesh, target);

		} else if (name && !pick) {
		// Find the mesh, target and distance to target if not provided in pick
		let mesh = this.groupMeshes.getObjectByName(name);
		if (mesh) {
			let cent = new THREE.Vector3();
			mesh.geometry.boundingBox.getCenter(cent)

			// Nudered UI controls because old system was too rollercoastery
			//let target = mesh.localToWorld(cent);
			let target = this.getAnatomicalCentre();
			this.moveCameraToFit(mesh, target);
		} else {
			console.log("Requested focus target '" + name + "' does not (yet) exist in scene")
		}
		}
	}

	// Helper to return the centre of all anatomical meshes (bounding box, not
	// barycentre)
	getAnatomicalCentre() {
		let cent = new THREE.Vector3();
		(new THREE.Box3()).setFromObject(this.groupMeshes).getCenter(cent);
		return cent;
	}

	// Recentres to arbitrary hardcoded general center (and orbits around it)
	defaultCameraPose (animate=true) {
		console.log("Moving camera to default pose");
		// Focus on the center of the group of meshes
		this.moveCameraToFit(this.groupMeshes, this.getAnatomicalCentre(), animate);
	}

	// Attaches the pose the camera will travel to when selecting each mesh,
	// to each mesh
	attachCameraFocusPoses () {
		console.log("Starting attaching poses");

		// Get the centre and extents of the anatomy meshes
		let grpbb = (new THREE.Box3()).setFromObject(this.groupMeshes);
		let grpcent = new THREE.Vector3();
		grpbb.getCenter(grpcent);
		let grpsize = new THREE.Vector3();
		grpbb.getSize(grpsize);

		// Use a bounding sphere to calculate the radius, and define a length which
		// will extend the points on this radius to an acceptable position
		let grprad = (new THREE.Vector3()).copy(grpsize).length() / 2.0;
		let desiredlength = grprad * 1.8;

		// For every 'focusable' object, calculate the position the camera will
		// go to when looking at it
		let _ = this;
		this.groupMeshes.traverse(function(node) {
			if (node instanceof THREE.Mesh) {
				let mesh = node;
				let cent = new THREE.Vector3();
				(new THREE.Box3()).setFromObject(mesh).getCenter(cent);

				// Place the camera on the wrapped face of the bounding cylinder - no
				// positions allowed inside this cylinder
				// Get the position from from the centre of the group to the centre of
				// the object & create a vector in this direction
				let grp2obj = (new THREE.Vector3()).copy(cent).sub(grpcent).normalize();

				// Position the focus point along this vector at a distance defined by
				// the bounding sphere of the group
				grp2obj.y = 0;	// Set a desired verticality - order is important
				grp2obj.setLength(desiredlength);
				let fp = (new THREE.Vector3()).copy(grpcent).add(grp2obj);
				mesh['cameraFocusPosition'] = fp;

				if (DEBUG_MODE) {
					var geometry = new THREE.SphereGeometry( 5, 32, 32 );
					var material = new THREE.MeshBasicMaterial( {color: 0xffff00} );
					var sphere = new THREE.Mesh( geometry, material );
					sphere.position.set(fp.x, fp.y, fp.z);
					_.scene.add( sphere );
				}
			}
		});

		// The meshes group also requires a focus point so that we can have a
		// default position
		this.groupMeshes['cameraFocusPosition'] =
					(new THREE.Vector3()).copy(grpcent)
					.add((new THREE.Vector3(0, 0.5, 1)).setLength(desiredlength));

		console.log("Finished attaching poses");
	}

	// Attaches labels to all named meshes
	attachLabelToNamedMesh (mesh) {
		// Put all the stuff that comprises a lebl in a group
		let labelSubgroup = new THREE.Group();

		// Position text based on the vector between the centre of the group,
		// and the label
		let grpbb = (new THREE.Box3()).setFromObject(this.groupMeshes);
		let grpcent = new THREE.Vector3();
		grpbb.getCenter(grpcent);
		let cent = new THREE.Vector3();
		(new THREE.Box3()).setFromObject(mesh).getCenter(cent);

		// TODO: need to wait for all meshes to be in before using the extents of
		// the group

		// Want to place the camera in the direction away from the center
		// of the meshes
		let grp2mesh = (new THREE.Vector3()).copy(cent).sub(grpcent).normalize();
		let linelength = 6;
		let offset = (new THREE.Vector3()).copy(grp2mesh).setLength(linelength);
		offset.y = 0; // Within the axial plane/side on view
		let labelpos = (new THREE.Vector3()).copy(mesh['anchor']).add(offset);
		this.addTextMesh(mesh['name'], labelpos, labelSubgroup);

		// TODO: draw square around label

		/*
		// Also add on a line from the anchor to the text
		var labelLineGeo = new THREE.Geometry();
		labelLineGeo.vertices.push(mesh['anchor'], labelpos);
		var labelLine = new THREE.Line(
		labelLineGeo,
		new THREE.LineBasicMaterial({
			color: COL_LABEL_LINES,
			linewidth: 2
		})
		);
		labelSubgroup.add(labelLine);
		*/
		this.groupLabels.add(labelSubgroup);
	}

	screenshot() {
		// open in new window like this

		// Without 'preserveDrawingBuffer' set to true, we must render now
		this.scene.background = new THREE.Color('#FFFFFF');
		this.renderer.render(this.scene, this.camera);
		const strMime = "image/png";
		const imgsrc = this.renderer.domElement.toDataURL(strMime);
		this.scene.background = new THREE.Color(COL_BACKGROUND);
		//console.log('screenshot url', URL.createObjectURL(imgsrc))
		//var imageData = new Uint8Array(imgsrc.replace(`/^data:image/png;base64,/`, ""), "base64");
		//let imageData = utils.convertDataURIToBinary(imgsrc)

		/* - to open screenshot in new window
		var w = window.open('', '');
		w.document.title = "Screenshot";
		//w.document.body.style.backgroundColor = "red";
		var img = new Image();
		img.src = imgsrc
		w.document.body.appendChild(img);
		*/
		//console.log('screenshot:', imageData)
		return imgsrc
	}

	// Helper to update all text billbording during the update loop
	updateTextBehaviour () {
		// Ensure all text is facing the user (billboarding)
		let camera = this.camera;
		this.groupLabels.traverse(function(node) {
		if (node instanceof THREE.Mesh) {
			let mesh = node;
			mesh.lookAt(camera.position);
		}
		});
	}

	// Helper to update the slice visualizer during the update loop
	updateSliceVisualizer (sliceidx) {

		// use props if not defined (will be defined if sliceidx changed externally)
		sliceidx = (sliceidx) ? sliceidx: this.props.sliceidx

		// Ensure that slicer is positioned over the current state and slice. Try
		// to get the mesh with the associated report name
		let selected = this.groupMeshes.getObjectByName(this.props.reportName);
		let slicer = this.groupInteract.getObjectByName('slicer');
		if (selected) {
		let mesh = selected;
		if (mesh['cl_mm']) {
			if (sliceidx >= 0 && sliceidx < mesh['cl_mm'].length) {
			// Position the slicer over the slice coordinate at that point
			let cl_coord = mesh['cl_mm'][sliceidx];
			let cl_norms = mesh['norms'][sliceidx];
			slicer.position.set(+cl_coord[0], +cl_coord[1], +cl_coord[2]);

			// Get rotation from normal and apply
			var mx = new THREE.Matrix4().lookAt(
				new THREE.Vector3(cl_norms[0],cl_norms[1],cl_norms[2]),
				new THREE.Vector3(0,0,0),
				new THREE.Vector3(0,1,0));
			slicer.setRotationFromMatrix(mx);
			slicer.visible = true;

			} else {
			// Only slice on valid slice indices
			slicer.visible = false;
			}
		} else {
			// Only visualize the slice on sliceable objects
			slicer.visible = false;
		}
		} else {
		// Only visualize the slice when an object is selected
		slicer.visible = false;
		}
	}

	createReadoutJSX () {
		// Build JSX for the HUD information readout based on set member variables
		// Construct a readout of what is being queried via hovers and clicks
		let width = 200;
		let unitHeight = 20;
		let height = unitHeight * 9;
		// Vessel
		let vesselStr = "";
		if (this.state.queryVessel != null) {
			vesselStr = `${this.state.queryVessel}`;
		}
		// Slice
		let sliceStr = ""
		if (this.state.queryVessel != null && this.state.querySlice != null) {
			sliceStr = `Slice #${this.state.querySlice}`;
		}
		// Stenosis
		let stenosisStr = ""
		if (this.state.queryVessel != null && this.state.queryStenosis != null) {
			// Round off decimal places
			let stenosisRounded = (100 * this.state.queryStenosis).toFixed(2);
			stenosisStr = `${stenosisRounded}% stenosis`;
		}
		// Plaque
		let plaqueStr = " ";
		let plaqueCompHeaderStr = " ";
		let plaqueComp0Calc = " ";
		let plaqueComp1Calc = " ";
		let plaqueComp2Calc = " ";
		let plaqueComp3Calc = " ";
		if (this.state.queryPlaqueInfo != null) {
			let qpi = this.state.queryPlaqueInfo;
			//qpi.composition.calcified
			//qpi.composition.fibrous
			//qpi.composition.fibrous_fatty
			//qpi.composition.low_attenuating
			let volumeRounded = qpi.volume.toFixed(2);
			plaqueStr = `Plaque volume ${volumeRounded}mm3`
			plaqueCompHeaderStr = `Plaque composition:`
			plaqueComp0Calc = `  ${(100 * qpi.composition.calcified).toFixed(2)}% calcified`
			plaqueComp1Calc = `  ${(100 * qpi.composition.fibrous).toFixed(2)}% fibrous`
			plaqueComp2Calc = `  ${(100 * qpi.composition.fibrous_fatty).toFixed(2)}% fibrous fatty`
			plaqueComp3Calc = `  ${(100 * qpi.composition.low_attenuating).toFixed(2)}% low attenuating`
		}


		let readoutstyle = {
			position:"absolute",
			width:width,
			height:height,
			top:`calc(100% - ${height}px)`,
			padding:"4px",
			// For debugging
			//border:"1px solid white"
		}
		let textstyle = {
			padding: 0,
			margin: 0,
			fontSize: 14,
			whiteSpace: "pre",
			height: unitHeight,
			fontFamily: "monospace",
			display: "block"
		}
		return (
			<div style={readoutstyle}>
				<div style={textstyle}>{plaqueStr}</div>
				<div style={textstyle}>{plaqueCompHeaderStr}</div>
				<div style={textstyle}>{plaqueComp0Calc}</div>
				<div style={textstyle}>{plaqueComp1Calc}</div>
				<div style={textstyle}>{plaqueComp2Calc}</div>
				<div style={textstyle}>{plaqueComp3Calc}</div>
				<div style={textstyle}>{vesselStr}</div>
				<div style={textstyle}>{sliceStr}</div>
				<div style={textstyle}>{stenosisStr}</div>
			</div>
		);

	}

	update() {
		// Uses current time by default
		TWEEN.update();

		// Loop once complete
		window.requestAnimationFrame(this.update);

		// Call update heplers for specific tasks
		this.updateTextBehaviour();
		this.updateSliceVisualizer();

		// Take control update and redraw the render
		this.controls.update();

		// Draw projection to screen buffer and swap
		this.renderer.render(this.scene, this.camera);
	}

	shouldComponentUpdate(nextProps, nextState) {

		// Button interactivity
		if (nextProps.togglePlaque){
			this.changeVisuals("toggle_plaque");
			this.props.changeStateDct({
				'togglePlaque': false
			})
		}

		if (this.props.resetCamera){
			this.defaultCameraPose();
			this.props.changeStateDct({
				'resetCamera': false
			})
		}

		if (nextProps.toggleLabels){
			this.changeVisuals("toggle_labels");
			this.props.changeStateDct({
				'toggleLabels': false
			})
		}

		// Takes a screenshot then disables flag
		if (this.props.takeModelScreenshot){
		let imgsrc = this.screenshot()
		nextProps.changeStateDct({
			'modelScreenshot': imgsrc,
			'takeModelScreenshot': false
		})
		}

		if (nextProps.sliceidx !== this.props.sliceidx){
			this.updateSliceVisualizer(nextProps.sliceidx);
		}

		return true

	}

	render(){
		// Only show one legend. Use unique keys to inform react that they are
		// different components (despite being the same type of component),
		// and should be switched not prop updated!
		let legend = <div/>;
		if (this.state.modelVisualsCode == "stenosis") {
			legend = (<Legend
				key="0"
				header={"Stenosis"}
				mapFile={"stenosis/" + this.props.colmapStenosis}
				width={16}
				height={220}
			/>);
		}
		else if (this.state.modelVisualsCode == "leiden") {
			legend = (<Legend
				key="1"
				header={"Leiden"}
				mapFile={"leiden/" + this.props.colmapLeiden}
				width={16}
				height={220}
			/>);
		}

		// Don't allow for drop down interaction until loaded
		let dropdown = (<Dropdown selection button compact
      style={{ width:"7em" }}
			defaultValue={"Stenosis"}
			options={[
				{key: 'Default', value: 'Default', text: 'Blank'},
				{key: 'Stenosis', value: 'Stenosis', text: 'Stenosis'},
				{key: 'Leiden', value: 'Leiden', text: 'Leiden'},
				{key: 'Wireframe', value: 'Wireframe', text: 'Wireframe'}
			]}
			onChange={(e, data) => {
				// The change visuals calls actually makes the change, and the
				// state is used to change the view of the model
				// TODO move changevisuals to proc on setstate
				let code = String(data.value).toLowerCase();
				this.changeVisuals(code)
				this.setState({
					modelVisualsCode: code
				})
			}}
		/>)

		// Get the JSX readout and rerender it every time the state changes
		let readout = this.createReadoutJSX();

		// No buttons if not loaded
		dropdown = (this.state.loaded) ? dropdown : <div/>
		legend   = (this.state.loaded) ? legend : <div/>
		readout  = (this.state.loaded) ? readout : <div/>
		return(
		<div className={"model"} ref={ref => (this.mount = ref)} >
			{dropdown}
			{legend}
			{readout}
		</div>
		)
	}
	}

	export default Model;
