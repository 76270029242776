
import React from 'react';
import ReactDOM from 'react-dom'
import { Icon, Button, Form, Dropdown, Table, Menu, Loader } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import Amplify from 'aws-amplify';
import Model from './components/model'
import Analysis from './components/analysis'
import Error from './error'
import { getH5Data } from './components/h5'
import { len, getPopup } from './components/utils'
import { LinePlot } from './components/plot'
import { updateReportPDF, buildEditableReport } from './components/report'
import { View } from '@react-pdf/renderer';
import VesselViewer from './components/vessel_viewer'
import { ContrastSmartSlicer, NonContrastSmartSlicer } from './components/smart_slicer'
//import { updateReportPDF } from './components/report2'


const STATS_KEYS = {
    calcium_score: 'Calcium Score',
    maximum_stenosis: 'Maximum Stenosis',
    cad_rads: 'CAD-RADS',
    plaque_volume: 'Plaque Volume',
    vulnerable_plaque: 'Vulnerable Plaque',
    leiden_score: 'Leiden Score'
}


class C extends React.Component {
    constructor(props) {
        super(props);

        console.log("--- props:", props);
        this.state = {
            patientID: props.match.params.id,
            patientName: props.match.params.id,
            reportName: 'Patient',
            reportType: 'Report',
            downloadReportButton: (<Button icon="download" className="downloadReport"/>),
            viewer: 'Tree',
            showSprites: true,
            reportOptions: null,
            cadrads: null,
            sliceidx: 0,
            showWalls: true,
            modelScreenshot: null,
            volumeFile: 'volume_contrast.h5',
            windowlevel: 220,
            windowwidth: 740
        };

        console.log("PATIENT PROPS", this.props)

        this.props.changeState('patientID', this.state.patientID)
        this.props.changeState('patientName', this.state.patientName)

        this.changeState = this.changeState.bind(this)
        this.changeStateDct = this.changeStateDct.bind(this)
        this.changeBodyScroll = this.changeBodyScroll.bind(this)
        this.forceBodyScroll = this.forceBodyScroll.bind(this)
        this.loadPatientData = this.loadPatientData.bind(this)
        this.showHideSprites = this.showHideSprites.bind(this)
        this.makeDropdown = this.makeDropdown.bind(this)
        this.userNotes = ''

        this.statTileInfo = {
            'Calcium Score': (
                <div>
                    <div>
                        Agatston Score based on plaque volume and composition derived from the non-contrast scan
                    </div>
                </div>
            ),
            'Maximum Stenosis': `
                Maximum stenosis in the LM and proximal LAD, LCX and RCA
            `,
            'CAD-RADS': (
                <div>
                    <div>
                        Coronary Artery Disease Reporting and Data System Score
                    </div>
                </div>
            ),
            'Plaque Volume': (
                'Total volume of all plaques detected in the contrast scan'
            ),
            'Vulnerable Plaque': (
                "Maximum probability observed of a plaque's vulnerability"
            ),
            'Leiden Score': (
                <div>
                    <div>
                        A multi-metric measure of risk
                    </div>
                </div>
            )
        }

        this.references = {
            'Calcium Score': 'https://www.ncbi.nlm.nih.gov/pubmed/2407762',
            'CAD-RADS': 'https://www.ncbi.nlm.nih.gov/pubmed/27318587',
            'Leiden Score': 'https://www.ncbi.nlm.nih.gov/pubmed/30660516'
        }
    }

    changeBodyScroll(k){
        let style = document.body.style.overflow
        document.body.style.overflow = (style === 'hidden') ? 'auto':'hidden'
        this.setState({
            [k]: (document.body.style.overflow === 'hidden') ? true: false
        })

    }

    forceBodyScroll(){
        //document.body.style.overflow = 'auto'
    }

    changeState(k, v){
        console.log("CHANGING STATE", k, v)
        if (v){ // can only be changed to something - necessary for model clicks
            let state = {...this.state}
            state[k] = v
            if (k === 'modelScreenshot'){
                state.takeModelScreenshot = false
            }
            this.setState(state)
        }
    }

    async changeStateDct(dct){
        // if screenshot taken update pdf report
        console.log('change state dict', dct)
        this.setState(dct)
    }

    showReport(){
        this.setState({showReport: true})
    }

    componentWillReceiveProps(nextProps){
        if (nextProps.patientName) {
            this.setState({
                patientName: nextProps.patientName,
            })
        }
        let patientID = nextProps.match.params.id
        if (patientID !== this.state.patientID){
            this.setState({
                patientID: patientID,
            })
        }
    }

    branchChange(e, data){
        this.setState({
            reportName: data.value
        })
    }

    async componentDidMount(){
        const cadrads = require('./public/cadrads.json')
        const reportOptions = require('./public/report_options.json')
        let modelOverlayButtons = (
            <div className='overlay'>

                {getPopup(
                    <Button className="invert" icon="redo"
                    onClick={() => this.setState({resetCamera: true}) }
                    />,
                    "Reset camera",
                    {basic: true, position: 'right center'}
                )}

                <br/>

                {getPopup(
                    <Button className="invert" icon="circle"
                    onClick={() => this.setState({togglePlaque: true}) }
                    />,
                    "Toggle plaque",
                    {basic: true, position: 'right center'}
                )}

                <br/>

                {/*
                {getPopup(
                    <Button className="invert" icon="edit"/>,
                    "Edit labels",
                    {basic: true, position: 'right center'}
                )}
                <br/>
                */}

                {getPopup(
                    <Button
                    className="invert" icon="tags"
                    onClick={() => this.setState({toggleLabels: true}) }
                    />,
                    "Toggle labels",
                    {basic: true, position: 'right center'}
                )}

                <br/>
                {/*
                {getPopup(
                    <Button
                    className="invert" icon="camera"
                    onClick={() => this.setState({takeModelScreenshot: true})}
                    />,
                    `Screenshot`,
                    {basic: true, position: 'right center'}
                )}
                */}
            </div>
        )

        this.setState({
            cadrads: cadrads, reportOptions: reportOptions,
            modelOverlayButtons: modelOverlayButtons
        })

    }

    componentWillMount(){ // for vars that only need to be made once (nothing referencing state)
        this.rhsTabs = ['Patient', 'LAD', 'LCX', 'RCA', 'LM', 'Branches']
        this.userNotesForm = (
            <Form>
                <Form.Group>
                    <Form.TextArea placeholder="..." className="notes"
                        onChange={(e) => {this.userNotes = e.target.value}}
                    />
                </Form.Group>
            </Form>
        )
    }

    showHideSprites(){
        let showSprites = (this.state.showSprites) ? false: true
        this.setState({showSprites: showSprites})
    }

    makeDropdown(k, patientData, nested1, nested2, nested3){
        console.log('making dropdown', k, patientData, nested1, nested2, nested3)
        const style = patientData.report.style
        if (this.state.reportOptions[style]){
            var options = this.state.reportOptions[style][k].map((v) => (
                {key: v, text: v, value: v}
            ))
            let def
            if (nested3){ // nested (eg segment_info)
                def = (
                    (patientData.report) ?
                    patientData.report[nested1][nested2][nested3][k] : null
                )
            }
            else if (nested2){
                def = (
                    (patientData.report) ?
                    patientData.report[nested1][nested2][k] : null
                )
            }
            else if (nested1){
                def = (
                    (patientData.report) ?
                    patientData.report[nested1][k] : null
                )
            }
            else {
                def = (
                    (patientData.report) ?
                    patientData.report[k] : null
                )
            }
            return (
                <Dropdown
                    defaultValue={def}
                    options={options}
                    onChange={(e, data) => this.updateReport(k, data.value, nested1, nested2, nested3)}
                />
            )
        }
        console.log("ERROR: nothing returned from makeDropdown")
    }

    async updateReport(k, value, nested1, nested2, nested3){
        let patientData = {...this.state.patientData}
        if (nested3){
            patientData.report[nested1][nested2][nested3][k] = value
        }
        else if (nested2){
            patientData.report[nested1][nested2][k] = value
        }
        else if (nested1){
            patientData.report[nested1][k] = value
        }
        else {
            patientData.report[k] = value
        }
        const downloadReportButton = await updateReportPDF(
            this.state.vesselData,
            patientData, this.userNotes,
            this.state.modelScreenshot,
            <Button icon="download" className="downloadReport"/>
        )
        // do this outside of render to avoid rebuilding each time
        let editableReport = buildEditableReport(
            patientData, this.state.vesselData, this.state.cadrads,
            this.userNotesForm, this.makeDropdown
        )

        // update state
        this.setState({
            patientData: patientData,
            downloadReportButton: downloadReportButton,
            editableReport: editableReport
        })
    }

        async getWebData(patientID, runID, local){
        let resp
        if (local){
            console.log('getting local web_data')
            resp = await fetch(`http://localhost:3000/s3_mirror/${patientID}/${runID}/web_data.json`)
        }
        else {
            const url = await Amplify.Storage.get(`${patientID}/${runID}/web_data.json`)
            resp = await fetch(url);
        }
        const webData = await resp.json()
        console.log('webdata', webData)
        return webData
    }

    async loadVolumeFile (filename) {
        console.log('loading volume file', filename)
		let volraw = await getH5Data(
			`${this.state.patientID}/${this.state.runID}/${filename}`,
			null, this.props.local
        );
        console.log("got volume file", volraw)
		return volraw;
	}

    async loadPatientData(){

        // load web_data.json with overall patient and vessel data
        console.log('loading patient data', this.state, this.props)
        const patientID = this.state.patientID
        const runID = this.props.dashboardData[patientID].run_id

        console.log('run id', runID)

        const webData = await this.getWebData(patientID, runID, this.props.local)

        console.log("web_data.json", webData)

        // get vessel info / MPRs from h5s
        let vessels = Object.keys(webData.vessel_data)
        console.log('vessels', vessels)

        let vesselInfo = {}
        let vessel
        for (let i=0; i < vessels.length; i++){
            vessel = vessels[i]
            vesselInfo[vessel] = {
                'stenosis': webData.vessel_data[vessel].stenosis,
                'plaque': webData.vessel_data[vessel].plaque,
                'outerArea': webData.vessel_data[vessel].outer_area,
                'lumenArea': webData.vessel_data[vessel].lumen_area,
                'vulnerablePlaque': webData.vessel_data[vessel].vulnerable_plaque,
            }
        }

        console.log('vessel_info', vesselInfo)

        // get scan quality
        let scanQuality = webData.patient_data.scan_quality

        const downloadReportButton = await updateReportPDF(
            webData.vessel_data,
            webData.patient_data, this.userNotes,
            this.state.modelScreenshot,
            <Button icon="download" className="downloadReport"/>
        )

        //update state - triggers refresh
        this.setState({
            patientData: webData.patient_data,
            vesselData: webData.vessel_data,
            scanQuality: scanQuality,
            vesselInfo: vesselInfo,
            downloadReportButton: downloadReportButton,
            runID: runID
        })

        // patientReport
        let editableReport = buildEditableReport(
            webData.patient_data, webData.vessel_data, this.state.cadrads,
            this.userNotesForm, this.makeDropdown
        )

        this.setState({editableReport: editableReport})

        //build vessel summary table - do here so only run once

        let rows = []
        Object.entries(webData.vessel_data).map(([vessel, dct]) => {
            let row = Object.entries(dct.stats).map(([k, v]) => (
                <td key={k}>
                    {(String(v).includes('mm')) ? <span>{v}<sup>3</sup></span>: v}
                </td>
            ))
            rows.push(
                <tr key={vessel}>
                    <td
                    onClick={() => this.setState({reportName: vessel})}>
                        {vessel}
                    </td>
                    {row}
                </tr>
            )
        })

        let vesselSummaryTable = (
            <div className="patientSummary">
                <table className="mainTable">
                    <tr className="headerRow">
                        <th>Vessel</th>
                        <th>Calcium Score</th>
                        <th>Maximum Stenosis</th>
                        <th>CAD RADS</th>
                        <th>Plaque Volume</th>
                        <th>Vulnerable Plaque</th>
                        <th>Leiden Score</th>
                    </tr>
                    <tbody>
                        {rows}
                    </tbody>
                </table>
            </div>
        )

        this.setState({vesselSummaryTable: vesselSummaryTable})

    }

    render(){
        const stateCopy = {...this.state}

        // load patient data if doesnt exist or new patientID in url
        if (!stateCopy.patientData || (stateCopy.patientID !== this.props.match.params.id)){
            if (this.props.dashboardData){ //need for runID
                if (this.props.dashboardData[stateCopy.patientID]){
                    this.loadPatientData()  // handles local vs amplify calls internally
                }
                else {
                    return (
                        <Error {...this.props} />
                    )
                }
            }
        }

        if (this.props.permissionError && !this.props.dashboardData) {
            return (
                <Error {...this.props} />
            )
        }

        if (this.props.dashboardData === 'loading' || (!stateCopy.patientData)){
            return(
                <Loader active inline='centered' style={{marginTop: "20%"}}>
                    Securely Loading Patient Data
                </Loader>
            )
        }

        else {
            try {
                // dont make var for reportName as used in for loops
                const patientID = stateCopy.patientID
                const runID = this.props.dashboardData[patientID].run_id
                const patientData = {...stateCopy.patientData}
                const vesselData = {...stateCopy.vesselData}
                const availableVessels = Object.keys(vesselData)
                const branches = []
                availableVessels.map((vessel) => {
                    if (!this.rhsTabs.includes(vessel)){
                        branches.push(vessel)
                    }
                })
                let vesselID
                if (availableVessels.includes(stateCopy.reportName)){
                    vesselID = stateCopy.reportName
                }
                else {
                    vesselID = null // selected 'Patient' tab
                }

                let tabs = []
                this.rhsTabs.map((reportName) => {
                    if (reportName === 'Patient' || availableVessels.includes(reportName)){
                        tabs.push(
                            <Menu.Item key={reportName}
                            active={stateCopy.reportName===reportName}
                            onClick={() => this.changeState('reportName', reportName)}>
                                {reportName}
                            </Menu.Item>
                        )
                    }
                    else if (reportName === 'Branches'){
                        let items = branches.map((branch) => (
                            {key: branch, text: branch, value: branch}
                        ))
                        tabs.push(
                            <Dropdown key={reportName}
                                active={stateCopy.reportName===reportName}
                                selection item button
                                placeholder="Branch"
                                options={items}
                                value={branches.includes(stateCopy.reportName) ? stateCopy.reportName: null}
                                onChange={(e, data) => this.branchChange(e, data)}
                            />
                        )
                    }
                    else {
                        tabs.push(
                            <Menu.Item disabled > {/* TODO show warning on click */}
                                {reportName}
                            </Menu.Item>
                        )
                    }
                })

                tabs = (
                    <Menu tabular className="main">
                        {tabs}
                    </Menu>
                )

                // get stat tiles
                let stats
                if (stateCopy.reportName === 'Patient'){
                    stats = {...patientData.stats}
                }
                else { // vessel or branch
                    stats = {...vesselData[vesselID].stats}
                }

                let statTiles = Object.entries(stats).map(([key, v]) => (
                    <div className="statTile">
                        <div className="inner">
                            <div className="front">
                                <div className="topHalf">
                                    <div>
                                        {STATS_KEYS[key]}
                                    </div>
                                </div>
                                <hr/>
                                <div className="bottomHalf">
                                {(String(v).includes('mm')) ? <span>{v}<sup>3</sup></span>: v}
                                </div>
                            </div>
                        </div>
                        {getPopup(
                            <a href={this.references[key]} target="_blank">
                                <Icon
                                    className={(this.references[key]) ? 'clickable': null}
                                    name="info" size="small"
                                />
                            </a>,
                            <div className="content">{this.statTileInfo[key]}</div>,
                            {basic: true, position: "bottom center"}
                        )}
                    </div>
                ))

                // make report
                let reportTabs
                let report
                if (stateCopy.reportName === 'Patient'){
                    reportTabs = (
                        <Menu tabular className="secondary">
                            <Menu.Menu>
                                {['Report', 'Summary'].map((opt) => (
                                    <Menu.Item key={opt}
                                        active={stateCopy.reportType == opt}
                                        onClick={() => this.changeState('reportType', opt)}>
                                            {opt}
                                    </Menu.Item>
                                ))}
                            </Menu.Menu>
                            {stateCopy.reportType === 'Report'&&(
                                <Menu.Menu position="right">
                                    <Menu.Item>
                                        <Button icon="download" className="downloadReport"/>
                                        {/*{this.state.downloadReportButton}*/}
                                    </Menu.Item>
                                </Menu.Menu>
                            )}
                        </Menu>
                    )

                    if (this.state.reportType === 'Summary'){
                        // Vessel overview
                        report = this.state.vesselSummaryTable
                    }
                    else { // Editable report
                        report = this.state.editableReport
                    }
                }
                else if (availableVessels.includes(vesselID)) { // Vessel MPR viewer
                    report = (
                        <VesselViewer
                            local={this.props.local}
                            vesselInfo={stateCopy.vesselInfo}
                            sliceidx={stateCopy.sliceidx}
                            vesselID={vesselID}
                            patientID={patientID}
                            runID={runID}
                            changeState={this.changeState}
                        />
                    )
                }
                else {
                    report = (
                        <div>
                            <br/>
                            <div>No report available for {vesselID}</div>
                        </div>
                    )
                }

                let viewerTabs = []
                let viewerTabNames = ['Tree', 'CT Volume', 'Analysis']
                viewerTabNames.map((opt) => {
                    viewerTabs.push(
                        <Menu.Item
                            active={stateCopy.viewer === opt}
                            onClick={() => this.changeState('viewer', opt)}>
                                {opt}
                        </Menu.Item>
                    )
                })

                viewerTabs = (
                    <Menu tabular className="main">
                        {viewerTabs}
                    </Menu>
                )

                let viewerContent

                if (this.state.viewer == 'Analysis'){
                    viewerContent = (
                        <div className="viewer">
                            <div className="analysis">
                                <Analysis
                                    data={stateCopy.scanQuality}
                                    vessels={availableVessels}
                                />
                            </div>
                        </div>
                    )
                }

                else if (this.state.viewer == 'Tree'){
                    viewerContent = (
                        <div className="viewer mainDiv" id="scene-container">
                            {stateCopy.modelOverlayButtons}
                            <Model
                                patientID={patientID}
                                runID={runID}
                                vessels={availableVessels}
                                vesselID={vesselID}
                                className="model"
                                changeStateDct={this.changeStateDct}
                                local={this.props.local}
                                colmapStenosis={'ironman.json'}
                                colmapLeiden={'blurst.json'}
                                takeModelScreenshot={stateCopy.takeModelScreenshot}
                                resetCamera={stateCopy.resetCamera}
                                togglePlaque={stateCopy.togglePlaque}
                                toggleLabels={stateCopy.toggleLabels}
                                reportName={stateCopy.reportName}
                                sliceidx={stateCopy.sliceidx}
                                vesselData={stateCopy.vesselData}
                            />


                        </div>
                    )
                }

                else if (this.state.viewer == 'CT Volume'){
                    let contrast = stateCopy.volumeFile === 'volume_contrast.h5'
                    let volume = (contrast) ? stateCopy.contrastVolume : stateCopy.noncontrastVolume

                    let slicer
                    if (contrast){
                        slicer = <ContrastSmartSlicer
                          changeStateDct={this.changeStateDct}
                          patientID={patientID}
                          runID={runID}
                          availableVessels={availableVessels}
                          local={false}
                          windowlevel={this.state.windowlevel}
                          windowwidth={this.state.windowwidth}
                        />
                    }
                    else {
                        slicer = <NonContrastSmartSlicer
                          changeStateDct={this.changeStateDct}
                          patientID={patientID}
                          runID={runID}
                          local={false}
                          windowlevel={this.state.windowlevel}
                          windowwidth={this.state.windowwidth}
                        />
                    }

                    viewerContent = (
                        <div className="viewer mainDiv ctvolume"
                            onMouseEnter={() => this.changeBodyScroll('mouseOverScanSlicer')}
                            onMouseLeave={() => this.changeBodyScroll('mouseOverScanSlicer')}
                        >
                            <div className="buttons">
                                <Button
                                active={stateCopy.volumeFile==='volume_contrast.h5'}
                                onClick={() => this.setState({volumeFile: 'volume_contrast.h5'})}>
                                    Contrast
                                </Button>
                                <Button
                                active={stateCopy.volumeFile==='volume_non_contrast.h5'}
                                onClick={() => this.setState({volumeFile: 'volume_non_contrast.h5'})}>
                                    Non-Contrast
                                </Button>
                            </div>
                            {slicer}
                        </div>
                    )
                }
                else {
                    viewerContent = (
                        <div className="viewer mainDiv">

                        </div>
                    )
                }

                return(
                    <div>
                        <div className="pageBody scan">
                            <div className="topDiv">
                               {/*  <div className="scanSelection">
                                    <span>
                                        <strong>Scan: </strong>
                                        <Dropdown selection button
                                            defaultValue={"Latest"}
                                            options={[
                                                {key: 'Latest', value: 'Latest', text: 'Latest'}
                                            ]}
                                        />
                                    </span>
                                </div> */}

                                <span className="patientId">
                                    {this.state.patientName}
                                </span>

                                <Button
                                className="approveButton"
                                onClick={this.showReport}>
                                    Approve
                                </Button>
                            </div>
                            <div className="lhs">
                                <div className="header"> {/* header of 3d model viewer */}
                                    {viewerTabs}
                                </div>
                                {viewerContent}
                            </div>
                            <div className="rhs" ref={this.rhsRef}>
                                {tabs}
                                <div className="container mainDiv">
                                    <div className="statTiles">
                                        {statTiles}
                                    </div>
                                    {reportTabs}
                                    {report}
                                </div>
                            </div>
                        </div>
                    </div>
                )

            }
            catch (err) {
                console.log("ERROR", err)
                return (
                    <Error {...this.props} />
                )
            }
        }
    }
}


export default C
