import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-konva';
import { clampNumber } from '../../utils';

const LINE_WIDTH = 0.5;
// TODO below is a magic number, change such that
// it reflects the actual MPR slice width
const VIEW_WIDTH = 50;
const DRAG_CURSOR = 'ns-resize';

export function CprAnnotations(props){
  // required values haven't beenn set yet
  if(!props.numSlices || !props.points) return null;
  const points = props.points.map(x=>[x[0]*props.scaleFactor,x[1]*props.scaleFactor]);
  return (
    <>
      <CprCentreLine
        sliderIdx={props.sliderIdx}
        points={points}
        scaleFactor={props.scaleFactor}
      />
      <CprSliceLine
        width={props.width}
        height={props.height}
        scaleFactor={props.scaleFactor}
        sliceIdx={props.sliceIdx}
        clPoints={points}
        changeState={props.changeState}
        numSlices={props.numSlices}
      />
    </>
  );
}
//
function CprCentreLine(props){
  const drawablePoints = [];
  for(let i = 0; i < props.points.length; i++){
    drawablePoints.push(props.points[i][0]);
    drawablePoints.push(props.points[i][1]);
  }
  return (
    <Line
      points={drawablePoints}
      tension={0}
      stroke={'orange'}
      opacity={0.8}
      strokeWidth={LINE_WIDTH*props.scaleFactor}
    />
  )
}

const linInterp = (points, t) =>{
  const val = t-Math.floor(t);
  const rv = [];
  for(let i = 0; i < 2; i++){
    const [a, b] = [points[Math.floor(t)][i], points[Math.ceil(t)][i]];
    rv.push(a+(b-a)*val);
  }
  return rv;
}

const linDdtInterp = (points, t)=>{
  /**
   * Calculates ddt at t for any R^n
   * Extrapolates initial and final ddt infinitely for out of bounds values
   * returns a normalised represetation of ddt @ t
   */
  if(points.length < 2) return console.error('centreline requires at least 2 points');
  const val = Math.round(t);
  if(val <= 0){
    const rv = points[1].map((x,idx)=>x-points[0][idx]);
    const rvMag = Math.sqrt(rv.map(x=>x**2).reduce((tot,curr)=>tot+curr));
    return rv.map(x=>x/rvMag);
  }
  if(val >= points.length - 1){
    const rv = points[points.length-1].map((x,idx)=>x-points[points.length-2][idx]);
    const rvMag = Math.sqrt(rv.map(x=>x**2).reduce((tot,curr)=>tot+curr));
    return rv.map(x=>x/rvMag);
  }
  let lhs = points[val].map((x,idx)=>x-points[val-1][idx]);
  const lhsMag = Math.sqrt(lhs.map(x=>x**2).reduce((tot,curr)=>tot+curr));
  lhs = lhs.map(x=>x/lhsMag);
  let rhs = points[val+1].map((x,idx)=>x-points[val][idx]);
  const rhsMag = Math.sqrt(rhs.map(x=>x**2).reduce((tot,curr)=>tot+curr));
  rhs = rhs.map(x=>x/rhsMag);
  const p = t-(val-0.5);
  const rv = lhs.map((x,idx)=>rhs[idx]*p + (1-p)*x);
  const rvMag = Math.sqrt(rv.map(x=>x**2).reduce((tot,curr)=>tot+curr));
  return rv.map(x=>x/rvMag);
}
//
function CprSliceLine(props){
  //not caring about zeros for now
  //below only works for origins within stage width and height
  const [changeState, setChangeState] = useState(()=>props.changeState);
  const [scaleFactor, setScaleFactor] = useState(props.scaleFactor);
  const [width, setWidth] = useState(props.width);
  const [height, setHeight] = useState(props.height);
  const [sliceIdx, setSliceIdx] = useState(props.sliceIdx);
  const [numSlices, setNumSlices] = useState(props.numSlices);
  const [lineNode, setLineNode] = useState(null);
  const [stageNode, setStageNode] = useState(null);
  const [clPoints, setClPoints] = useState(props.clPoints);

  // check what state to update
  useEffect(()=>{
    if(scaleFactor !== props.scaleFactor) setScaleFactor(props.scaleFactor);
    if(width !== props.width) setWidth(props.width);
    if(height !== props.height) setHeight(props.height);
    if(sliceIdx !== props.sliceIdx) setSliceIdx(props.sliceIdx);
    if(numSlices !== props.numSlices) setNumSlices(props.numSlices);
    if(clPoints !== props.clPoints) setClPoints(props.clPoints);
  },[props]);

  const sf = props.scaleFactor;
  const yToSliceFactor = clPoints.length / numSlices;
  const currT = clampNumber(Math.round(sliceIdx * yToSliceFactor),0, clPoints.length);
  let direction = linDdtInterp(props.clPoints, currT);
  direction = [direction[1], -direction[0]];
  const [p1, p2] = [-1,1].map(y=>direction.map(x=>y*VIEW_WIDTH/2*sf*x));
  //TODO add clampNumber here with the correct maximum widths and heights below is broken
  const [ox, oy] = clPoints[currT];
  //width and height isn't calculated properly so commenting out for now
  //const points = [p1,p2].map(x=>[clampNumber(x[0]+ox,0,width*sf)-ox,clampNumber(x[1]+oy,0,height*sf)-oy]);
  const points = [p1,p2];
  const setNodes = useCallback(ref=>{
    setLineNode(ref);
    setStageNode((ref)?ref.getStage():null);
  },[]);
  //placeholder
  const disabled = false;
//
  // CALLBACK FUNCTIONS
  // would like to find an alternative to the transformations if possible
  // TODO refactor to base off deltaPos rather than pointer pos
  const dragHandler = useCallback((pos)=>{
    const transform = lineNode.getAbsoluteTransform().copy();
    const invert = lineNode.getAbsoluteTransform().copy();
    invert.invert();
    const [start, end] = [clPoints[0], clPoints[clPoints.length-1]];
    let newPos = invert.point(lineNode.getStage().getPointerPosition());
    //below relies on 1 px per cl point (not planned to change)
    newPos.y = clampNumber(lineNode.y()+newPos.y,start[1],end[1])-lineNode.y();
    const t = (newPos.y+lineNode.y()-start[1])*(clPoints.length-1)/(end[1]-start[1]);
    const x = linInterp(clPoints, t)[0];
    newPos.x = x-lineNode.x();
    let newSlope = linDdtInterp(clPoints, t);
    newSlope = [-newSlope[1],newSlope[0]];
    // temp origin
    const [tpOx, tpOy] = [lineNode.x() + newPos.x, lineNode.y() + newPos.x];
    const [tpP1, tpP2] = [1,-1].map(y=>newSlope.map(x=>y*VIEW_WIDTH/2*scaleFactor*x));
    // TODO fix clamping to width and height such that it works properly
    // commenting out for now
    // const newPoints = [tpP1,tpP2].map(x=>[clampNumber(x[0]+tpOx,0,width*scaleFactor)-tpOx,clampNumber(x[1]+tpOy,0,height*scaleFactor)-tpOy]);
    const newPoints = [tpP1,tpP2];
    lineNode.points([].concat(...newPoints));
    return transform.point(newPos);
  },[lineNode, clPoints, scaleFactor, width, height]);

  const dropHandler = useCallback(()=>{
    // uhh trust me it works
    const yToSliceFactor  = clPoints.length / numSlices;
    const currT = clampNumber(Math.round(sliceIdx * yToSliceFactor),0, clPoints.length);
    const deltaSliceIdx = Math.round(((lineNode.y()-clPoints[currT][1])/scaleFactor)/yToSliceFactor);
    const nextSliceIdx = clampNumber(sliceIdx + deltaSliceIdx, 0, numSlices - 1);
    //update to snap dragged piece even if value doesn't change
    changeState({sliceidx:nextSliceIdx});
  },[lineNode,changeState,scaleFactor,sliceIdx, numSlices, clPoints]);

  const updateCursor = useCallback((value, event)=>{
    if(disabled) return;
    // We stop the event from bubbling to control the cursor at the SliceLine level.
    // Otherwise, the cursor set on the WindowView takes over.
    if(event.type==='dragstart') event.cancelBubble = true;
    stageNode && (stageNode.container().style.cursor = value);
  }, [disabled, stageNode]);

  return (
    <Line
      ref={setNodes}
      x={ox}
      y={oy}
      points={[].concat(...points)}
      stroke={'#00ffa0'}
      opacity={0.7}
      strokeWidth={LINE_WIDTH*2*sf}
      dash={[6, 3]}
      draggable={true}
      //prevent bubbling to stage when dragging
      //TODO fix initial bubble on drag
      onMouseDown={(event)=>event.evt.preventDefault()}
      onMouseUp={(event)=>event.evt.preventDefault()}
      onMouseOver={updateCursor.bind(null, DRAG_CURSOR)}
      onMouseLeave={updateCursor.bind(null, 'auto')}
      dragBoundFunc={dragHandler}
      onDragEnd={dropHandler}
    />
  )
}



CprAnnotations.propTypes = {
  scaleFactor: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  points: PropTypes.array.isRequired,
  numSlices: PropTypes.number.isRequired,
  sliceIdx: PropTypes.number.isRequired,
  changeState: PropTypes.func.isRequired
};
