import styled from 'styled-components';

export const WindowViewContainerStyled = styled.div`
  border: 1px solid #f0f0f0;
  display: inline-block;
`;

export const WindowViewStyled = styled.canvas`
  vertical-align: top;
  cursor: ${(props) => (props.interactive ? 'crosshair' : 'auto')};
`;
