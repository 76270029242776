import React from 'react'
import { Menu, Icon, Image, Dropdown, Button, Radio } from 'semantic-ui-react'
import { getPopup } from './utils'
import { Layer, Stage } from 'react-konva';
//import { MprSlicer, SmprSlicer, CprSlicer } from './slicer'
import VerticalPlot from './plot'
import { CprSlicer, MprSlicer, SmprSlicer } from './slicer'
import { SimpleWindower } from './windower'
import { CprViewer } from './view-loader'


class C extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mpr: 'CPR',
      mouseOverScanSlicer: false,
      mouseOverMprSlicer: false,
      mouseOverSmprSlicer: false,
      //vesselViewerStyle: {overflow: 'auto'},
      showWalls: true,
      showCl: true,
      resetControlsFlag: false,
      windowLevel: 220,
        windowWidth: 740,
    }
    this.sliceidx = 0
    this.changeBodyScroll = this.changeBodyScroll.bind(this)
    this.changeStateDct = this.changeStateDct.bind(this)
    this.changeState = this.changeState.bind(this)
    this.setSliceIdx = this.setSliceIdx.bind(this)
  }

  changeBodyScroll(k, v){
    let style = document.body.style.overflow
    //let vesselViewerOverflow
    if (v){
      document.body.style.overflow = 'hidden'
      //vesselViewerOverflow = 'hidden'
    }
    else {
      document.body.style.overflow = 'auto'
      //vesselViewerOverflow = 'auto'
    }
    this.setState({
      [k]: v,
      //vesselViewerStyle: {overflow: vesselViewerOverflow}
    })
  }

  changeState(k, v){
    console.log('changing state vv', k, v)
    this.setState({[k]: v});
  }

  changeStateDct(dct){
    // if screenshot taken update pdf report
    this.setState(dct)
    // pass back to page such that smart slicers may use
    if ('windowWidth' in dct)
      this.props.changeState('windowWidth', dct.windowWidth);
    if ('windowLevel' in dct)
      this.props.changeState('windowLevel', dct.windowLevel);
    if ('sliceidx' in dct){
      // pass back to Patient component for 3D model
      this.sliceidx = dct.sliceidx;
      this.forceUpdate();
      this.props.changeState('sliceidx', dct.sliceidx);
    }
  }

  setSliceIdx(v){
    this.sliceidx = v
    this.forceUpdate()
    this.props.changeState('sliceidx', v)
  }

  componentDidMount(){

  }

  shouldComponentUpdate(nextProps, nextState){
    // do this check before rendering
    // in order to avoid double render
    if (nextProps.sliceidx !== this.props.sliceidx){
      // caused by clicking on 3d model
      this.sliceidx = nextProps.sliceidx
    }
    return true
  }

  render() {

    if (!this.props.vesselInfo){
      return
    }

    let mprslicer

    // TODO: the pan zoom reset button
    /*
    {getPopup(
      <Button
        className="resetPanZoom"
        icon="redo"
        onClick={() => {
          this.setState({resetControlsFlag: true})
        }}
      />,
      `Reset pan and zoom`,
      {basic: true, position: "left center", mouseEnterDelay: 500}
    )}
    */

    let buttons = (
      <div className="mprToggle toggleButtons">
        <span
        className={(this.state.mpr === 'CPR') ? 'underline': null}
        onClick={() => this.setState({mpr: 'CPR'})}
        >
        Curved
        </span>
        <span
        className={(this.state.mpr === 'SMPR') ? 'underline': null}
        onClick={() => this.setState({mpr: 'SMPR'})}
        >
        Straightened
        </span>
      </div>
    )

    if (this.state.mpr === 'SMPR'){
      mprslicer = (
        <div className="smprSlicer"
        //onMouseEnter={() => this.changeBodyScroll('mouseOverSmprSlicer', true)}
        //onMouseLeave={() => this.changeBodyScroll('mouseOverSmprSlicer', false)}
        >
            {buttons}
            <SmprSlicer
              patientID={this.props.patientID}
              runID={this.props.runID}
              vesselID={this.props.vesselID}
              local={this.props.local}
              sliceidx={this.sliceidx}
              //mouseOver={this.state.mouseOverSmprSlicer}
              windowLevel={this.state.windowLevel}
              windowWidth={this.state.windowWidth}
              changeState={this.changeState}
              setSliceIdx={this.setSliceIdx}
              resetControlsFlag={this.state.resetControlsFlag}
            />
        </div>
      )
    }

    else {
      mprslicer = (
        <div
        className="cprSlicer"
        //onMouseEnter={() => this.changeBodyScroll('mouseOverCprSlicer', true)}
        //onMouseLeave={() => this.changeBodyScroll('mouseOverCprSlicer', false)}
        >
          {buttons}
          {/*
          <CprSlicer
            patientID={this.props.patientID}
            runID={this.props.runID}
            vesselID={this.props.vesselID}
            local={this.props.local}
            //mouseOver={this.state.mouseOverCprSlicer}
            className="cpr"
            sliceidx={this.sliceidx}
            windowLevel={this.state.windowLevel}
            windowWidth={this.state.windowWidth}
            changeState={this.changeState}
            setSliceIdx={this.setSliceIdx}
            resetControlsFlag={this.state.resetControlsFlag}
          />
          */}
          <CprViewer
            patientID={this.props.patientID}
            runID={this.props.runID}
            local={this.props.local}
            vesselID={this.props.vesselID}
            windowWidth={this.state.windowWidth}
            windowLevel={this.state.windowLevel}
            changeStateDct={this.changeStateDct}
            sliceIdx={this.sliceidx}
            resetControlsFlag={this.state.resetControlsFlag}
            smoothing={true}
            //onMouseMove={(...args) => action('onMouseMove')(...args)}
            //onImageLoad={({ height }) => setHeight(height)}
          />
        </div>
      )
    }

    const vesselInfo = this.props.vesselInfo[this.props.vesselID]

    return (
      <div className="report vesselViewer" style={this.state.vesselViewerStyle}> {/* 4 columns*/}
        {/* vertical line/bar plot */}

        {/* side view of vessel (curved/straightened) */}
        {mprslicer}

        {/* vessel cross section */}
        <div className="mprSlicer" >
        <div className="mprToggle" />

          <div
          onMouseEnter={() => this.changeBodyScroll('mouseOverMprSlicer', true)}
          onMouseLeave={() => this.changeBodyScroll('mouseOverMprSlicer', false)}
          >
            <MprSlicer
              patientID={this.props.patientID}
              runID={this.props.runID}
              vesselID={this.props.vesselID}
              local={this.props.local}
              mouseOver={this.state.mouseOverMprSlicer}
              changeState={this.changeState}
              sliceidx={this.sliceidx}
              windowLevel={this.state.windowLevel}
              windowWidth={this.state.windowWidth}
              setSliceIdx={this.setSliceIdx}
            />
          </div>

          {/* controls for window width and Level */}
          <SimpleWindower
            changeStateDct={this.changeStateDct}
            windowLevel={this.state.windowLevel}
            windowWidth={this.state.windowWidth}
          />

          <div className="mprInfo">
            <table>
              <tbody>
                <tr>
                  <td>Slice</td>
                  <td>
                    <input
                      type="number"
                      min={1}
                      max={vesselInfo.lumenArea.length}
                      value={this.sliceidx}
                      onChange={(e) => this.setSliceIdx(parseInt(e.target.value) - 1)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Vessel mm<sup>2</sup></td>
                  <td>{vesselInfo.outerArea[this.sliceidx]}</td>
                </tr>
                <tr>
                  <td>Lumen mm<sup>2</sup></td>
                  <td>{vesselInfo.lumenArea[this.sliceidx]}</td>
                </tr>
                <tr>
                  <td>Stenosis (%)</td>
                  <td>{(vesselInfo.stenosis[this.sliceidx] * 100).toFixed(3)}</td>
                </tr>
                <tr>
                  <td>Calcified Plaque mm<sup>3</sup></td>
                  <td>{vesselInfo.plaque[this.sliceidx]}</td>
                </tr>
                {/*
                <tr>
                  <td>Soft Plaque mm<sup>3</sup></td>
                  <td>{vesselInfo.plaque[this.sliceidx]}</td>
                </tr>
                */}
                <tr>
                  <td>Vulnerable Plaque</td>
                  <td>{vesselInfo.vulnerablePlaque.includes(this.sliceidx) ? 'Present': 'Absent'}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
}


export default C
