	import React from 'react'
	import { Menu, Icon, Image, Dropdown } from 'semantic-ui-react'
	import { Link } from 'react-router-dom';
	import { Auth } from 'aws-amplify';
	import { DownloadViewer } from './download';

	const logoPath = require('../public/logo.png')

	class Navbar extends React.Component {
		constructor(props) {
			super(props);
			this.state = {}
			this.patientId = props.patientID
		}

	logout() {
		console.log("Logout");
		Auth.signOut();
	}

	render() {

		console.log("NAV", this.props)
		
		let bgClass = (this.props.errorPage === true) ? 'navBarBg': 'navBarBg'
		
		let lhsMenu

		// TODO
		if (!this.props.onDashboard){
			lhsMenu = (
				<Menu.Menu position="left">
					<Menu.Item>
						<Link to="/dashboard" className="backToDashboard">
							<Icon name="arrow left"/>
							Back to Dashboard
						</Link>
					</Menu.Item>
				</Menu.Menu>
			)
		}
		else {
			lhsMenu = (
				<Menu.Menu position="left">
					<Menu.Item>
						<Image className="logo" src={logoPath} size="mini"/>
					</Menu.Item>
				</Menu.Menu>
			)
		}
		
		return (
			<Menu secondary attached="top" fluid={true} className={`${bgClass} huge navBar`}>
				{lhsMenu}
				<Menu.Menu>
					<Menu.Item>
						{this.patientId}
					</Menu.Item>
				</Menu.Menu>
				<Menu.Menu position="right">
					<Menu.Item>						
						<DownloadViewer></DownloadViewer>
					</Menu.Item>
					<Menu.Item>
						<Link to="/help" className="navButton">
							<div className="courier">Help</div>
						</Link>
					</Menu.Item>
					<Menu.Item className="navButton">
						<Dropdown className="accDropdown" icon="user md" button>
							<Dropdown.Menu>
								<Dropdown.Item>
									<div className="testing" onClick={this.logout}>Logout</div>
								</Dropdown.Item>
								<Dropdown.Item>
									<Link to="/account">
										<div className="testing">Settings</div>
									</Link>
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</Menu.Item>
				</Menu.Menu>
			</Menu>
			)
		}
	}

	export default Navbar