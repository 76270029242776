import PropTypes from 'prop-types';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Image } from 'react-konva';
import Logger from '../../utils/logger';
import { useForkRef } from '../../utils/use-fork-ref';
import {
  addWindowedMatrix2dToBuffer,
  getEmptyBuffer,
  getRelativePointerPosition,
  isCanvas,
} from '../utils';
import {
  WindowViewContainerStyled,
  WindowViewStyled,
} from './window-view.styled';

const CANVAS_CONFIG = {
  initialHeight: 400,
  initialWidth: 400,
  smoothing: true,
};

/**
 * The `WindowView` component is responsible to render a given slice (2d matrix).
 * `WindowView` has no concept of what type of slice it receives (MPR, etc).
 * Given a valid slice, mouse movements over the canvas return the HU value under the mouse cursor.
 * Given an invalid slice, the canvas remains empty and no mouse movements are triggered.
 * The drawn slice image is stretched to fit the `width` & `height` of the given canvas size (via props or `CANVAS_CONFIG`).
 * The drawn image can be adjusted with the `windowLevel` and `windowWidth` props.
 *
 * @param {number[][]} sliceData - 2d matrix that is used to draw onto the canvas
 * @param {windowLevel} windowLevel - used to adjust the canvas drawing
 * @param {windowWidth} windowWidth - used to adjust the canvas drawing
 * @param {width} width - the width of the canvas element when added to a view,
 * @param {height} height - the height of the canvas element when added to a view
 * @param {function(HUValue, event):void} onMouseMove - callback that returns the HU value under the mouse cursor and the original MouseEvent
 * @param {function(event):void} onMouseLeave - callback that returns the original MouseEvent (used for Konva:Shape)
 * @param {function(event):void} onMouseOut - callback that returns the original MouseEvent (used for Canvas)
 * @param {boolean} useShape - toggle return type of component between Canvas element and Konva:Image.
 * @property {ref} ref - reference to the canvas element that is used to draw on
 */
const WindowView = forwardRef(function WindowView(
  {
    sliceData,
    useShape,
    smoothing,
    width,
    height,
    windowLevel,
    windowWidth,
    onMouseEnter,
    onMouseMove,
    onMouseOut,
    onMouseLeave,
    onImageLoad,
    onCanvasUpdate,
    ...props
  },
  ref
) {
  const [interactive, setInteractive] = useState(false);
  const KonvaImageRef = useRef(null);
  const CanvasRef = useRef(null);
  const [sliceDimension, setSliceDimension] = useState({ width: 0, height: 0 });
  const previousSliceDimension = useRef(sliceDimension);

  const handleMouseMove = useCallback(
    (event) => {
      let canvas = null;

      if (useShape) {
        canvas = KonvaImageRef.current.image();
      } else {
        canvas = CanvasRef.current;
      }

      if (!canvas || !interactive) {
        // not ready yet
        return null;
      }

      let pointerX = null;
      let pointerY = null;

      if (useShape) {
        const { x, y } = getRelativePointerPosition(event.target);
        pointerX = x;
        pointerY = y;
      } else {
        pointerX = event.clientX;
        pointerY = event.clientY;
      }

      const width = sliceData[0].length - 1;
      const height = sliceData.length - 1;
      const windowRect = canvas.getBoundingClientRect();
      const mouseX = pointerX - windowRect.left;
      const mouseY = pointerY - windowRect.top;
      // x and y should never be negative
      const x = Math.max(Math.round((mouseX / canvas.width) * width), 0);
      const y = Math.max(Math.round((mouseY / canvas.height) * height), 0);

      // Get the HU Value from the "pixel" under the cursor of the visible slice
      const HUValue = sliceData[y][x];

      onMouseMove && onMouseMove(HUValue, event);
    },
    [interactive, onMouseMove, sliceData, useShape]
  );

  const handleMouseEnter = useCallback(
    (event) => {
      if (interactive) {
        event.target.getStage().container().style.cursor = 'crosshair';
      }
      onMouseEnter && onMouseEnter(event);
    },
    [interactive, onMouseEnter]
  );

  // Used for Canvas element
  const handleMouseOut = useCallback(
    (event) => onMouseOut && onMouseOut(event),
    [onMouseOut]
  );

  // `mouseLeave` doesn't bubble up, ideal use for specific element (Konva:Shape).
  const handleMouseLeave = useCallback(
    (event) => {
      if (interactive) {
        event.target.getStage().container().style.cursor = null;
      }
      onMouseLeave && onMouseLeave(event);
    },
    [interactive, onMouseLeave]
  );

  const updateCanvas = useCallback(
    (slice, windowWidth, windowLevel) => {
      let canvas = null;
      let usedCanvas = null;

      if (useShape) {
        if (isCanvas(KonvaImageRef.current.image())) {
          usedCanvas = 'Konva:Image';
          canvas = KonvaImageRef.current.image();
        } else {
          usedCanvas = 'new Canvas';
          canvas = document.createElement('canvas');
          KonvaImageRef.current.image(canvas);
        }
      } else {
        usedCanvas = 'existing Canvas';
        canvas = CanvasRef.current;
      }

      if (
        !canvas ||
        !slice ||
        !Number.isInteger(windowWidth) ||
        !Number.isInteger(windowLevel)
      ) {
        // not ready yet
        setInteractive(false);
        Logger.Info(`Canvas not ready`);
        return;
      }

      canvas.width = width;
      canvas.height = height;

      const dimension = { width: slice[0].length, height: slice.length };
      const previousDimension = previousSliceDimension.current;

      Logger.Info(
        `Updating ${usedCanvas}:`,
        `ww: ${windowWidth}`,
        `wl: ${windowLevel}`,
        `size: ${dimension.width}x${dimension.height}`
      );

      // Scale data to fit to canvas, in all slicing cases:
      // - the data is stretched to fit the canvas at the original aspect ratio
      // - the data needs to be stretched to fit the canvas
      //   (fit to width, then scroll vertically in canvas)
      // Begin by calculating the scale factor required to fit the width
      // const scaleFactor = mprImageDimension.width / dimension.width;
      // Resize the slice matrix to fit the canvas
      // const data = resizeMatrix(slice, scaleFactor);

      // Update the canvas height to match the amount of cross section slices
      canvas.height = canvas.width * (dimension.height / dimension.width);

      // Create an empty image buffer to put image data and annotations into
      let buffer = getEmptyBuffer(dimension.height, dimension.width);

      // Write image data from the volume slice into the buffer
      buffer = addWindowedMatrix2dToBuffer(
        buffer,
        slice,
        windowLevel,
        windowWidth
      );

      // Create an image data object, note flipped dimensions for correct
      // writing order of rows and cols
      const ctx = canvas.getContext('2d');

      // Smooths the scaled image to the nearest neighbour, if enabled
      ctx.imageSmoothingEnabled = smoothing ?? CANVAS_CONFIG.smoothing;

      // adding an inception canvas to try and downsample correctly
      // deprecating single canvas solution for now

      const imageData = ctx.createImageData(dimension.width, dimension.height);
      imageData.data.set(buffer);

      // Clear the canvas before we put the new image
      // This gets rid of a shadow from stacking images on top of each other.
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // found this hack here:
      // https://stackoverflow.com/a/51394338/1166161
      ctx.globalCompositeOperation = 'copy';

      // below solves both upscaling and downscaling
      // approach with one canvas truncated when downscaling

      const img = document.createElement('canvas');
      const imgCtx = img.getContext('2d');
      img.width = dimension.width;
      img.height = dimension.height;
      imgCtx.putImageData(imageData, 0, 0);
      imgCtx.drawImage(img, 0, 0, img.width, img.height);

      ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, canvas.width, canvas.height);


      if (
        previousDimension.width !== canvas.width ||
        previousDimension.height !== canvas.height
      ) {
        const newDimension = { width: canvas.width, height: canvas.height };
        setSliceDimension(newDimension);
      }

      if (useShape) {
        KonvaImageRef.current.image(canvas);
        KonvaImageRef.current.getStage().batchDraw();
      }

      setInteractive(true);
    },
    [useShape, width, height, smoothing]
  );

  const clearCanvas = useCallback(() => {
    const canvas = CanvasRef.current || KonvaImageRef.current;

    if (canvas) {
      Logger.Info('Clearing canvas');
      if (useShape) {
        canvas.image(null);
        canvas.getStage().batchDraw();
      } else {
        const ctx = canvas.getContext('2d');
        canvas.height = props.height ?? CANVAS_CONFIG.initialHeight;
        ctx.clearRect(0, 0, canvas.width, canvas.height);
      }
    }
  }, [props.height, useShape]);

  const handleImageDimensionChange = useCallback(
    (nextDimension, prevDimension) => {
      Logger.Info(
        `Dimension change ${prevDimension.width}x${prevDimension.height} -> ${nextDimension.width}x${nextDimension.height}`
      );
      previousSliceDimension.current = nextDimension;
      onImageLoad && onImageLoad(nextDimension);
    },
    [onImageLoad]
  );

  useEffect(() => {
    // Only update canvas is we are dealing with a 2d matrix
    if (Array.isArray(sliceData) && Array.isArray(sliceData[0])) {
      updateCanvas(sliceData, windowWidth, windowLevel);
      onCanvasUpdate && onCanvasUpdate();
    } else {
      setInteractive(false);
      clearCanvas();
    }
  }, [clearCanvas, sliceData, updateCanvas, windowLevel, windowWidth]);

  useEffect(() => {
    if (
      useShape &&
      KonvaImageRef.current &&
      KonvaImageRef.current.image() &&
      sliceDimension.width > 0 &&
      sliceDimension.height > 0
    ) {
      handleImageDimensionChange(
        sliceDimension,
        previousSliceDimension.current
      );
    }
  }, [handleImageDimensionChange, sliceDimension, useShape]);

  const handleImageRef = useForkRef(KonvaImageRef, ref);
  const handleCanvasRef = useForkRef(CanvasRef, ref);

  if (useShape) {
    return (
      <Image
        ref={handleImageRef}
        {...props}
        onMouseMove={handleMouseMove}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      />
    );
  } else {
    return (
      <WindowViewContainerStyled>
        <WindowViewStyled
          interactive={interactive}
          ref={handleCanvasRef}
          width={width}
          height={height}
          onMouseMove={handleMouseMove}
          onMouseOut={handleMouseOut}
        />
      </WindowViewContainerStyled>
    );
  }
});

WindowView.propTypes = {
  sliceData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
  windowLevel: PropTypes.number,
  windowWidth: PropTypes.number,
  smoothing: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  onMouseMove: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onMouseOut: PropTypes.func,
  useShape: PropTypes.bool,
};

WindowView.defaultProps = {
  windowLevel: 240,
  windowWidth: 720,
  width: CANVAS_CONFIG.initialWidth,
  height: CANVAS_CONFIG.initialHeight,
  smoothing: CANVAS_CONFIG.smoothing,
  useShape: false,
};

export default WindowView;
