import React from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { relativeRadiusToValue } from '@amcharts/amcharts4/.internal/core/utils/Utils';

am4core.useTheme(am4themes_animated);
am4core.options.autoSetClassName = true;

const LINES = {
    'stenosis': '#FF8700',
    'plaque': '#34E9B4'
}

class C extends React.Component {

    constructor(props){
        super(props)
    }

  componentDidMount() {
    window.chart = this
    this.reload()
  }

  reload(){

    this.chart = am4core.create("container", am4charts.XYChart)
    this.chart.height = am4core.percent(100);

    this.chart.paddingRight = 0;
    this.chart.paddingLeft = 0
    this.chart.paddingBottom = 0
    this.chart.paddingTop = 0
    this.chart.marginLeft = 0
    this.chart.marginRight = 0
    this.chart.marginBottom = 0

    let data
    
    Object.entries(this.props.vesselInfo).map(([k, vals]) => {
        // create list of dictionaries if not yet defined
       if (!data){
            data = vals.map((v, ix) => (
                {
                    'ix': ix,
                    [k]: v
                }
            ))
        }
        // if list of dictionaries already defined 
        // just add new values to each existing dictionary
        else {
            vals.map((v, ix) => {
                data[ix][k] = v
            })
        }
    })

    this.chart.data = data;

    var yAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
    yAxis.renderer.inversed = true;
    yAxis.visible = false
    yAxis.renderer.grid.template.disabled = true
    yAxis.width = 0

    // Create value axis
    var xAxis = this.chart.xAxes.push(new am4charts.ValueAxis());
    xAxis.visible = false

    // Create series
    this.seriesNames = []

    Object.keys(this.props.vesselInfo).map((k) => {
        this.addSeries(k)
    })

    // Add chart cursor
    this.chart.cursor = new am4charts.XYCursor();
    //chart.cursor.behavior = "zoomY";

    // Add legend

  }

  removeSeries(k){
    if (this.chart.series.length > 1) {
        let ix = this.seriesNames.indexOf(k)
        this.chart.series.removeIndex(ix).dispose();
        this.seriesNames.splice(ix, 1)
      }
  }

  addSeries(k){
    if (LINES[k]){ // maybe be other data (outerArea) in vesselInfo we don't want to plot
        var series = this.chart.series.push(new am4charts.LineSeries());
        series.dataFields.valueX = k;
        series.dataFields.valueY = "ix";
        series.name = k.charAt(0).toUpperCase() + k.slice(1)
        series.id = k
        series.strokeWidth = 2;
        series.strokeDasharray = 2;
        series.stroke = LINES[k]
        //series1.bullets.push(new am4charts.CircleBullet());
        series.tooltipText = `{valueX.${k}}`;
        series.legendSettings.labelText = "[{color}]{name}[/]"
        this.seriesNames.push(k)
    }
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  shouldComponentUpdate(nextProps, nextState){
      /*
      if (nextProps.vesselID !== this.props.vesselID){
          this.reload()
      }
      */
      return true
  }

  componentDidUpdate(prevProps){
      if (prevProps.chartHeight !== this.props.chartHeight){
          this.reload()
          this.forceUpdate()
      }
  }

  render() {
    if (this.seriesNames){
        Object.entries(this.props.legend).map(([k, v]) => {
            if (this.seriesNames.length){
                if (v && !this.seriesNames.includes(k)){
                    this.addSeries(k)
                }
                if (!v){
                    this.removeSeries(k)
                }
            }
        })
    }
    // add this arbitrary amount to try match the actual height of the chart
    // within this container to the mpr plot
    return <div id="container" style={{height: this.props.chartHeight + 50}}/>
  }
}

export default C;