import React, { Component } from 'react';
import {
	Page, Text, View, Document,
	StyleSheet, PDFViewer, Font,
	Image, PDFDownloadLink
} from '@react-pdf/renderer';
import { getPopup } from './utils.js';
import { Icon } from 'semantic-ui-react'

// TODO fetch from public (not src/public)
const CADRADS = require('../public/cadrads.json')
const logoPath = require('../public/logo.png')

//if adding new fonts to ../fonts, register them here

Font.register({
	family: 'Roboto',
	fonts: [
		{
			src: `../fonts/Roboto-Bold.ttf`,
			fontStyle: "bold",
			fontWeight: 700
		},
		{
			src: `../fonts/Roboto-Regular.ttf`,
			fontStyle: "regular",
			fontWeight: 400
		},
		{
			src: `../fonts/Roboto-Light.ttf`,
			fontStyle: "light",
			fontWeight: 300
		}
	]
});


// Create styles
const styles = StyleSheet.create({
	page: {
		fontSize: 12,
		paddingTop: "10%",
		backgroundColor: '#E4E4E4'
	},
	logo: {
		width: '10%',
		position: "absolute" /* to ignore page padding */
	},
	screenshot: {
		width: '80%',
	},
	section: {
		marginBottom: "5%",
		marginLeft: "8%",
		marginRight: "8%"
	},
	heading: {
		fontSize: 18,
		textAlign: "center",
		marginBottom: "5%"
	},
	subHeadingTable: {
		fontSize: 14,
		fontWeight: 700,
		marginBottom: 45, /* as table underneath has absolute positioning */
		fontFamily: "Roboto",
		fontStyle: "bold"
	},
	subHeading: {
		fontSize: 14,
		fontWeight: 700,
		fontFamily: "Roboto",
		fontStyle: "bold"
	},
	para: {
		marginTop: "1%",
		marginRight: "5%",
		marginLeft: "5%"
	},
	table: {
		marginTop: "3%",
		display: "table",
		borderStyle: "none",
		borderWidth: 0,
		borderRightWidth: 0,
		borderBottomWidth: 0,
		verticalAlign: "bottom"
	},
	tableRow: {
		margin: "auto",
		flexDirection: "row"
	},
	tableColHeader: {
		width: "15%",
		borderStyle: "solid",
		borderColor: '#bfbfbf',
		borderBottomColor: '#000',
		borderWidth: 1,
		borderLeftWidth: 0,
		borderRightWidth: 0,
		borderTopWidth: 0,
		textAlign: "left"
	},
	tableCol: {
		width: "15%",
		borderStyle: "solid",
		borderColor: '#bfbfbf',
		borderWidth: 1,
		borderLeftWidth: 0,
		borderRightWidth: 0,
		borderTopWidth: 0
	},
	tableCellHeader: {
		fontSize: 12,
		fontWeight: 400,
		position: "absolute",
		bottom: 0,
		paddingBottom: 5
	},
	tableCell: {
		margin: 1,
		fontSize: 11,
		textAlign: "left",
		paddingTop: 5,
		paddingBottom: 5
	}
});

const getTable = (vesselData) => {
	let rows = []
	if (vesselData){
		console.log("REPORT MAKING ROWS", vesselData)
		Object.entries(vesselData).map(([vessel, dct]) => {
			let row = Object.entries(dct.stats).map(([k, v]) => (
				<View style={styles.tableCol} key={k}>
					<Text style={styles.tableCell}>
						{v}
					</Text>
				</View>
			))
			rows.push(
				<View style={styles.tableRow} key={vessel}>
					<View style={styles.tableCol}>
						<Text style={styles.tableCell}>
							{vessel}
						</Text>
					</View>
					{row}
				</View>
			)
		})
	}
	return (
		<View style={styles.table}>
			<View style={styles.tableRow}>
				<View style={styles.tableColHeader}>
					<Text style={styles.tableCellHeader}>Vessel</Text>
				</View>
				<View style={styles.tableColHeader}>
					<Text style={styles.tableCellHeader}>Calcium Score</Text>
				</View>
				<View style={styles.tableColHeader}>
					<Text style={styles.tableCellHeader}>Maximum Stenosis</Text>
				</View>
				<View style={styles.tableColHeader}>
					<Text style={styles.tableCellHeader}>CAD RADS</Text>
				</View>
				<View style={styles.tableColHeader}>
					{/* for some inexplicable reason "Volume" wont jump to next line without these spaces... */}
					<Text style={styles.tableCellHeader}>Total Plaque Volume</Text>
				</View>
				<View style={styles.tableColHeader}>
					<Text style={styles.tableCellHeader}>Vulnerable Plaque</Text>
				</View>
				<View style={styles.tableColHeader}>
					<Text style={styles.tableCellHeader}>Leiden Score</Text>
				</View>
			</View>
			{rows}
		</View>
	)

}


// Create Document Component
export const pdfReport = (patientData, imgsrc, vesselData) => {

	let vesselSentences1
	let vesselSentences2
	const report = patientData.report
	const scanQuality = patientData.scan_quality

	if (report.style === 'scct'){
		vesselSentences1 = (
			<View style={styles.section}>
				<Text style={styles.subHeading}>Coronary Angiography</Text>
				<Text style={styles.para}>
					Left Main: The LM is a large calibre vessel with a normal takeoff from
					the left coronary cusp that bifurcates into the LAD and LCX.
				</Text>
				<Text style={styles.para}>
					Left Anterior Descending: The LAD has {report.lad_disease}.
					The LAD gives off {report.lad_branch} vessels.
				</Text>
				<Text style={styles.para}>
				Ramus Intermedius: The RI has {report.ri_disease}.
				</Text>
				<Text style={styles.para}>
				Left Circumflex: The LCX is {report.lcx_dom} and has {report.lcx_disease}.
				The LCX gives of {report.lcx_branch} obtuse marginal branches.
				</Text>
				<Text style={styles.para}>
				Right Coronary Artery: The RCA is {report.rca_dom} and has {report.rca_disease}.
				{report.rca_branch}
				</Text>
				<Text style={styles.para}>
				Aorta: The Aorta is {report.aorta_cal} calibre with {report.aorta_disease}.
				</Text>
			</View>
		)
	}
	else { // abdul style

		const aortaSent = (
			<Text style={styles.para}>
				Aorta: The Aorta is {report.aorta_cal} calibre with {report.aorta_disease}.
			</Text>
		)

		const max_vessel_sents_per_page = 12

		console.log("REPORT", report)
		const keys = Object.keys(report.segment_info)
		if (keys.length > max_vessel_sents_per_page){
			vesselSentences1 = (
				<Page size="A4" style={styles.page}>
					<View style={styles.section}>
						<Text style={styles.subHeading}>Coronary Angiography</Text>
						{keys.map((vessel) => (
							<Text style={styles.para} key={vessel}>
								{vessel}: {getSentence(vessel, report.segment_info[vessel])}.
								{/* side branches and length available */}
								{(report.lengths[vessel] && report.side_branches[vessel]) ?
									` The ${vessel} is ${report.lengths[vessel]} mm long and has ${report.side_branches[vessel]}.
									`: null
								}
								{/* only length available */}
								{(report.lengths[vessel] && !report.side_branches[vessel]) ?
									` The ${vessel} is ${report.lengths[vessel]} mm long.`: null
								}
								{/* only side branches available */}
								{(!report.lengths[vessel] && report.side_branches[vessel]) ?
									` The ${vessel} has ${report.side_branches[vessel]}`: null
								}
							</Text>
						))}
						{aortaSent}
					</View>
				</Page>
			)
		}
		else {
			vesselSentences1 = (
				<Page size="A4" style={styles.page}>
					<View style={styles.section}>
						<Text style={styles.subHeading}>Coronary Angiography</Text>
						{keys.slice(0, max_vessel_sents_per_page).map((vessel) => (
							<Text style={styles.para}>
								{vessel}: {getSentence(vessel, report.segment_info[vessel])}.
								{/* side branches and length available */}
								{(report.lengths[vessel] && report.side_branches[vessel]) ?
									` The ${vessel} is ${report.lengths[vessel]} mm long and has ${report.side_branches[vessel]}.
									`: null
								}
								{/* only length available */}
								{(report.lengths[vessel] && !report.side_branches[vessel]) ?
									` The ${vessel} is ${report.lengths[vessel]} mm long.`: null
								}
								{/* only side branches available */}
								{(!report.lengths[vessel] && report.side_branches[vessel]) ?
									` The ${vessel} has ${report.side_branches[vessel]}`: null
								}
							</Text>
						))}
					</View>
				</Page>
			)
			vesselSentences2 = (
				<Page size="A4" style={styles.page}>
					<View style={styles.section}>
						<Text style={styles.subHeading}>Coronary Angiography (continued)</Text>
						{keys.slice(max_vessel_sents_per_page, keys.length - 1).map((vessel) => (
							<Text style={styles.para}>
								{vessel}: {getSentence(vessel, report.segment_info[vessel])}.
								{/* side branches and length available */}
								{(report.lengths[vessel] && report.side_branches[vessel]) ?
									` The ${vessel} is ${report.lengths[vessel]} mm long and has ${report.side_branches[vessel]}.
									`: null
								}
								{/* only length available */}
								{(report.lengths[vessel] && !report.side_branches[vessel]) ?
									` The ${vessel} is ${report.lengths[vessel]} mm long.`: null
								}
								{/* only side branches available */}
								{(!report.lengths[vessel] && report.side_branches[vessel]) ?
									` The ${vessel} has ${report.side_branches[vessel]}`: null
								}
							</Text>
						))}
						{aortaSent}
					</View>
				</Page>
			)
		}
	}
	return (
		<Document>

			{/* PAGE 1 */}
			<Page size="A4" style={styles.page}>
				<Image style={styles.logo} src={logoPath}/>
				<View style={styles.section}>
					<Text style={styles.heading}>Report: {report.title}</Text>
				</View>
				<View style={styles.section}>
					<Text style={styles.subHeading}>
						Details
					</Text>
					<Text style={styles.para}>
						Age: {report.age}
					</Text>
					<Text style={styles.para}>
						Sex: {report.sex}
					</Text>
					<Text style={styles.para}>
						Heart Rate: {report.bpm}bpm
					</Text>
					<Text style={styles.para}>
						Contrast Volume: {report.ctdivol.toFixed(2)}mL
					</Text>
					<Text style={styles.para}>
						Kilovoltage Peak (kVp): {report.kvp.toFixed(2)}
					</Text>

					<Text style={styles.para}>
						Scanner: {report.scanner}
					</Text>

					<Text style={styles.para}>
						Scan Quality - Signal Intensity: {scanQuality['Mean Signal Intensity']}
					</Text>
					<Text style={styles.para}>
						Scan Quality - Image Noise: {scanQuality['Mean Image Noise']}
					</Text>
					<Text style={styles.para}>
						Scan Quality - Signal to Noise: {scanQuality['Mean Signal to Noise']}
					</Text>
				</View>
				{/*show image if one sent */}
				{(imgsrc) ? <Image style={styles.screenshot} src={imgsrc}/> : <View/>}

				<View style={styles.section}>
					<Text style={styles.subHeading}>Impression</Text>
					<Text style={styles.para}>
					{report.disease}
					</Text>
				</View>

				<View style={styles.section}>
					<Text style={styles.subHeading}>CAD RADS</Text>
					<Text style={styles.para}>
					{report.cadrads}
					</Text>
				</View>

				<View style={styles.section}>
					<Text style={styles.subHeading}>Recommendation for CAD RADS</Text>
					<Text style={styles.para}>
					{CADRADS[report.cadrads]}
					</Text>
				</View>

				<View style={styles.section}>
					<Text style={styles.subHeading}>Notes</Text>
					<Text style={styles.para}>
					{report.notes}
					</Text>
				</View>

			</Page>

			{/* NEW PAGE */}
			{vesselSentences1}
			{vesselSentences2}

			{/* NEW PAGE */}
			<Page size="A4" style={styles.page}>
				<View style={styles.section}>
					<Text style={styles.subHeadingTable}>Vessel Overview</Text>
					{getTable(vesselData)}
				</View>
			</Page>

		</Document>
	)
}

function getSentence(v, s){
	const keys = Object.keys(s)
	console.log(v, s)
	if (keys.includes('all')){ // no segments - all in one
		return `The ${v} ${getPhrase(s.all)}`
	}
	else if (v === 'LCX'){ // lcx -- only has prox and dist
		return `The proximal ${v} ${getPhrase(s.prox)} and the distal ${v} ${getPhrase(s.dist)}.`
	}
	else if (keys.length === 3){// not lcx, will have prox, mid and dist
		return `The proximal ${v} ${getPhrase(s.prox)}, the mid ${v} ${getPhrase(s.prox)} and the distal ${v} ${getPhrase(s.prox)}`
	}
}


function getPhrase(a){
	if (typeof a === 'string'){
		return a
	}
	else{
		const {stenosis, plaque} = a
		return `has ${stenosis} stenosis and ${plaque} plaque`
	}
}

function getPhraseDropdown(a, makeDropdown, patientData, v, s){
	// v = vessel
	// s = segement = one of ['all', 'prox', 'mid', 'dist'] = keys of patientData.report.segment_info[vessel]
	if (typeof a === 'string'){
		return <span>{a}</span>
	}
	else {
		const {stenosis, plaque} = a
		console.log(stenosis, plaque)
		return (
			<span>
				has {makeDropdown('stenosis', patientData, 'segment_info', v, s)} stenosis
				 and {makeDropdown('plaque', patientData, 'segment_info', v, s)} plaque
			</span>
		)
	}
}


export async function updateReportPDF(vesselData, patientData, notes, modelScreenshot, trigger){
	//modelScreenshot = URL.createObjectURL(modelScreenshot)
	console.log("REPORT ARGS", vesselData, patientData, notes, modelScreenshot, trigger)
	console.log('REPORT vd', vesselData)
	modelScreenshot = null
	/*
	modelScreenshot = {
		data: modelScreenshot,
		format: 'png'
	}
	*/
	if (patientData.report){
		patientData.report['notes'] = notes
		return (
			<PDFDownloadLink document={pdfReport(patientData, modelScreenshot, vesselData)}>
				{getPopup(
					trigger, "Download PDF",
					{basic: true, position: "left center"}
				)}
			</PDFDownloadLink>
		)
	}
	return trigger
}

function abdulStyleReport(patientData, vesselData, cadrads, userNotesForm, makeDropdown){
	let vesselSentences = Object.entries(patientData.report.segment_info).map(([v, s]) => {
		let sent
		const keys = Object.keys(s)
		if (keys.includes('all')){ // no segments - all in one
			sent = <div>The {v} {getPhraseDropdown(s.all, makeDropdown, patientData, v, 'all')}</div>
		}
		else if (v === 'LCX'){ // lcx -- only has prox and dist
			sent = (
				<div>
					The proximal {v} {getPhraseDropdown(s.prox, makeDropdown, patientData, v, 'prox')}
					{" "}and the distal {v} {getPhraseDropdown(s.dist, makeDropdown, patientData, v, 'dist')}.
				</div>
			)
		}
		else if (keys.length === 3){// not lcsx, will have prox, mid and dist
			sent = (
			<div>
				The proximal {v} {getPhraseDropdown(s.prox, makeDropdown, patientData, v, 'prox')},
				{" "}the mid {v} {getPhraseDropdown(s.mid, makeDropdown, patientData, v, 'mid')}
				{" "}and the distal {v} {getPhraseDropdown(s.dist, makeDropdown, patientData, v, 'dist')}
			</div>
			)
		}
		return (
			<div key={v}>
				<h4>{v}</h4>
				<div>{sent}</div>
				<br/>
			</div>
		)
	})

	return (
		<div className="patientReport">
			<h3>Impression</h3>
			<div>{makeDropdown('disease', patientData)}</div>

			<h3>CAD RADS</h3>
			Category: {makeDropdown('cadrads', patientData)}

			<h3>Recommendation for CAD RADS {patientData.report.cadrads}</h3>
			<div>
				{cadrads[patientData.report.cadrads]}
			</div>

			<h3 className='inlineHeader'>Coronary Calcium (Agatston)</h3>
				{getPopup(
					<Icon
					name="info" size="small"
					/>,
					<div className="content">Derived from the non-contrast scan</div>,
					{basic: true, position: "right center"}
				)}
			<br/>  {/* need break due to inline header */}
			LM: {vesselData.LM.stats['Calcium Score']}<br/>
			RCA: {vesselData.RCA.stats['Calcium Score']}<br/>
			LAD: {vesselData.LAD.stats['Calcium Score']}<br/>
			LCX: {vesselData.LCX.stats['Calcium Score']}<br/>
			Total: {patientData.stats['Calcium Score']}
			<br/>

			<h3 className='inlineHeader'>Coronary Angiography</h3>
			{getPopup(
					<Icon
					name="info" size="small"
					/>,
					<div className="content">Derived from the contrast scan</div>,
					{basic: true, position: "right center"}
				)}
			<br/>  {/* need break due to inline header */}
			{vesselSentences}

			<h3>Notes</h3>
			{userNotesForm}
		</div>
	)
}


export function buildEditableReport(patientData, vesselData, cadrads, userNotesForm, makeDropdown){
	console.log('building report', patientData, vesselData, cadrads, userNotesForm, makeDropdown)
	if (patientData.report.style === 'abdul'){
		console.log('abdul style')
		return abdulStyleReport(patientData, vesselData, cadrads, userNotesForm, makeDropdown)
	}
	else {
		return (
			<div className="patientReport">
				<h3>Impression</h3>
				<div>{makeDropdown('disease', patientData)}</div>

				<h3>CAD RADS</h3>
				Category: {makeDropdown('cadrads', patientData)}
				Stent: {makeDropdown('cadrads_stent', patientData)}
				Graft: {makeDropdown('cadrads_graft', patientData)}
				Vulnerability: {makeDropdown('cadrads_vulnerability', patientData)}

				<h3>Recommendation for CAD RADS {patientData.report.cadrads}</h3>
				<div>
					{cadrads[patientData.report.cadrads]}
				</div>

				<h3>Coronary Calcium (Agatston)</h3>
				LM: {vesselData.LM.stats['Calcium Score']}<br/>
				RCA: {vesselData.RCA.stats['Calcium Score']}<br/>
				LAD: {vesselData.LAD.stats['Calcium Score']}<br/>
				LCX: {vesselData.LCX.stats['Calcium Score']}<br/>
				Total: {patientData.stats['Calcium Score']}
				<br/>

				<h3>Coronary Angiography</h3>

					<h4>Left Main</h4>
					<div>
						The LM is a {makeDropdown('lm_cal', patientData)} calibre vessel
						with a {makeDropdown('lm_takeoff', patientData)} take off from the left
						coronary cusp that {makeDropdown('lm_branch', patientData)}.
					</div>

					<h4>Left Anterior Descending</h4>
					<div>
						The LAD has {makeDropdown('lad_disease', patientData)}.
						The LAD gives off {makeDropdown('lad_branch', patientData)} vessels.
					</div>

					<h4>Ramus Intermedius</h4>
					<div>
						The RI has {makeDropdown('ri_disease', patientData)}.
					</div>

					<h4>Left Circumflex</h4>
					<div>
						The LCX is {makeDropdown('lcx_dom', patientData)} and has {makeDropdown('lcx_disease', patientData)}.
						The LCX gives off {makeDropdown('lcx_branch', patientData)} obtuse marginal branches.
					</div>

					<h4>Right Coronary</h4>
					<div>
						The RCA is {makeDropdown('rca_dom', patientData)} and has {makeDropdown('rca_disease', patientData)}.
						{makeDropdown('rca_branch', patientData)}.
					</div>

					<h4>Aorta</h4>
					<div>
						The Aorta has a {makeDropdown('aorta_cal', patientData)} calibre
						with {makeDropdown('aorta_disease', patientData)}.
					</div>

				<h3>Notes</h3>
				{userNotesForm}
			</div>
		)
	}
}
