export const BufferingData = {
    MOUSE_DETECTION: false,
    SLICES_TO_DISPLAY: 7
}

export const debounce = (fn, time) => {
	let timeout;
  
	return function() {
	  const functionCall = () => fn.apply(this, arguments);
	  
	  clearTimeout(timeout);
	  timeout = setTimeout(functionCall, time);
	}
}