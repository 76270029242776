import { DEVELOPMENT } from './helpers';
export default class Logger {
  static _Color(type, ...args) {
    let background = null;
    switch (type) {
      case 'log':
        background = '#333';
        break;
      case 'warn':
        background = '#FC8622';
        break;
      case 'error':
        background = '#FF383D';
        break;
      case 'info':
      default:
        background = '#45A5F9';
        break;
    }

    return [
      '%c %s %c',
      `color: #fff; background-color: ${background};`,
      type.toUpperCase(),
      '',
      ...args,
    ];
  }

  static Info(...args) {
    if (DEVELOPMENT) {
      if (console && console.info) {
        console.info(...this._Color('info', ...args));
      }
    }
  }

  static Warn(...args) {
    if (DEVELOPMENT) {
      if (console && console.warn) {
        console.warn(...this._Color('warn', ...args));
      }
    }
  }
  static Error(...args) {
    if (DEVELOPMENT) {
      if (console && console.error) {
        console.error(...this._Color('error', ...args));
      }
    }
  }
  static Debug(...args) {
    if (DEVELOPMENT) {
      if (console && console.log) {
        console.log(...this._Color('log', ...args));
      }
    }
  }
}
