import React, { Component } from 'react';
import * as math from 'mathjs';
import Amplify from 'aws-amplify';
import { Icon, Button, Loader } from 'semantic-ui-react';
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import * as utils from './utils';
import * as h5 from './h5';
var THREE = window.THREE = require('three');	// For nice colours

// All slicers have some things in common, they load a giant 1D array from an
// h5, reshape it, and display slices of the reshaped 3D volume wrt to a given
// slicing axis. These utilities (and other basic canvas display functions
// are defined in here)
class BaseSlicer extends Component {

	constructor(props){
		// Allows use of this after parent constructor (component) call
		super(props);
		//console.log(props);

		// Remember the difference:
		// props are properties, they are options that a component rec'vs
		// but can't change
		// states are mutable within the component - it is private to the
		// component, and is optional

		// Initialise state here
		this.state = {
			panX: 0,
			panY: 0,
			zoom: 1,
			loading: true,
			showAnnos: true
		};

		//below used for backwards compatibility
		this._usingBackup = false;
		this._realTimeSlicing = false;
		this._defaultSliceCount = 32;
		this.viewAxis = 0;

		// slice number
		this.sliceidx = 0

		// slider number (will = slice number for cross sections and be diff for cpr/smpr)
		this.slideridx = 0

		// Bind functions which will use this
		this.updateCanvas = this.updateCanvas.bind(this);
		this._onSliceSliderChange = this._onSliceSliderChange.bind(this);
		this._onMouseMove = this._onMouseMove.bind(this);
		this._onScroll = this._onScroll.bind(this);
		this._onButtonHide = this._onButtonHide.bind(this);
		this._onButtonRedo = this._onButtonRedo.bind(this);

		//Check combinations of props and warn about redundancy
		if (this.props.loadType === "SLICEKEYS" && this.props.viewAxis) {
			console.log("Warning: viewAxis " + this.props.viewAxis + " will be"
				+ " ignored as slices have been requested to be loaded in chunks")
		}
		/*
		if (this.props.sliceangles && this.props.viewAxis != 0) {
			console.log("Will slice at angles to zeroth axis regardless of "
				+ "requested viewAxis")
		}
		*/

		// For console testing
		window.test_base_slicer = this;
	}

	// Helper to load JSONs regardless of locality
	async loadJSON (url) {
		let resp;
		if (this.props.local) {
			resp = await fetch(`http://localhost:3000/s3_mirror/` + url);
		} else {
			const awsurl = await Amplify.Storage.get(url)
			resp = await fetch(awsurl);
		}
		return await resp.json();
	}

	// Load up the annotations regardless of requested type
	async loadAnnotations () {
		// TODO Probably specific enough that this can be semi hardcoded as is

		if (this.props.annoType === "SMPR") {
			// In response to slice index, nothing to load

		}

		else if (this.props.annoType === "CPR") {
			// List of 2D coordinates for different vessels, lives in the volume file
			let raw = await h5.getH5Data(
				`${this.props.patientID}/${this.props.runID}/vessels/${this.props.vesselID}/cpr.h5`,
				null, this.props.local);

			this.annoCenterlines2d = utils.reshapeArray1d(
				raw.get("centerlines_2d").value, raw.get("centerlines_2d").shape);

			// TODO Why is this offset required on the x position?
			for (var i = 0; i < this.annoCenterlines2d.length; i++) {
				for (var j = 0; j < this.annoCenterlines2d[0].length; j++) {
					this.annoCenterlines2d[i][j][1] += 1;
				}
			}

			//console.log(this.annoCenterlines2d);

		}

		else if (this.props.annoType === "MPR") {
			// List of ordered points on a polygon per slice, lives in the special
			// polygons file
			let polygons = await this.loadJSON(`${this.props.patientID}/${this.props.runID}/vessels/${this.props.vesselID}/mpr_polygons.json`);

			// Extract inner and outer walls
			this.annoInnerWalls = polygons["lumen"];
			this.annoOuterWalls = polygons["outer"];

			// TODO: sub sample for now but really should not use these interpolated
			// positions, and just use the pixels on the outline which we can fit
			// a spline to
			// TODO can this be a list not a dict? Same for leiden
			function everyN(arr, nth) {
				let newarr = [];
				for (var i = 0; i < Object.keys(arr).length; i++) {
					let subsampled = [];
					for (var j = 0; j < arr[i].length; j+=nth) {
						subsampled.push(arr[i][j]);
					}
					newarr.push(subsampled);
				}
				return newarr;
			}

			this.annoInnerWalls = everyN(this.annoInnerWalls, 4);
			this.annoOuterWalls = everyN(this.annoOuterWalls, 4);

		}

		else if (this.props.annoType === "CONTRASTSCAN") {
			// List of all centerlines in R^3
			this.annoCenterlines3d = {};
			for (var i = 0; i < this.props.availableVessels.length; i++) {
				let v = this.props.availableVessels[i];
				let raw = await h5.getH5Data(
					`${this.props.patientID}/${this.props.runID}/vessels/${v}/mpr.h5`,
					null, this.props.local);
				let clraw = utils.reshapeArray1d(
					raw.get("cl_mm").value, raw.get("cl_mm").shape);

				// Scale into voxel coordinates, and save these coordinates for
				// later rendering
				let cl = [];
				for (var j = 0; j < clraw.length; j++) {
					cl.push([
						Math.floor(clraw[j][0] / this.scaling[0]),
						Math.floor(clraw[j][1] / this.scaling[1]),
						Math.floor(clraw[j][2] / this.scaling[2])
					]);
				}

				this.annoCenterlines3d[String(v)] = cl;
			}

		}

		else if (this.props.annoType === "NONCONTRASTSCAN") {
			// Set of coordinates into the volume where we can find calcium
			let raw
			try {
				raw = await this.loadJSON(`${this.props.patientID}/${this.props.runID}/ui_calcium.json`);
			}
			catch {
				console.log('using deprecated calcium.json')
				raw = await this.loadJSON(`${this.props.patientID}/${this.props.runID}/calcium.json`);
			}

			// Rather than a dict of vessels to XYZ, we'll make this into a list
			// of vessels to 3xN coordaintes
			// TODO: this can just be list? Not a dict? Ask Casey. Same for Leiden
			// and MPR polygons
			this.annoCalciums = [];
			for (var i = 0; i < Object.keys(raw).length; i++) {
				let v = Object.keys(raw)[i];
				let processedCoordinates = [];
				for (var j = 0; j < raw[v][0].length; j++) {
					// TODO: need coordaintes in mm in order for scaling to work, otherwise
					// we also need the downscale factor - in this case hardcoded as 2
					processedCoordinates.push([
						Math.floor(raw[v][0][j] / 2),
						Math.floor(raw[v][1][j] / 2),
						Math.floor(raw[v][2][j] / 2)
					])
				}
				this.annoCalciums.push({ key: v, value: processedCoordinates});
			}

		}

		else {
			// No annotations requested or wrong code
			if (this.props.annoType) {
				console.log("Error: annotation type '" + this.props.annoType
					+ "' not recognised");
			}
		}
	}

	async componentDidMount() {
		// API calls & data loads
		// Get the volume file reference first
		this.annoColors = await require("../legends/annos.json");
		await this.reloadData(this.props)

		// Add scroll listeners this way, rather than through react events
		// Check canvas is defined - may unmount before data loaded and canvas rendered
		if (this.canvas){
			this.canvas.addEventListener('DOMMouseScroll', this._onScroll)
			this.canvas.addEventListener('mousewheel', this._onScroll)
			this.canvas.addEventListener('scroll', this._onScroll)
			// rerender after mounting
			this.forceUpdate()
		}

	}

	// accept props as argument as may be loading for this.props or nextProps
	// (if called from shouldComponentUpdate)
	async reloadData(props){

		// check canvas is defined
		// may be undefined if the component was unmounted before the canvas was created
		if (this.canvas !== null){
			const { runID, volumeFile, patientID, local, vesselID, viewAxis, viewAxisOld,
			loadType, volumeKey, volumeKeyOld, requiresScaling, annoType } = props
			this.viewAxis =  viewAxis;
			if (vesselID) {
				// If a vessel ID is supplied look in the vessel directory
				console.log("Looking for " + volumeFile
					+ " in vessel " + vesselID)

				this.file = await h5.getH5Data(
					`${patientID}/${runID}/vessels/${vesselID}/${volumeFile}`,
					null, local);
			}
			else {
				// Otherwise look in the upper directory
				console.log("Looking for " + volumeFile + " in top directory")

				this.file = await h5.getH5Data(
					`${patientID}/${runID}/${volumeFile}`,
					null, local);
			}
			//below is to ensure backwards compatibility
			//currently only setup for smpr but could be used for

			//TODO better error handling if file or key does not exist
			if(!this.file){
				console.error("Couldn't find " + volumeFile);
			}
			else{
				try{
					this.file.get(volumeKey);
				}
				catch(err){
					//if key not found use backup key
					console.error(volumeKey + " not found in " + volumeFile + ". Using Backup");
					this._usingBackup = true;
					this.viewAxis = viewAxisOld
					if(annoType === "SMPR"){
						this._realTimeSlicing = true;
					}
				}
			}

			// If loading in chunks get required info from keys, otherwise directly
			// from data. Basically this supports legacy h5s with slices as their own
			// h5 datasets, rather than one big 3d volume
			if (loadType === "SLICEKEYS") {
				this.shape = this.file.get("dimensions").value;
				this.volume = null; 	// Get on the fly
			}
			else {
				let keyref = this.file.get(`${(this._usingBackup)?volumeKeyOld:volumeKey}`);
				this.shape = keyref.shape;
				this.volume = utils.reshapeArray1d(keyref.value, this.shape);
			}

			// The scan volumes require scaling information
			if (requiresScaling) {
				this.scaling = this.file.get("scaling").value;
			}

			// update numSlices and maxSliceIdx - needed below
			this.getNumSlices();

			// Load the requested anno's babe!
			await this.loadAnnotations();
			// And the color mapping

			// Every slicer has a target size for the element. The canvas lives in this
			// scrollable target sized div
			this.canvas = this.refs.canvas;

			// increase canvas size beyond size of the div so upsampling done with js
			// rather than css doing upsampling by forcing canvas size to the div size
			// as that will blur anno lines
			// 3 is somewhat arbitrary scaling factor that ensures data size > div size
			if (annoType === 'SMPR'){
				this.canvas.height = this.shape[0] * 3
			}
			else {
				this.canvas.height = this.shape[2] * 3
			}
			this.canvas.width = this.shape[1] * 3

			this.ctx = this.canvas.getContext("2d");

			this.setState({loading: false})

			// Start me up
			this.updateCanvas();

			// if new vessel clicked on the slider idx will have changed
			// must rerender component to use the new slider
			await this.forceUpdate()

			// get height and send to vertical plot
			console.log("CHECKING", this.props.annoType, this.refs.container.clientHeight)
			if (['SMPR', 'CPR'].includes(this.props.annoType)){
				const height = this.refs.container.clientHeight
				this.props.changeState('chartHeight', height)
			}
		}
	}

	// Helper to get the slice matrix regardless of the data loading type, should
	// always be used within the slicer component
	getSliceData (idx) {
		if (this._realTimeSlicing) {
			// In angular slicing we use a different utils function
			// Calculate the angle just around half the circle otherwise we'll
			// have repeats, just flipped
			let angle = (idx / this._defaultSliceCount) * 1 * Math.PI;
			// TODO: allow custom widths on sampled slices
			return utils.sliceMatrix3dAtAngle(this.volume, angle, this.volume[0].length);
		}

		if (this.props.loadType === "SLICEKEYS") {
			let raw = this.file.get('slice_' + String(idx));
			return utils.reshapeArray1d(raw.value, raw.shape);
		}
		return utils.sliceMatrix3d(this.volume, this.viewAxis, this.slideridx);
	}

	// Helper to get the max number of slices regardless of data loading type,
	// should always be used within the slicer component
	// called by reloadData()
	getNumSlices () {

		// set max slice idx to prevent updating canvas is sliceidx
		// above this is requested
		this.numSlices = this._defaultSliceCount
		if ("CPR" === this.props.annoType){
			this.maxSliceIdx = this.shape[2]
		}
		else { // SMPR, MPR, CONTRAST, NONCONTRAST
			this.maxSliceIdx = this.shape[0]
		}

		if (this._realTimeSlicing) {
			// When slicing at a given angle, return the number of desired slices
		}

		// Shape won't always be available on the first render after constructor
		else if (this.shape) {
			if (this.props.loadType === "SLICEKEYS") {
				this.numSlices = this.shape[0] - 1;
			}
			else {
				this.numSlices = this.shape[this.viewAxis] - 1;
			}
		}
		else {
			console.log("Warning: shape requested but not yet known")
		}
	}

	// An annotation processing helper which finds the coordinates which
	// intersect with a slice in the fist axis
	getCoordinatesOnSliceForVolumeAnnos (coordinates, slice, slicebleed, scalefactor, transpose) {
		let res = [];
		for (var j = 0; j < coordinates.length; j++) {
			let point = coordinates[j];
			// Check if intersects with slice
			if (Math.abs(point[0] - slice) <= slicebleed) {
				// Register point into cluster
				if (transpose) {
					res.push([
						Math.floor( point[2] * scalefactor ),
						Math.floor( point[1] * scalefactor )
					]);
				} else {
					res.push([
						Math.floor( point[1] * scalefactor ),
						Math.floor( point[2] * scalefactor )
					]);
				}
			}
		}
		return res;
	}

	// A helper to convert the vessel label into the color attached to it in the
	// legends/annos.json color configuration file
	getVesselAnnoCol (label) {
		const baseName = utils.vesselBaseName(label)
		let col = this.annoColors.vesselToColorMapping[baseName]
		return (col) ? col : this.annoColors.colorVesselDefault
	}

	// Apply the previously loaded annotations
	addAnnotationsToBuffer (buffer, rows, cols, scalefactor) {
		// How the annotations index is used is different for every type of slicer
		if (this.props.annoType === "SMPR") {
			// A horizontal line typically overlayed on SMPR indicating the slice
			let positions = [];
			// below if block should never be run, however solves
			// issue of calling this fuction before calling getNumSlices
			if (!this.maxSliceIdx){
				this.getNumSlices();
			}
			if (this.sliceidx >= 0 && this.sliceidx < this.maxSliceIdx) {
				let y = Math.floor(this.sliceidx * scalefactor);
				for (var x = 4; x < cols - 4; x++) {
					positions.push([x, y]);
				}
				// TODO dependant on the scale of the SMPR
				let radius = 1;
				buffer = utils.writeToBufferAtPositions(
					buffer, rows, cols, positions,
					this.annoColors.colorSmprSliceIndicator, radius);
			}
		}

		else if (this.props.annoType === "CPR") {
			// Exact centerline coordinates typically overlayed on CPR
			let cl = this.annoCenterlines2d[this.slideridx];

			// Need to round these to integers
			let clint = [];
			for (var i = 0; i < cl.length; i++) {
				// CPRS are transposed, so reflect that here
				clint.push([
					Math.floor( cl[i][1] * scalefactor ),
					Math.floor( cl[i][0] * scalefactor )
				]);
			}

			// Setup plotting sizes
			// TODO dependant on the scale of the CPR
			let radiusCl = 5;
			let radiusNorm = 5;

			// Render the full centerline and a normal indication at the desired
			// annotation index
			buffer = utils.writeToBufferBetweenPositions(
				buffer, rows, cols, clint,
				this.annoColors.colorCprCenterline, radiusCl);

			// Calculate the normal to the current annotation slice
			if (this.sliceidx >= 1 && this.sliceidx < this.shape[2] - 1) {
				// Need before and after of current annotation index to get a direction
				// vector
				let prev = cl[this.sliceidx - 1];
				let curr = cl[this.sliceidx + 0];
				let next = cl[this.sliceidx + 1];

				// In the case of a slicing error, or an incorrect prop, check that
				// these are defined before doing anything
				if (prev != null && curr != null && next != null) {
					// Normalize this direction vector
					let to = [
						next[0] - prev[0],
						next[1] - prev[1]
					];
					let tomag = Math.hypot(to[0], to[1]);
					to = [to[0] / tomag, to[1] / tomag];

					// Use orthogonal vector to the 'to' vector to get left and right of
					// displayed 'handle'
					let mag = 6;
					let orth = [to[1], -to[0]];
					// In place transpose
					let left = [
						Math.floor(( curr[1] + mag * orth[1]) * scalefactor),
						Math.floor(( curr[0] + mag * orth[0]) * scalefactor)
					];
					let right = [
						Math.floor(( curr[1] - mag * orth[1]) * scalefactor),
						Math.floor(( curr[0] - mag * orth[0]) * scalefactor)
					];

					// Can render in the cross sectional/normal. Explictly ask for more
					// resampling between normal
					let numBetween = 32;
					buffer = utils.writeToBufferBetweenPositions(
						buffer, rows, cols, [left, right],
						this.annoColors.colorCprSliceIndicator,
						numBetween, radiusNorm
					);
				}
			}
		}

		else if (this.props.annoType === "MPR") {
			// Inner and outer wall polygons typically overlayed on an MPR
			let inner = this.annoInnerWalls[this.sliceidx];
			let outer = this.annoOuterWalls[this.sliceidx];

			// Apply scaling and other required preprocessing, including a quick
			// transpose
			let innerScaled = [];
			for (var i = 0; i < inner.length; i++) {
				innerScaled.push([
					Math.floor( inner[i][1] * scalefactor),
					Math.floor( inner[i][0] * scalefactor)
				]);
			}
			let outerScaled = [];
			for (var i = 0; i < outer.length; i++) {
				outerScaled.push([
					Math.floor( outer[i][1] * scalefactor),
					Math.floor( outer[i][0] * scalefactor)
				]);
			}

			// Write into the buffer with some interpolation to fill in the gaps and
			// require the spline be closed
			let numBetween = 32;
			let closed = true;
			let radius = scalefactor / 32;
			buffer = utils.writeToBufferBetweenPositions(
				buffer, rows, cols, innerScaled,
				this.annoColors.colorMprInnerWall,
				numBetween, closed, radius);
 			buffer = utils.writeToBufferBetweenPositions(
				buffer, rows, cols, outerScaled,
				this.annoColors.colorMprOuterWall,
				numBetween, closed, radius);
		}

		else if (this.props.annoType === "CONTRASTSCAN") {
			// Pieces of the centerline corresponding to slice typically overlayed on
			// contrast scans
			for (var i = 0; i < this.props.availableVessels.length; i++) {
				let v = this.props.availableVessels[i];
				let cl = this.annoCenterlines3d[String(v)];

				// Get the component of each centerline which is intersecting with
				// the current slice (consistently the first axis) or slightly around
				// it and render it as its won color with a discrete legend of sorts
				let slicebleed = 2;
				let tranpose = true;
				let positions = this.getCoordinatesOnSliceForVolumeAnnos(
						cl, this.slideridx, slicebleed, scalefactor, tranpose);

				// Only bother rendering if there are actually points
				let drawradius = 2;
				let radius = 2;
				if (positions.length > 0) {
					buffer = utils.writeToBufferAtPositions(
						buffer, rows, cols, positions,
						this.getVesselAnnoCol(v),
						drawradius, radius);
			  	}

				// TODO need legend

			}
		}

		else if (this.props.annoType === "NONCONTRASTSCAN") {
			// Similar to above, we have coordinates thorughout the scan
			// per some vessels, and we need to find where they index the slicing
			// plane
			for (var i = 0; i < Object.keys(this.annoCalciums).length; i++) {
				let v = this.annoCalciums[i].key;

				// Have to line up directly with slices to bother here
				let slicebleed = 0;
				let transpose = true;
				let positions = this.getCoordinatesOnSliceForVolumeAnnos(
						this.annoCalciums[i].value, this.sliceidx,
						slicebleed, scalefactor, transpose
					);

				// Draw into buffer
				let drawradius = 0.5;
				if (positions.length > 0) {
					buffer = utils.writeToBufferAtPositions(
						buffer, rows, cols, positions,
						this.getVesselAnnoCol(v),
						drawradius
					);
				}
			}
		}

		else {
			// No annotations requested or wrong code
			if (this.props.annoType) {
				console.log("Error: annotation type '" + this.props.annoType
					+ "' not recognised");
			}
		}

		// Return the edited buffer
		return buffer;
	}

	// Writes new slice data into the canvas with desired options
	async updateCanvas () {

		//console.log("UC", this.props.annoType, this.sliceidx, this.slideridx)

		if (!this.volume && this.props.loadType === "VOLUME") {
			console.log("Error: cannot update canvas given volume is not loaded")
			return;
		}
		if (!this.canvas) {
			console.log("Error: cannot update canvas given canvas is not yet defined")
			return;
		}
		if (!this.file) {
			console.log("Error: cannot update canvas given volume file reference is not yet defined")
			return;
		}

		// Perform slice and give data in raw slice dimensions
		let data = await this.getSliceData(this.slideridx);

		// Transpose matrix if required before dimensions are used

		if (data){  // may be null and cause crash

			// Clear the canvas
			this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

			if (this.props.transpose) {
				data = utils.transposeMatrix2d(data);
			}

			// Scale data to fit to canvas, in all slicing cases:
			// - the data is stretched to fit the canvas at the original aspect ratio
			// - the data needs to be stretched to fit the canvas
			//			(fit to width, then scroll vertically in canvas)
			// Begin by calculating the scale factor required to fit the width
			let sf = this.canvas.width / data[0].length;
			// Separate slcaing is the same as one individual, but removes an O(n^2),
			// so do sf fixup and user requested zoom here together
			data = utils.resizeMatrix(data, sf)
			let origX = data[0].length;
			let origY = data.length;
			data = utils.resizeMatrix(data, this.state.zoom);

			// Create an empty image buffer to put image data and annotations into
			let buffer = utils.getEmptyBuffer(data.length, data[0].length);

			// Write image data from the volume slice into the buffer
			buffer = utils.addWindowedMatrix2dToBuffer(
				buffer, data,
				this.props.windowLevel, this.props.windowWidth
			);

			// Write annotation data if requested into the buffer also
			if (this.state.showAnnos) {
				// for MPR this is controlled by showWalls of vesselviewer
				buffer = this.addAnnotationsToBuffer(
					buffer, data.length, data[0].length, sf * this.state.zoom);
			}

			// Create an image data object, note flipped dimensions for correct
			// writing order of rows and cols
			var idata = this.ctx.createImageData(data[0].length, data.length);
			idata.data.set(buffer);

			// This interpolates as it renders to canvas, can't do stretching using
			// dirty options of 7 param version of this function, hence the scaling
			// code earlier. Putting data in at translation location is faster
			// than matrix shifting, especially for large images, and location
			// is determined to ensure the zoom occurs around the content centre
			this.ctx.putImageData(idata,
				this.state.panX,// - (data[0].length - origX) / 2.0,
				this.state.panY // - (data.length    - origY) / 2.0
			)
		}
	}

	async _onSliceSliderChange(e) {
		// Called whenever slice slider is adjusted, this is a state mutator, so
		// redraw when done

		this.slideridx = e

		// only update sliceidx if mpr xsection
		if (['MPR', 'CONTRAST', 'NONCONTRAST'].includes(this.props.annoType)){
			// will cause this component to rerender and give slider the new slideridx
			this.props.setSliceIdx(e)
			this.sliceidx = e
		}

		else {
			// force render in order to update Slider with new slideridx
			this.forceUpdate()
		}

		// draw new slice on to canvas
		this.updateCanvas()
	}

	async _onMouseMove(e) {
		// TODO currently blocked while functionality incomplete
		return

		// TODO, must have click down also
		// Only pan if clicking in with given mouse button. L==1, M==2, R==3
		if (e.nativeEvent.which === 1) {
			// Left clicking, check if pan requested via move
			let panOffsetX = e.movementX;
			let panOffsetY = e.movementY;

			// Scale mouse movement
			// TODO scale movement parameters as prop
			panOffsetX *= 1.6;
			panOffsetY *= 1.6;

			// Apply scaled movement to panning parameters, which will inform canvas
			// pan is the amount of pixels to shift left and right, which should be
			// limited to prevent moving image off screen TODO
			this.setState({
				panX: this.state.panX + panOffsetX,
				panY: this.state.panY + panOffsetY
			}, this.updateCanvas)
		}
	}

	async _onScroll(e) {
		// TODO currently blocked while functionality incomplete
		return

		// Want to scale from [-inf, +inf] into [0, inf]
		let zoomDelta = e.wheelDelta;
		// TODO scale zoom parameters as prop/based on hardware
		zoomDelta *= 0.001;
		let zoomMin = 0.33;
		let zoomMax = 3;
	  	this.setState({
			// Some min/max zoom out
	    	zoom: Math.min(Math.max(this.state.zoom + zoomDelta, zoomMin), zoomMax)
	  }, this.updateCanvas)
	}

	async _onButtonHide(e) {
		// Hide annotations and rerender
		await this.setState({
			showAnnos: !this.state.showAnnos
		});
		this.updateCanvas();
	}

	async _onButtonRedo(e) {
		// Reset pan and zoom params. Note that setstate is async, and we want to
		// ensure we get the new parameters before we update the canvas
		this.setState({
			//sliceidx: 0, // What slice to look at
			//windowLevel: 740,		// All have windowing utilities
			//windowWidth: 2000,
			panX: 0,
			panY: 0,
			zoom: 1
		}, this.updateCanvas)
	}

	updateSliderIdx(props){
		// called if new vessel set externally
		// or if sliceidx being set externally (by vessel info table or 3d model)
		if (['MPR', 'CONTRAST', 'NONCONTRAST'].includes(props.annoType)){
			// for these cross section slicers the slideridx always = sliceidx
			console.log('setting slider index to sliceidx', this.sliceidx)
			this.slideridx = this.sliceidx
		}
		else {
			// for cpr and smpr change slider index to 1
			this.slideridx = 1
		}
	}

	async shouldComponentUpdate(nextProps, nextState){

		let render = false

		if (nextProps.mouseOver !== this.props.mouseOver){
			render = false
		}

		if (nextProps.showAnnos !== this.props.showAnnos){
			this.updateCanvas()
			render = false
		}

		// register new window settings
		if ((nextProps.windowWidth !== this.props.windowWidth)
		|| (nextProps.windowLevel !== this.props.windowLevel) ){
			this.updateCanvas()
		}

		// load data of new vessel
		else if (nextProps.vesselID !== this.props.vesselID){
			if (nextProps.sliceidx !== this.props.sliceidx){
				console.log("3D model click", nextProps, this.props)
				// will trigger in the case of changing a vessel by clicking 3d model
				// that also changes the slice number
				// must update slice number first as previous slice number may not be in range
				// of the newly selected vessel
				this.sliceidx = nextProps.sliceidx
				this.updateSliderIdx(nextProps)
				render = true
			}
			else {
				console.log("NON 3D model click", nextProps, this.props)
				// reset if changing vessel without 3d model click
				this.sliceidx = 0
				this.slideridx = 0
				this.props.setSliceIdx(0) // update table
			}
			this.reloadData(nextProps) // updates canvas as well
		}

		// changed slice idx (from 3d model) without changing vessel id
		else if (nextProps.sliceidx !== this.props.sliceidx){
			if (nextProps.sliceidx < this.maxSliceIdx) {
				this.sliceidx = nextProps.sliceidx
				this.updateSliderIdx(nextProps)
				render = true
				this.updateCanvas()
			}
			else {
				console.log('preventing update', nextProps.sliceidx, this.numSlices)
			}
		}

		return render


	}

	render() {
		//console.log('R', this.props.annoType, this.props.sliceidx, this.slideridx)
		// Main element div with windowing info and scrollable canvas holder

		const loader = (this.state.loading) ?
			<Loader active inline='centered' style={{marginTop: "20%"}}>
				Loading Data
			</Loader>
			: null

		const canvasDisplay = (this.state.loader) ? 'none': 'block'

		return (
			<div className="slicer" ref="container">
				<div className="slicer-content">
					<div className="slicer-slider">
						<Button icon={(this.state.showAnnos) ? 'eye': 'eye slash'} style={{height:"20px", padding:"0px", margin:"0px"}} onClick={this._onButtonHide}/>
						<Button icon="redo" style={{height:"20px", padding:"0px", margin:"0px"}} onClick={this._onButtonRedo}/>
						<div style={{height:"calc(100% - 48px)", padding:"0px", paddingBottom: "8px", paddingTop: "4px", margin:"0px"}}>
							<Slider
								vertical reverse
								min={0}
								max={this.numSlices}
								value={this.slideridx}
								onChange={(e) => this._onSliceSliderChange(e)}
							/>
						</div>
					</div>
					<div className="slicer-canvas-holder"
						onMouseMove={this._onMouseMove}
						onScroll={this._onScroll}>

						<canvas ref="canvas" style={{display: canvasDisplay}}/>
					</div>
					{loader}
				</div>
			</div>
		)
	}
}

// ----------------------------------------------------------------------------
// Define all common high level slicers which are built on the base slicer here
// ----------------------------------------------------------------------------

class ContrastScanSlicer extends React.Component {
  render () {
    return(
			<BaseSlicer className={"slicer-contrast-scan"}
				patientID={this.props.patientID}
				runID={this.props.runID}
				local={this.props.local}
				//vesselID={} // Otherwise will look in the vessel
				volumeFile={"volume_contrast.h5"}
				requiresScaling={true}
				loadType={"SLICEKEYS"}
				annoType={"CONTRASTSCAN"}
				availableVessels={this.props.availableVessels}
				transpose={false}
				scrollY={false}
				windowLevel={this.props.windowLevel}
				windowWidth={this.props.windowWidth}
				changeState={this.props.changeState}
			/>
    )
  }
}

class NonContrastScanSlicer extends React.Component {
  render () {
    return(
			<BaseSlicer
				className={"slicer-non-contrast-scan"}
				patientID={this.props.patientID}
				runID={this.props.runID}
				local={this.props.local}
				//vesselID={} // Otherwise will look in the vessel
				volumeFile={"volume_non_contrast.h5"}
				requiresScaling={true}
				loadType={"SLICEKEYS"}
				annoType={"NONCONTRASTSCAN"}
				transpose={false}
				scrollY={false}
				windowLevel={this.props.windowLevel}
				windowWidth={this.props.windowWidth}
				changeState={this.props.changeState}
			/>
    )
  }
}

class MprSlicer extends React.Component {
  render () {
    return(
			<BaseSlicer
				className={"slicer-mpr"}
				patientID={this.props.patientID}
				runID={this.props.runID}
				local={this.props.local}
				vesselID={this.props.vesselID}
				volumeFile={"mpr.h5"}
				volumeKey={"mpr_image"}
				loadType={"VOLUME"}
				viewAxis={0}
				sliceAxis={0}
				annoType={"MPR"}
				transpose={false}
				scrollY={false}
				sliceidx={this.props.sliceidx}
				windowLevel={this.props.windowLevel}
				windowWidth={this.props.windowWidth}
				changeState={this.props.changeState}
				setSliceIdx={this.props.setSliceIdx}
			/>
    )
  }
}

class CprSlicer extends React.Component {
  render () {
    return(
			<BaseSlicer
				className={"slicer-cpr"}
				patientID={this.props.patientID}
				runID={this.props.runID}
				local={this.props.local}
				vesselID={this.props.vesselID}
				volumeFile={"cpr.h5"}
				volumeKey={"views"}
				loadType={"VOLUME"}
				viewAxis={0}
				sliceAxis={0}
				sliceidx={this.props.sliceidx}
				annoType={"CPR"}
				transpose={true}
				scrollY={false}
				windowLevel={this.props.windowLevel}
				windowWidth={this.props.windowWidth}
				changeState={this.props.changeState}
				setSliceIdx={this.props.setSliceIdx}
			/>
    )
  }
}

class SmprSlicer extends React.Component {
  render () {
    return(
			<BaseSlicer
				className={"slicer-smpr"}
				patientID={this.props.patientID}
				runID={this.props.runID}
				local={this.props.local}
				vesselID={this.props.vesselID}
				volumeFile={"mpr.h5"}
				volumeKey={"mpr_angles"}
				volumeKeyOld={"mpr_image"}
				loadType={"VOLUME"}
				viewAxis={2}
				viewAxisOld={0}
				sliceAxis={0}
				sliceidx={this.props.sliceidx}
				annoType={"SMPR"}
				transpose={false}
				scrollY={false}
				windowLevel={this.props.windowLevel}
				windowWidth={this.props.windowWidth}
				changeState={this.props.changeState}
				setSliceIdx={this.props.setSliceIdx}
			/>
    )
  }
}

// You should never need the base slicer but if you want it here it is!
export {
	BaseSlicer,
	ContrastScanSlicer,
	NonContrastScanSlicer,
	MprSlicer,
	CprSlicer,
	SmprSlicer
}
