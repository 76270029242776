
import React from 'react';
import { Form, Button, Modal, Loader } from 'semantic-ui-react'
import Amplify from 'aws-amplify';
import { getPopup } from './utils';

Amplify.configure({
    Auth: {
      mandatorySignIn: true,
      region: process.env.REACT_APP_REGION,
      userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
      identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
    },
    Storage: {
      AWSS3: {
        bucket: process.env.REACT_APP_S3_BUCKET,
        region: process.env.REACT_APP_REGION,
      },
    },
  });

  
const MAX_DATE = "2020-03-01"
  

class RequestForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = { open: false, patientID: "", date: "", series: "", showMaxDateWarning: "hide" }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.reset = this.reset.bind(this)
    }
    handleChange = (e, { name, value }) => {
        console.log("change", name, value)
        this.setState({ [name]: value })
    }
    
    show = () => this.setState({open: true})
    close = () => this.setState({ 
        open: false, patientID: "", date: "", series: "", submitState: null
    })
    
    async handleSubmit() {

        console.log('submitting form', patientID, date, series)
        
        const { patientID, date, series } = this.state

        // check fields
        if (!patientID){
            alert("Please specifiy the Patient ID")
        }
        else if (!date){
            alert("Please specifiy the Date")
        }
        else if (!series){
            alert("Please specifiy the Series")
        }
        // all fields filled
        else {
            this.setState({submitState: 'submitting'}) // changes to spinning Loader
            const date_requested = new Date().toUTCString()
            const date_filename = new Date().getTime()

            let username, email
            await Amplify.Auth.currentAuthenticatedUser()
                .then(user => {
                    username = user.username
                    email = user.attributes.email
                })
                .catch(err => console.log('error: could not get username', err));

            const data = {
                patientID: patientID, date: date, series: series, 
                user: username, date_requested: date_requested, email: email
            }

            // set filename to user if no email available
            const filename = ''.concat(email ? email : username, '/', date_filename, '.json')
            console.log('putting data', filename, data)
            // must set to private here (not globally)
            Amplify.Storage.configure({ level: 'private' }) // prepends "private" to filename
            Amplify.Storage.put(filename, JSON.stringify(data), {contentType: 'application/json'})
            .then (result => {
                console.log(result) // {key: "test.txt"}
                this.setState({submitState: 'submitted'})
            })
            .catch(err => console.log('error submitting analysis request', err));
        }
    }

    reset(){
        this.setState({
            patientID: "", date: "", series: "", submitState: null
        })
    }

    render() {
        console.log('state', this.state)
        const { open, submitState } = this.state

        let submitButton
        if (submitState === 'submitting'){
            submitButton = <Loader inline size="small"/>
        }
        else if (submitState === 'submitted'){
            submitButton = (
                <div>
                    <Button className='submit' content="Submitted" disabled/>
                    <Button className='new' content="New Request" onClick={this.reset}/>
                </div>
            )
        }
        else {
            submitButton = <Button className="submit" content="Submit" onClick={this.handleSubmit}/>
        }
    
        return (
            <div className="button">  {/* needs this classname to be inline with button group container */}
                <Button onClick={() => this.show()} icon="plus"/>
                <Modal className="requestForm" open={open} onClose={this.close}>
                    <Modal.Header>Request New Analysis</Modal.Header>
                    <Modal.Content>     
                        <Button className='close' icon="window close" onClick={this.close}/>
                        <Form>
                            <Form.Group>
                                <Form.Input
                                label='Patient ID'
                                name='patientID'
                                placeholder='Enter ID...'
                                value={this.state.patientID}
                                onChange={this.handleChange}
                                />
                                <div 
                                onMouseOver={() => this.setState({showMaxDateWarning: "show"})} 
                                onMouseOut={() => this.setState({showMaxDateWarning: "hide"})} 
                                >
                                    <Form.Input
                                    type="date"
                                    label='Date'
                                    name='date'
                                    placeholder='Enter Date...'
                                    value={this.state.date}
                                    onChange={this.handleChange}
                                    max={MAX_DATE}
                                    />
                                    <i className={`maxDateWarning ${this.state.showMaxDateWarning}`}>
                                        *Up to {MAX_DATE}
                                    </i>
                                </div>
                                <Form.Input
                                label='Series'
                                name='series'
                                placeholder='Enter Series Number...'
                                value={this.state.series}
                                onChange={this.handleChange}
                                />
                                <br/>
                            </Form.Group>
                            {submitButton}
                        </Form>
                    </Modal.Content>
                </Modal>
            </div>
        )
    }
}


export { RequestForm }