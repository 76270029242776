import React, { Component } from 'react';
import Slider from 'rc-slider';
import Amplify from 'aws-amplify';
import styled, {css} from 'styled-components';
import { Icon, Button } from 'semantic-ui-react';
import 'rc-slider/assets/index.css';
import * as utils from './utils';
import * as h5 from './h5';
import { BufferingData } from '../utils';
// var THREE = window.THREE = require('three');	// For nice colours


const StyledViewer = styled.div`
	background: black;
`

const SliderContainer = styled.div`
	height: 320px;
    width: 30px;
    display: flex;
    position: absolute;
    justify-content: center;
`;

const StyledLoader = styled.div`
	position: absolute;
	z-index: 2;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	color: white;
	background: black;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	i.icon {
		font-size: 2em;
	}
`;

const ProgressBarWrapper = styled.div`
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	display: inline-block;
	width: 260px;
	border: solid white;
	border-width: 0 1px;
	padding: 0 3px;
	height: 11px;
	position: relative;
`;

//need to change this
const BufferBar = styled.div`
	position: absolute;
	top: 0px;
	height: 100%;
	width: ${props => props.width};
	background: rgba(255,255,255,0.2);
	left: ${props => props.startpoint}
`;

let sliderTimout = null;
let mouseTimeOut = null;

// All slicers have some things in common, they load a giant 1D array from an
// h5, reshape it, and display slices of the reshaped 3D volume wrt to a given
// slicing axis. These utilities (and other basic canvas display functions
// are defined in here)
class SmartSlicer extends Component {

	constructor(props){
		// Allows use of this after parent constructor (component) call
		super(props);

		// Initialise state here
		this.state = {
			loading:true,
			bufferCount: 0,
			previousBufferCount: 0,
			progress: 0,
			paused: false,
			showAnnos : true,
		};

		// Bind functions which will use this
		this.pickClick = this.pickClick.bind(this);
		this.updateCanvas 			  = this.updateCanvas.bind(this);
		this._onSliceSliderChange = this._onSliceSliderChange.bind(this);
		this._onButtonHide 				= this._onButtonHide.bind(this);

		// For console testing
		window.test_smart_slicer = this;
	}


	pickClick (event) {
		if (this.props.annoType === "CONTRASTSCAN"){
			// Only accept left clicks
			if (event.which !== 1) return;
			// if data has not begun loading there is no chance to calculate
			// the closest vessel
			if(!this.loadedIdx) return;
			//fix slice to avoid race conditions
			let slice = this.slideridx;
			// slice has not loaded so cannot calculate closest vessel
			if(!this.loadedIdx[slice]) return;
			// check whether polygons and centrelines have loaded
			let offsetRect = this.canvas.getBoundingClientRect();
			//convert to voxels but using floating point to preserve
			//accuracy for conversion to mm
			const x = (event.clientX - offsetRect.left)/offsetRect.width * this.shape[1];
			const y = (event.clientY - offsetRect.top)/offsetRect.height * this.shape[2];
			console.log('clicked at voxel',x,y);
	    let newState = {};
	    const vessel = this.pickVessel(slice, x, y);
	    if(vessel){
	      newState['reportName'] = vessel;
	      newState['slideridx'] = this.pickMprInd(vessel, slice, x, y);
	      this.props.changeStateDct(newState);
	    }
	    console.log('click', vessel);
		}
	}

	pickMprInd (vessel, slice, x, y){
		//expects x, y, slice as voxels
		if(!this.vesselCentrelines || !this.spacing) return;
		const [mmz, mmx, mmy] = utils.voxelsToMm(this.spacing, [slice,x,y]);
		const cls = this.vesselCentrelines[vessel];
		let rv = 0;
		let mn = Math.sqrt((mmz-cls[0][0])**2 + (mmx-cls[0][1])**2 + (mmy-cls[0][2])**2);
		for(let i = 1; i < cls.length; i++){
			let tp = Math.sqrt((mmz-cls[i][0])**2 + (mmx-cls[i][1])**2 + (mmy-cls[i][2])**2);
			if(tp < mn){
				mn = tp;
				rv = i;
			}
		}
		return rv;
	}

	pickVessel(slice,x,y){
		//expects slice, x, y as voxels (slice must be integral)
		if(!this.vesselPolygons || !this.spacing) return;
		const [mmz, mmx, mmy] = utils.voxelsToMm(this.spacing, [slice,x,y]);
		for(let i = 0; i < this.vesselPolygons[slice].length; i++){
			const polygon = this.vesselPolygons[slice][i].polygon;
			const vessel = this.vesselPolygons[slice][i].vessel;
			if(polygon.length === 1){
				if(polygon[0][0] === mmx && polygon[0][1] === mmy) return vessel;
			}
			let segments = [];
			for(let j = 1; j < polygon.length; j++) segments.push([j-1,j]);
			segments.push([polygon.length-1,0]);
			let  inter = [];
			for(let j = 0; j < segments.length; j++){
				let [a,b] = segments[j];
				if(polygon[a][0] > polygon[b][0]) [a,b] = [b,a];
				inter.push([polygon[a][0],true,j]);
				inter.push([polygon[b][0],false,j]);
			}
			inter.sort((a,b)=>{
				if(a[0]<b[0]) return -1;
				if(a[0]>b[0]) return 1;
				if(a[1]) return -1;
				if(b[1]) return 1;
				return 0;
			});
			let j = 0;
			let segs = new Set();
			while(j < inter.length){
				if(inter[j][0] >= mmx) break;
				const [xVal, add, segInd] = inter[j];
				if(add) segs.add(segInd);
				else segs.delete(segInd);
				j++;
			}
			let u = 0;
			for(const key of segs){
				const [a,b] = segments[key];
				const [x0,y0] = polygon[a];
				const [x1,y1] = polygon[b];
				const dy = y1-y0;
				const dx = x1-x0;
				if(dx === 0){
					const [a_high, b_high] = [y0 < mmy, y1 < mmy];
					if (a_high === b_high){
						if(a_high) u++;
					}
					else return vessel;
					continue;
				}
				const intersect = (mmx - x0) + dy/dx + y0;
				if(intersect > mmy) u++;
				else if(intersect == mmy) return vessel;
			}
			if(u&1) return vessel;
		}
		//returns null when not in polygon
	}

	async componentDidMount() {
		// bind listeners for vessel in report
		if(!this.props.changeStateDct) console.log('changeStateDct does not exist');
		window.addEventListener('mousedown', this.pickClick);
		// API calls & data loads
		// Get the volume file reference first
		await this.loadData(this.props);
	}

	async componentWillUnmount(){
		window.removeEventListener('mousedown', this.pickClick);
	}

	async _onButtonHide(e) {
		// Hide annotations and rerender
		await this.setState({
			showAnnos: !this.state.showAnnos
		});
		this.updateCanvas();
	}

	// Helper to load JSONs regardless of locality
	async loadJSON (url) {
		let resp;
		if (this.props.local) {
			resp = await fetch(`http://localhost:3000/s3_mirror/` + url);
		} else {
			const awsurl = await Amplify.Storage.get(url)
			resp = await fetch(awsurl);
		}
		return await resp.json();
	}

	// accept props as argument as may be loading for this.props or nextProps
	// (if called from shouldComponentUpdate)
	async loadData(props){
		// check canvas is defined
		// may be undefined if the component was unmounted before the canvas was created
		if (this.canvas !== null){

			// Get the shape
			let fp_info = `${this.props.patientID}/${this.props.runID}/${this.props.relativepathtofiles}/info.h5`;
			let file = await h5.getH5Data(fp_info,	null, this.props.local);
			this.shape   = file.get('shape').value
			this.spacing = file.get('spacing').value

			// Load the annotations for rendering on 2D slices
			this.loadAnnotations()
			this.annoColors = await require("../legends/annos.json");

			// load vessel info used for mapping clicks to vessels
			this.loadVesselInfo();

			// Set loading params
			this.slideridx = Math.round(this.shape[0] / 2);
			this.bufferInterval = null;
			this.startPoint = this.slideridx;

			/** Buffer to load data into */
			this.bufferedData = new Array(this.shape[0]);

			// Every slicer has a target size for the element. The canvas lives in this
			// scrollable target sized div
			this.canvas = this.refs.canvas;

			// increase canvas size beyond size of the div so upsampling done with js
			// rather than css doing upsampling by forcing canvas size to the div size
			// as that will blur anno lines
			// 3 is somewhat arbitrary scaling factor that ensures data size > div size
			this.canvas.height = this.shape[2]; // * 2
			this.canvas.width  = this.shape[1]; // * 2
			this.ctx = this.canvas.getContext("2d");


			// Start me up
			let buffCounter = 1;
      this.updateBuffer(this.startPoint); // add the first slice


			// stores the left and right loaded extremeties from an idx
			let leftParents = new Array(this.shape[0]).fill(-1);
			let rightParents = new Array(this.shape[0]).fill(-1);
			// array to tell what has loaded
			this.loadedIdx = new Array(this.shape[0]).fill(false);
			let sliceCounter = 0;


			this.bufferInterval = setInterval(() => {
				//dsu might be a little overkill considering other inefficiencies
				//but it's guaranteed at worst O(3n) avg theta(n)
				if (!this.paused){
					let l = this.slideridx
					while(this.loadedIdx[l]){
						let stack = [];
						while(leftParents[l] != -1){
							stack.push(l);
							l = leftParents[l];
						}
						while(stack.length){
							leftParents[stack.pop()] = l;
						}
						if(this.loadedIdx[l]){
							l--;
							if(l < 0) break;
							leftParents[l+1] = l;
						}
					}
					if(l >= 0){
						this.updateBuffer(l);
						this.loadedIdx[l] = true;
						sliceCounter++;
					}
					let r = this.slideridx;
					while(this.loadedIdx[r]){
						let stack = [];
						while(rightParents[r] != -1){
							stack.push(r);
							r = rightParents[r];
						}
						while(stack.length){
							rightParents[stack.pop()] = r;
						}
						if(this.loadedIdx[r]){
							r++;
							if(r >= this.shape[0]) break;
							rightParents[r-1] = r;
						}
					}
					if(r < this.shape[0]){
						this.updateBuffer(r);
						this.loadedIdx[r] = true;
						sliceCounter++;
					}
					buffCounter++;
					this.setState({bufferCount: sliceCounter});
					this.setState({progress: this.getBufferProgress()});

					if(buffCounter === BufferingData.SLICES_TO_DISPLAY) {
						// No longer loading
						this.setState({loading:false})
						// Show what we have
						this.updateCanvas();
						// Start mouse detection
						if (BufferingData.MOUSE_DETECTION && this.state.progress < 100) {
							this.InitiateMouseMovement();
						}
					}

					if(sliceCounter === this.shape[0]) {
						console.log('clearing interval', this.bufferedData);
						clearInterval(this.bufferInterval);
					}
				}
			}, 500);
		}
	}

	async loadAnnotations () {
		if (this.props.annoType === "CONTRASTSCAN") {
			// List of all centerlines in R^3
			this.annoCenterlines3d = {};
			for (var i = 0; i < this.props.availableVessels.length; i++) {
				let v = this.props.availableVessels[i];
				let raw = await h5.getH5Data(
					`${this.props.patientID}/${this.props.runID}/vessels/${v}/mpr.h5`,
					null, this.props.local);
				let clraw = utils.reshapeArray1d(
					raw.get("cl_mm").value, raw.get("cl_mm").shape);

				// Scale into voxel coordinates, and save these coordinates for
				// later rendering
				let cl = [];
				for (var j = 0; j < clraw.length; j++) {
					cl.push([
						Math.floor(clraw[j][2] / this.spacing[0]),
						Math.floor(clraw[j][1] / this.spacing[1]),
						Math.floor(clraw[j][0] / this.spacing[2])
					]);
				}

				this.annoCenterlines3d[String(v)] = cl;
			}

		}

		else if (this.props.annoType === "NONCONTRASTSCAN") {
			// Set of coordinates into the volume where we can find calcium
			let raw
			try {
				raw = await this.loadJSON(`${this.props.patientID}/${this.props.runID}/ui_calcium.json`);
			}
			catch {
				console.log('using deprecated calcium.json')
				raw = await this.loadJSON(`${this.props.patientID}/${this.props.runID}/calcium.json`);
			}
			console.log(raw)

			// Rather than a dict of vessels to XYZ, we'll make this into a list
			// of vessels to 3xN coordaintes
			// TODO: this can just be list? Not a dict? Ask Casey. Same for Leiden
			// and MPR polygons
			this.annoCalciums = [];
			for (var i = 0; i < Object.keys(raw).length; i++) {
				let v = Object.keys(raw)[i];
				let processedCoordinates = [];
				for (var j = 0; j < raw[v][0].length; j++) {
					// TODO: need coordaintes in mm in order for scaling to work, otherwise
					// we also need the downscale factor - in this case hardcoded as 2
					processedCoordinates.push([
						Math.floor(raw[v][0][j]),
						Math.floor(raw[v][1][j]*(0.5/this.spacing[1])),
						Math.floor(raw[v][2][j]*(0.5/this.spacing[2]))
					])
				}
				this.annoCalciums.push({ key: v, value: processedCoordinates});
			}
		}

		return;
  }

  async loadVesselInfo (){
    if(this.props.annoType === "CONTRASTSCAN"){
    	this.vesselPolygons = await this.loadJSON(`${this.props.patientID}/${this.props.runID}/volume_contrast/vessel_polygons.json`);
    	this.vesselCentrelines = {};
      for(let i = 0; i < this.props.availableVessels.length; i++){
        let v = this.props.availableVessels[i];
        //load centrelines
        this.vesselCentrelines[v] = await h5.getH5Data(
					`${this.props.patientID}/${this.props.runID}/vessels/${v}/mpr.h5`,
					'cl_mm', this.props.local);
      }
    }
  }

	// An annotation processing helper which finds the coordinates which
	// intersect with a slice in the fist axis
	getCoordinatesOnSliceForVolumeAnnos (coordinates, slice, slicebleed, scalefactor, transpose) {
		let res = [];
		for (var j = 0; j < coordinates.length; j++) {
			let point = coordinates[j];
			// Check if intersects with slice
			if (Math.abs(point[0] - slice) <= slicebleed) {
				// Register point into cluster
				if (transpose) {
					res.push([
						Math.floor( point[2] * scalefactor ),
						Math.floor( point[1] * scalefactor )
					]);
				} else {
					res.push([
						Math.floor( point[1] * scalefactor ),
						Math.floor( point[2] * scalefactor )
					]);
				}
			}
		}
		return res;
	}

	// A helper to convert the vessel label into the color attached to it in the
	// legends/annos.json color configuration file
	getVesselAnnoCol (label) {
		const baseName = utils.vesselBaseName(label)
		let col = this.annoColors.vesselToColorMapping[baseName]
		return (col) ? col : this.annoColors.colorVesselDefault
	}

	// Apply the previously loaded annotations
	addAnnotationsToBuffer (buffer, rows, cols, scalefactor) {
		// How the annotations index is used is different for every type of slicer
		if (this.props.annoType === "CONTRASTSCAN") {
			// Pieces of the centerline corresponding to slice typically overlayed on
			// contrast scans
			for (var i = 0; i < this.props.availableVessels.length; i++) {
				let v = this.props.availableVessels[i];
				let cl = this.annoCenterlines3d[String(v)];

				if (cl == null) {
					return buffer
				}

				// Get the component of each centerline which is intersecting with
				// the current slice (consistently the first axis) or slightly around
				// it and render it as its won color with a discrete legend of sorts
				let slicebleed = 2;
				let tranpose = true;
				let positions = this.getCoordinatesOnSliceForVolumeAnnos(
						cl, this.slideridx, slicebleed, scalefactor, tranpose);

				// Only bother rendering if there are actually points
				let drawradius = 0.9;
				if (positions.length > 0) {
					buffer = utils.writeToBufferAtPositions(
						buffer, rows, cols, positions,
						this.getVesselAnnoCol(v),
						drawradius);
			  	}

				// TODO need legend

			}
		}

		else if (this.props.annoType === "NONCONTRASTSCAN") {
			// Similar to above, we have coordinates thorughout the scan
			// per some vessels, and we need to find where they index the slicing
			// plane
			for (var i = 0; i < Object.keys(this.annoCalciums).length; i++) {
				let v = String(this.annoCalciums[i].key);

				// Have to line up directly with slices to bother here
				let slicebleed = 0;
				let transpose = true;
				let positions = this.getCoordinatesOnSliceForVolumeAnnos(
						this.annoCalciums[i].value, Math.floor(this.slideridx),
						slicebleed, scalefactor, transpose
					);
				// Draw into buffer
				let drawradius = 0.4;
				if (positions.length > 0) {
					buffer = utils.writeToBufferAtPositions(
						buffer, rows, cols, positions,
						this.getVesselAnnoCol(v),
						drawradius
					);
				}
			}
		}

		else {
			// No annotations requested or wrong code
			if (this.props.annoType) {
				console.log("Error: annotation type '" + this.props.annoType
					+ "' not recognised");
			}
		}

		// Return the edited buffer
		return buffer;
	}

	addLegendToBuffer(buffer, rows, cols) {
		let av;
		if (this.props.annoType === "CONTRASTSCAN") {
			av = this.props.availableVessels;
		} else if (this.props.annoType === "NONCONTRASTSCAN") {
			av = []
			for (var i = 0; i < Object.keys(this.annoCalciums).length; i++) {
				av.push(this.annoCalciums[i].key)
			}
		}

		// Get longest string in aavailable vessels
		var maxlabellen = 0;
		for (var i = 0; i < av.length; i++) {
			maxlabellen = Math.max(av[i].length, maxlabellen)
		}
		var width = 4 + 16 + 2 + maxlabellen * 9 + 4;
		var height = 16 * av.length + 6
		var topleftx = 4
		var toplefty = 4

		// Blat onto canvas
		// The legend background
		this.ctx.lineWidth = "2";
		this.ctx.strokeStyle = "#FFFFFF";
		this.ctx.beginPath();
		this.ctx.rect(topleftx, toplefty, width, height);
		this.ctx.stroke();

		this.ctx.fillStyle = "#000000";
		this.ctx.fillRect(topleftx, toplefty, width, height);

		// THe names and colours
		for (var i = 0; i < av.length; i++) {
			// Names
			this.ctx.fillStyle = "#FFFFFF";
			this.ctx.font = "14px monospace";
			this.ctx.fillText(av[i], topleftx + 20, toplefty + 16 + 16 * i);

			// Colours
			this.ctx.fillStyle = this.getVesselAnnoCol(av[i]);
			this.ctx.fillRect(4 + topleftx, 4 + toplefty + 16 * i, 14, 14);
		}

	}


	getBufferProgress = () => {
		return ((this.state.bufferCount / this.shape[0]) * 100).toFixed(0);
	}

	mouseStopped = () => {
		this.setState({paused: false});
    }

    InitiateMouseMovement = () => {
		if(this.state.progress < 100) {
			document.onmousemove = () => {
				this.setState({paused: true});
				clearTimeout(mouseTimeOut);
				let _ = this; // To refer in callback
				mouseTimeOut = setTimeout(function(){
					_.mouseStopped();
				}, 2000);
			}
		} else {
			clearTimeout(mouseTimeOut);
		}
  }

	/** Get the requested slice and add it to corresponding position in the buffer data array */
	async updateBuffer(idx) {
		try {
			if (!this.bufferedData[idx]) {
				let fp = `${this.props.patientID}/${this.props.runID}/${this.props.relativepathtofiles}/${idx}.h5`;
				let file = await h5.getH5Data(fp,	null, this.props.local);
				let raw = file.get('data');

				this.bufferedData[idx] = utils.reshapeArray1d(raw.value, raw.shape);
			}
		}
		catch(err) {
		  console.log(`Cannot find ${idx}.h5`)
		}
	}

	// Writes new slice data into the canvas with desired options
	async updateCanvas () {
		if (!this.canvas || !this.ctx) {
			console.log("Error: cannot update canvas given canvas is not yet defined")
			return;
		}

		// Perform slice and give data in raw slice dimensions
		let data = this.bufferedData[this.slideridx];

		if (data){  // may be null and cause crash

			// Clear the canvas
			this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

			// Scale data to fit to canvas, in all slicing cases:
			// - the data is stretched to fit the canvas at the original aspect ratio
			// - the data needs to be stretched to fit the canvas
			//			(fit to width, then scroll vertically in canvas)
			// Begin by calculating the scale factor required to fit the width
			let sf = this.canvas.width / data[0].length;

			// Separate slcaing is the same as one individual, but removes an O(n^2),
			// so do sf fixup and user requested zoom here together
			data = utils.resizeMatrix(data, sf)

			// Create an empty image buffer to put image data and annotations into
			let buffer = utils.getEmptyBuffer(data.length, data[0].length);

			// Write image data from the volume slice into the buffer
			buffer = utils.addWindowedMatrix2dToBuffer(
				buffer, data,
				this.props.windowlevel, this.props.windowwidth
			);

			// Add annotations into the buffer after windowing (so as to not change colors
		  // via windowing controls)
			if (this.state.showAnnos) {
				// Write in the annotations
				buffer = this.addAnnotationsToBuffer(buffer, data.length, data[0].length, sf);
			}

			// Create an image data object, note flipped dimensions for correct
			// writing order of rows and cols
			var idata = this.ctx.createImageData(data[0].length, data.length);
			idata.data.set(buffer);

			// Put buffer into canvas
			this.ctx.putImageData(idata, 0, 0);

			if (this.state.showAnnos) {
				// Write in the annotations legend using ctx operations
				this.addLegendToBuffer()
			}
		}
	}

	_onSliceSliderChange(e) {
		if (sliderTimout) { clearInterval(sliderTimout) }

		// Called whenever slice slider is adjusted, this is a state mutator, so
		// redraw when done
		this.slideridx = typeof(e) == 'number' ? e : e.target.value - 1;

		// force render in order to update Slider with new slideridx
		this.forceUpdate();

		if(this.bufferedData[this.slideridx]) {
			this.setState()
			// draw new slice on to canvas
			this.updateCanvas()
		} else {
			// selected slider index does not exist in buffer yet so add it and check when it is and update canvas
			sliderTimout = setTimeout(() => {
				this.skipTo();
			}, 500);
		}
	}

	async skipTo() {
		await this.updateBuffer(this.slideridx);
		this.updateCanvas();
	}

	render() {

		var bartext = ""
		if (this.state.progress < 99) {
			bartext = `${this.state.progress}%, `
		}
		bartext += `slice #${this.slideridx}`

		var denom = 100
		if (this.shape != null) {
			denom = this.shape[0]
		}

    let bufferBars = [];

    //required to update canvas if window settings change
    const update_prom = new Promise((res,rej) => { return this.updateCanvas(); });

		if (this.loadedIdx){
			//TODO add dsu here
			let found = false;
			let prev = 0;
			for(let i = 0; i < this.loadedIdx.length; i++){
				if(!this.loadedIdx[i]){
					if(found){
						bufferBars.push(<BufferBar
							width={`${((i-prev) / denom * 100).toFixed(0)}%`}
							startpoint={`${((prev) / denom * 100).toFixed(0)}%`}
							prevPoint={this.state.previousBufferCount}
							pointerPos={((this.startPoint / denom) * 100).toFixed(0)}
						></BufferBar>);
						found=false;
					}
					prev=i;
				}
				else found = true;
			}
			if(found){
				bufferBars.push(<BufferBar
					width={`${((denom-prev) / denom * 100).toFixed(0)}%`}
					startpoint={`${((prev) / denom * 100).toFixed(0)}%`}
					prevPoint={this.state.previousBufferCount}
					pointerPos={((this.startPoint / denom) * 100).toFixed(0)}
				></BufferBar>);
			}
		}

		return (
			<div className="slicer" ref="container">
				<div className="slicer-content" style={{position: "relative", width:"100%", height:"120%", nwhiteSpace:"nowrap"}}>

					{ this.state.loading && (
						<StyledLoader>
							<p className="message">Initialising</p>
							<Icon loading name='spinner' />
						</StyledLoader>
					)}

					<StyledViewer style={{width:"100%", paddingTop:"40px"}}>
						<canvas ref="canvas"/>
					</StyledViewer>

					<div style={{width:'100%', position:"absolute"}}>
						<Button icon={(this.state.showAnnos) ? 'eye': 'eye slash'} style={{height:"20px", paddingRight:"6px", margin:"0px"}} onClick={this._onButtonHide}/>
						<ProgressBarWrapper
							pointerPos={((this.startPoint / denom) * 100).toFixed(0)}>
							{bufferBars}
							<CustomSlider
								maxValue={denom}
								currentValue={this.slideridx}
								updateSlider={(e) => {
									this._onSliceSliderChange(e)
								}}
							></CustomSlider>
						</ProgressBarWrapper>
						&nbsp;&nbsp;<span>{bartext}</span>
					</div>
				</div>
			</div>
		)
	}
}

const StyledCustomSlider = styled.div`
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;

	.slider {
		-webkit-appearance: none;
		background-color: transparent!important;
		width: 100%;
		outline: none;
	}

	.slider::-webkit-slider-thumb {
		-webkit-appearance: none;
		appearance: none;
		transform:  translateY(-30%);
		width: 10px;
		height: 10px;
		background: white;
		cursor: pointer;
		border-radius: 50%;
	}

	.slider::-moz-range-thumb {
		width: 25px;
		height: 25px;
		background: transparent;
	}
`;

class CustomSlider extends Component {
	render() {
		return (
			<StyledCustomSlider>
				<input type="range"
					min={1}
					max={this.props.maxValue}
					value={this.props.currentValue}
					onChange={(e)=> this.props.updateSlider(e)} className="slider" id="myRange" />
			</StyledCustomSlider>
		)
	}
}

class ContrastSmartSlicer extends React.Component {
  render () {
    return(
			<SmartSlicer
        changeStateDct={this.props.changeStateDct}
	      patientID={this.props.patientID}
	      runID={this.props.runID}
	      relativepathtofiles={"volume_contrast"}
	      local={this.props.local}
				annoType={"CONTRASTSCAN"}
				availableVessels={this.props.availableVessels}
	      windowlevel={this.props.windowlevel}
	      windowwidth={this.props.windowwidth}
	    />
    )
  }
}

class NonContrastSmartSlicer extends React.Component {
  render () {
    return(
			<SmartSlicer
        changeStateDct={this.props.changeStateDct}
	      patientID={this.props.patientID}
	      runID={this.props.runID}
	      relativepathtofiles={"volume_non_contrast"}
	      local={this.props.local}
				annoType={"NONCONTRASTSCAN"}
        ref={this.slicerRef}
	      windowlevel={this.props.windowlevel}
	      windowwidth={this.props.windowwidth}
	    />
    )
  }
}

export {
	SmartSlicer,
	ContrastSmartSlicer,
	NonContrastSmartSlicer,
	CustomSlider
};
