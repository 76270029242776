import AWS from "aws-sdk";
import Amplify, { Auth } from "aws-amplify";

function _browser_base64ToArrayBuffer(base64) {
    // function to convert a base64 encoded string
    // into a binary blob that AWS's KMS service
    // expects

    let binary_string = window.atob(base64);
    let len = binary_string.length;
    let bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
}

async function decrypt(kms, cipher) {
    let params = {
        CiphertextBlob: _browser_base64ToArrayBuffer(cipher),
    };

    console.log(cipher);
    console.log(params);
    console.log(kms);

    try {
        return await kms.decrypt(params).promise();
    } catch (e) {
        console.log(e);
    }
}

async function decryptName(kms, patientCipher) {
    try {
        let r = await decrypt(kms, patientCipher);
        return r.Plaintext.toString();
    } catch (e) {
        console.error('Error decrypting name', e);
    }
}

export async function decryptDashboardData(dashboardData) {
    console.log("Decrypting dashboard data.");

    // Get our current user's credentials
    AWS.config.region = "ap-southeast-2";
    try {
        AWS.config.credentials = await Amplify.Auth.currentUserCredentials();
    } catch (e) {
        // Amplify responds with "cannot get guest credentials when mandatory signin enabled"
        // when an error happens (i.e. ambiguous role mapping rules)
        // By throwing here, we can catch it upstream in the fetchWrapper instead of continuing to
        // decrypt data which will error as well.
        throw new Error(`Unable to get current user credentials: ${e}`)
    }
    // Only init after specifying credentials
    const kms = new AWS.KMS();
    for (let d in dashboardData) {
        if (dashboardData[d]["patient_cipher"]) {
            console.log(d, " cipher = ", dashboardData[d]["patient_cipher"]);
            let plaintext = await decryptName(
                kms,
                dashboardData[d]["patient_cipher"]
            );
            console.log("plaintext = ", plaintext);
            if (plaintext) {
                dashboardData[d]["patient_plaintext"] = plaintext;
                dashboardData[d]["patient_name"] = dashboardData[d][
                    "patient_plaintext"
                ].split(",")[0];
                dashboardData[d]["patient_name"] = dashboardData[d][
                    "patient_name"
                ].replace("^", ", ");
            }
        }
    }

    return dashboardData;
}
