import React from 'react';

class C extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
        console.log("PROPS", this.props)
        this.props.changeState('errorPage', true)
    }

    componentWillUnmount(){
        this.props.changeState('errorPage', false)
    }

    render(){
        let message = `Sorry, we couldn't find what you're looking for.`

        if (this.props.permissionError) {
            message = `Your account does not have permission to retrieve data from the Artrya backend.`
        }

        return(
          <div className="viewer" id="scene-container" style={{width:"100%", marginLeft:"0%"}}>
                <div className="errorMsg centered">
                    {message}
                </div>
                {/*
                <Model
                    reportName="Patient"
                    className="model"
                    changeState={() => {}}
                    errorPage={true}
                />
                */}
            </div>
        )
    }

}

C.displayName = 'Error'

export default C
