// import 'react-contexify/dist/ReactContexify.min.css';
import { Item, Menu } from 'react-contexify';
import styled from 'styled-components';
import { colors } from '../../styles';

/**
 *  NOTE:
 *  Styles are mostly copied from `react-contexify`
 *  Source: https://github.com/fkhadra/react-contexify/blob/master/scss/_menu.scss
 *
 *  Didn't want to include all of the styles as most of them are unnecessary for our purposes or are
 *  doing too much. If we need all of them, just uncomment line 1 of this file. The below will still
 *  be required to override the contexify styles as there is currently no other solution to do so.
 */

const rootClass = '.react-contexify';

export const ContextMenu = styled(Menu)`
  position: fixed;
  opacity: 0;
  user-select: none;
  box-sizing: border-box;
  padding: 5px 0;
  /* Custom Styles below */
  box-shadow: ${colors.black30} 0 10px 20px, ${colors.greyLightest} 0 0 0 1px;
  background-color: ${colors.white};
  min-width: 30px;
  padding: 4px 0;
  z-index: 1;

  ${rootClass}__separator {
    /* float: left; */
    width: 100%;
    height: 1px;
    cursor: default;
    margin: 4px 0;
    background-color: ${colors.black20};
  }
`;

export const ContextItem = styled(Item)`
  cursor: pointer;
  position: relative;

  &${rootClass}__item:not(${rootClass}__item--disabled):hover > ${rootClass}__item__content {
    /* Custom Styles below */
    background-color: ${colors.blueMedium};
    color: ${colors.white};
  }

  &${rootClass}__item:not(${rootClass}__item--disabled):hover > ${rootClass}__submenu {
    pointer-events: initial;
    opacity: 1;
  }

  &${rootClass}__item--disabled {
    cursor: default;
    opacity: 0.5;
  }

  ${rootClass}__item__content {
    display: flex;
    text-align: left;
    white-space: nowrap;
    position: relative;
    /* Custom Styles below */
    color: ${colors.black};
    padding: 6px 8px;
  }
`;
