import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef
} from 'react';
import Amplify from 'aws-amplify';
import PropTypes from 'prop-types';
import { Button, Loader } from 'semantic-ui-react';
import { contextMenu } from 'react-contexify';
import * as utils from '../utils';
import * as h5 from '../h5';
import { clampNumber, isLeftMouseButton } from '../../utils';
import { Stage } from '../stage';
import WindowView from '../window-view';
import { SliceContainerStyled, WrapperStyled } from './vessel-explorer.styled';
import { ContextItem, ContextMenu } from '../context-menu';
import { ModeIcon } from '../mode-icon';
import { CprAnnotations } from '../annotations';
import { getConfig, getFromConfig } from '../../account';
import { MODIFIERKEYS } from '../settings';

const ANNOTYPES = {
  cpr:'CPR',
  mpr:'MPR',
  //mapping standard of long axis mpr to deprecated straightened mpr
  lmpr:'SMPR',
  ct:'CT'
};

// MODE is an old naming convention
// there are two sets of modes to avoid confusion
// MODE refers to the modes of the default controls that  do not use
// custom bindings
// BINDINGSMODE refers to the modes used by Custom Bindings
// as BINDINGSMODE is more up to date slice mode is replaced with scrollView
export const MODE = {
  slice: 'slice',
  zoom: 'zoom',
  window: 'window'
};

const BINDINGSMODE = {
  zoom:'zoom',
  pan:'pan',
  window:'window',
  scrollViews:'scrollViews',
  none:'none'
};

const WINDOW_LEVEL = {
  min: -100,
  max: 1000,
  initial: 240,
};

const WINDOW_WIDTH = {
  min: 100,
  max: 1500,
  initial: 720,
};

// TODO as an additional measure of safety add a check that mouse buttons match the combination
// expected by the drag functions

//would like to rename sliderIdx to viewIdx
//------------- rename annoType to viewType

function ViewLoader(props){
  const startTime = (new Date()).getTime();
  const [config, setConfig] = useState(null);
  //putting a lot of the props into state just so effects are called correctly
  //wrap in anonymous function due to behaviour of lazyInitialiser
  const [changeState,] = useState(()=>props.changeStateDct);
  const [annoType, setAnnoType] = useState(props.annoType)
  const [viewAxis, setViewAxis] = useState(props.viewAxis);
  const [transpose, setTranspose] = useState(null);
  const [thisNode, setThisNode] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showAnnos, setShowAnnos] = useState(true);
  const [sliderIdx, setSliderIdx] = useState(0);
  //need to differentiate the slice shown vs the sliceIdx to account for
  //sequential loading
  const [shownSliderIdx, setShownSliderIdx] = useState(null);
  const [sliceIdx, setSliceIdx] = useState(props.sliceIdx);
  //seperating sliderIdx and annoSliderIdx as annotations must wait for canvas
  //to load before redrawing
  const [annoSliderIdx, setAnnoSliderIdx] = useState(0);
  const [numSlices, setNumSlices] = useState(0);
  const [volumeShape, setVolumeShape] = useState(null);
  const [sliceData, setSliceData] = useState([]);
  const [mouseDelta, setMouseDelta] = useState(0);
  const [stageHeight, setStageHeight] = useState(100);
  const [stageWidth, setStageWidth] = useState(100);
  const [windowWidth, setWindowWidth] = useState(props.windowWidth);
  const [windowLevel, setWindowLevel] = useState(props.windowLevel);
  const [stageAspect, setStageAspect] = useState(-1);
  const [scaleFactor, setScaleFactor] = useState(1);
  // aspect is height / width
  // currentMode is the naming convention for default controls
  const [currentMode, setCurrentMode] = useState(
    props.initialMode || MODE.zoom
  );
  const [currentBindingsMode, setCurrentBindingsMode] = useState (BINDINGSMODE.none);
  const [usingBindings, setUsingBindings] = useState(false);
  const [dragStage, setDragStage] = useState(false);
  const [zoomByScale, setZoomByScale] = useState({
    scale:1,
    point:{
      x:0,
      y:0
    }
  });
  // mouse state is a string denoting current mouse presses
  // used to determine currentBindingsMode
  const [mouseState, setMouseState] = useState('');

  const prevVesselInfo = useRef({
    runID:props.runID,
    volumeFile:props.volumeFile,
    volumeDir:props.volumeDir,
    patientID:props.patientID,
    local:props.local,
    vesselID:props.vesselID,
    viewAxis:props.viewAxis,
    viewAxisOld:props.viewAxisOld,
    volumeKey:props.volumeKey,
    volumeKeyOld:props.volumeKeyOld,
    annoType:props.annoType,
    transpose:props.transpose
  });
  const cursorRef = useRef('auto');
  const previousSliderIdx = useRef(props.sliderIdx);
  const previousWindowWidth = useRef(windowWidth);
  const previousWindowLevel = useRef(windowLevel);
  const previousZoomByScale = useRef(zoomByScale);
  const viewsSet = useRef([]);
  const volumeData = useRef(null);
  const clCoords = useRef(null);
  // need a sliderIdxRef for dynamic loading
  const sliderIdxRef = useRef(null);

  useEffect(()=>{
    sliderIdxRef.current = sliderIdx;
  },[sliderIdx]);

  const thisRef = useCallback(ref =>setThisNode(ref), []);
  const HURef = useRef();

  // Helper to load JSONs regardless of locality
  const loadJSON = useCallback(async (url) => {
    let resp;
    if (props.local) {
      resp = await fetch(`http://localhost:3000/s3_mirror/` + url);
    } else {
      const awsurl = await Amplify.Storage.get(url);
      resp = await fetch(awsurl);
    }
    try{
      return await resp.json();
    }
    catch (err){
      console.error(err);
    }
    //return undefined if no JSON found
  },[props.local]);

  const loadView = useCallback(async (bucketPrefix, viewIdx, tpVolumeData, tpClCoords, props)=>{
    //possibly wrap in retry attempts
    const file = await h5.getH5Data(`${bucketPrefix}/${viewIdx}.h5`, null, props.local);
    const volumeInf = file.get(props.volumeDirKey);
    const clInf = file.get(props.annoKey);
    tpVolumeData[viewIdx] = utils.reshapeArray1d(volumeInf.value, volumeInf.shape);
    tpClCoords[viewIdx] = utils.reshapeArray1d(clInf.value, clInf.shape);
    if(props.transpose){
      tpVolumeData[viewIdx] = utils.transposeMatrix2d(tpVolumeData[viewIdx]);
      tpClCoords[viewIdx] = tpClCoords[viewIdx].map(x=>[x[1],x[0]]);
    }
    // due to below line, cannot call this method until viewsSet.current contains a correct sized array
    viewsSet.current[viewIdx] = true;
    if(sliderIdxRef.current === viewIdx){
      setShownSliderIdx(viewIdx);
    }
  },[viewsSet, sliderIdxRef]);

  const sequentialLoad = useCallback(
    async (bucketPrefix,volumeShape, props)=>{
      //likely going to refactor this for checking annoType with different vessel types
      //for now assert that this only works for cpr
      if(props.annoType !== ANNOTYPES.cpr) throw 'method not implemented for viewers other than CPR';
      const numViews = volumeShape[0];
      viewsSet.current = new Array(numViews).fill(false);
      const tpVolumeData = new Array(numViews);
      const tpClCoords = new Array(numViews);
      const firstViewIdx = numViews>>1;
      const firstFile = await h5.getH5Data(`${bucketPrefix}/${firstViewIdx}.h5`, null, props.local);
      const volumeInf = firstFile.get(props.volumeDirKey);
      const clInf = firstFile.get(props.annoKey);
      const dataShape = volumeInf.shape;
      tpVolumeData[firstViewIdx] =  utils.reshapeArray1d(volumeInf.value, dataShape);
      tpClCoords[firstViewIdx] = utils.reshapeArray1d(clInf.value, clInf.shape);
      if(props.transpose){
        tpVolumeData[firstViewIdx] = utils.transposeMatrix2d(tpVolumeData[firstViewIdx]);
        tpClCoords[firstViewIdx] = tpClCoords[firstViewIdx].map(x=>[x[1],x[0]]);
      }
      if(props.transpose) volumeShape = [volumeShape[0],volumeShape[2],volumeShape[1]];
      volumeData.current = tpVolumeData;
      clCoords.current = tpClCoords;
      viewsSet.current[firstViewIdx] = true;
      setVolumeShape(volumeShape);
      const [height,width] = [volumeShape[1],volumeShape[2]];
      setStageAspect(height / width);
      setSliderIdx(firstViewIdx);
      setShownSliderIdx(firstViewIdx);
      setAnnoSliderIdx(firstViewIdx);
      setIsLoading(false);
      const leftParents = new Array(numViews).fill(-1);
      const rightParents = new Array(numViews).fill(-1);
      const sliceLoading = new Array(numViews).fill(false);
      sliceLoading[firstViewIdx] = true;
      let loadingCount = 1;
      const bufferInterval = setInterval(()=>{
        let l = sliderIdxRef.current;
        // to save race condition
        if(!l) l = firstViewIdx;
        while(sliceLoading[l]){
          let stack = [];
          while(leftParents[l] !== -1){
            stack.push(l);
            l = leftParents[l];
          }
          while(stack.length){
            leftParents[stack.pop()] = l;
          }
          if(sliceLoading[l]){
            l--;
            if(l < 0) break;
            leftParents[l+1] = l;
          }
        }
        if(l >= 0){
          loadView(bucketPrefix, l, tpVolumeData, tpClCoords, props);
          sliceLoading[l] = true;
          loadingCount++;
        }
        let r = sliderIdxRef.current;
        if(!r) r = firstViewIdx;
        while(sliceLoading[r]){
          let stack = [];
          while(rightParents[r] !== -1){
            stack.push(r);
            r = rightParents[r];
          }
          while(stack.length){
            rightParents[stack.pop()] = r;
          }
          if(sliceLoading[r]){
            r++;
            if(r >= numViews) break;
            rightParents[r-1] = r;
          }
        }
        if(r < numViews){
          loadView(bucketPrefix, r, tpVolumeData, tpClCoords, props);
          sliceLoading[r] = true;
          loadingCount++;
        }
        if(loadingCount === numViews) clearInterval(bufferInterval);
      }, 200);

  },[viewsSet, sliderIdxRef, loadView, volumeData, clCoords]);

  const getSetting = useCallback((key)=>{
    if(!config) return;
    try{
      const rv = getFromConfig(config, key);
      //console.log(key, ':', rv);
      return rv;
    }
    catch(err){
      console.error(err);
    }
  },[config]);

  useEffect(()=>{
    const wrapGetConfig = async ()=>{
      setConfig(await getConfig());
    };
    wrapGetConfig();
  },[]);

  // define method to reload data
  const reloadData = useCallback(async () =>{
    // load numslices first
    const vesselDetails = await loadJSON(`${props.patientID}/${props.runID}/web_data.json`);
    const nSlices = vesselDetails.vessel_data[props.vesselID].n_slices;
    setNumSlices(nSlices);
    let file;
    if(props.vesselID){
      if(props.volumeDir){
        console.log('Looking for  ' + props.volumeDir + '/info.json');
        const infoFile = await loadJSON(`${props.patientID}/${props.runID}/vessels/${props.vesselID}/${props.volumeDir}/info.json`);
        if(infoFile){
          const shape = infoFile.shape;
          console.log('infoFile',infoFile);
          console.log(infoFile.shape);
          const bucketPrefix = `${props.patientID}/${props.runID}/vessels/${props.vesselID}/${props.volumeDir}`;
          sequentialLoad(bucketPrefix,shape,props);
          return;
          // at this point need to create the sequential loader and return from this function
        }
      }
      console.log('Looking for ' + props.volumeFile + ' in  vessel ' + props.vesselID);
      file = await h5.getH5Data(
        `${props.patientID}/${props.runID}/vessels/${props.vesselID}/${props.volumeFile}`,
        null, props.local);
    }
    else{
      console.log('Looking for ' + props.volumeFile + ' in top directory');
      file = await h5.getH5Data(
        `${props.patientID}/${props.runID}/${props.volumeFile}`,
        null, props.local);
    }
    if(!file) return console.error("Couldn't find "+props.volumeFile);
    // determine if backup key is required
    let usingBackup = false;
    let newViewAxis = props.viewAxis;
    try{
      file.get(props.volumeKey);
    }
    catch(err){
      console.error(props.volumeKey + " not found in " + props.volumeFile + ". Using Backup");
      usingBackup = true;
      viewAxis = props.viewAxisOld;
    }
    setViewAxis(viewAxis);
    let keyref = file.get((props.usingBackup)?props.volumeKeyOld:props.volumeKey);
    let dataShape = keyref.shape;
    let data = utils.reshapeArray1d(keyref.value,dataShape);
    if(props.transpose){
      for(let i = 0; i < data.length; i++){
        data[i] = utils.transposeMatrix2d(data[i]);
      }
      dataShape = [dataShape[0], dataShape[2], dataShape[1]];
    }
    let clShape;
    let annoCl2d;
    // load annotations assuming they were not already in vesselDir
    if(props.annoType === ANNOTYPES.lmpr){/*do nothing*/}
    else if(props.annoType === ANNOTYPES.cpr){
      let raw = await h5.getH5Data(
        `${props.patientID}/${props.runID}/vessels/${props.vesselID}/cpr.h5`,
        null, props.local);
      clShape = raw.get('centerlines_2d').shape;
      annoCl2d = utils.reshapeArray1d(raw.get('centerlines_2d').value, clShape);
      // TODO fix magic offset at this position
      // legacy from slicer.js
      for(let i = 0; i < clShape[0]; i++){
        for(let j = 0; j < clShape[1]; j++){
          annoCl2d[i][j][1]++;
        }
      }
    }
    else if(props.annoType === ANNOTYPES.mpr){
      //not implemented
    }
    else{
      console.error("Error: annotation type '" + props.annoType + "' not recognised");
    }
    if(clShape && props.transpose){
      for(let i = 0; i < clShape[0]; i++){
        for(let j = 0; j < clShape[1]; j++){
          annoCl2d[i][j]  = [annoCl2d[i][j][1],annoCl2d[i][j][0]];
        }
      }
    }
    setVolumeShape(dataShape);
    viewsSet.current =  new Array(dataShape[0]).fill(true);
    volumeData.current = data;
    clCoords.current = annoCl2d;
    let height = dataShape[(props.annoType === ANNOTYPES.lmpr)?0:2];
    let width = dataShape[1];
    if(props.transpose) [height, width] = [width, height];
    setStageAspect(height / width);
    // as long as setIsLoading comes last, there are no race conditions
    setIsLoading(false);
  },[props, volumeData]);

  //check for reload
  useEffect(()=>{
    let found = false;
    for(const key of Object.keys(prevVesselInfo.current)){
      if(props[key] !== prevVesselInfo.current[key]){
        prevVesselInfo.current[key] = props[key];
        found = true;
      }
    }
    if(found){
      setIsLoading(true);
      reloadData();
    }
  },[props, prevVesselInfo]);

  // load data on first load
  useEffect(()=>{
    reloadData();
  },[]);

  // set relevant fields for config one it is set.
  // Will be called twice but getSetting will only return once config
  // is set
  useEffect(()=>{
    const rv = getSetting('bindings.global.usingBindings');
    if(!rv) return;
    setCurrentMode(null);
    setUsingBindings(rv);
  },[getSetting]);

  // update sliceIdx if the Vessel picker deems such an update necessary
  useEffect(()=>{
    setSliceIdx(props.sliceIdx);
  },[props.sliceIdx]);

  // update shownSliderIdx if the index can be  shown

  useEffect(()=>{
    if(!viewsSet.current || !volumeShape) return;
    if(sliderIdx < 0 || volumeShape[0] <= sliderIdx) return;
    if(viewsSet.current[sliderIdx]) setShownSliderIdx(sliderIdx);
  },[sliderIdx, viewsSet, volumeShape]);

  //------------------------------------------------------------------------------------------------
  // STAGE CALLBACKS
  //------------------------------------------------------------------------------------------------
  const moveSliceIdxWithWheel = useCallback(
    (event)=>{
      if(isLoading) return;
      const canMove = currentMode === MODE.slice && numSlices > 0;
      if(canMove && numSlices){
        event.evt.preventDefault();
        const delta = (event.evt.deltaY > 0) - (event.evt.deltaY < 0);
        if(0 <= sliceIdx+delta && sliceIdx+delta < numSlices) {
          setSliceIdx(sliceIdx+delta);
          //TODO refactor to rename all sliceidx to sliceIdx
          changeState({sliceidx:sliceIdx+delta});
        }
      }
    },
    [
      currentMode,
      sliceIdx,
      numSlices,
      changeState,
      isLoading
    ]
  );

  const changeWindowWithDrag = useCallback(
    (event) => {
      const canChangeWindow = isLeftMouseButton(event) && mouseDelta !== null;

      if (canChangeWindow) {
        /**
         * NOTE:
         * Movement on the x-axis is used to change the **window width**.
         * Movement on the y-axis is used to change the **window level**.
         */
        const pointerPosition = event.target.getStage().getPointerPosition();
        // how far the mouse would need to travel to get from min to max
        // on either axis (virtual slider width/height)
        const distance = {
          x: stageWidth,
          y: stageWidth,
        };
        const scaleBy = {
          x: distance.x / WINDOW_WIDTH.max,
          y: distance.y / WINDOW_LEVEL.max,
        };
        const currentValue = {
          x: previousWindowWidth.current,
          y: previousWindowLevel.current,
        };
        const delta = {
          x: Math.round((pointerPosition.x - mouseDelta.x) / scaleBy.x),
          y: Math.round((pointerPosition.y - mouseDelta.y) / scaleBy.y),
        };
        const mn = {
          x: WINDOW_WIDTH.min - currentValue.x,
          y: WINDOW_LEVEL.min - currentValue.y,
        };
        const mx = {
          x: WINDOW_WIDTH.max - currentValue.x,
          y: WINDOW_LEVEL.max - currentValue.y,
        };
        const diff = {
          x: clampNumber(delta.x, mn.x, mx.x),
          y: clampNumber(delta.y, mn.y, mx.y),
        };
        const newValue = {
          width: currentValue.x + diff.x,
          level: currentValue.y + diff.y,
        };
        const updateObj = {};
        if (newValue.width !== windowWidth) updateObj['windowWidth'] = newValue.width;
        if (newValue.level !== windowLevel) updateObj['windowLevel'] = newValue.level;
        if(Object.keys(updateObj).length) changeState(updateObj);
      }
    },
    [mouseDelta, windowLevel, windowWidth, changeState, stageWidth]
  );

  // need to add invert setting here
  const rotateAngleWithDrag = useCallback(
    (event) => {
      const canRotate = mouseDelta !== null && !isLoading;

      if (canRotate) {
        /*
            In order to calculate the new angle, we need to consider the current angle as the starting point.
            The min/max values are dynamic and calculated to be relative to the current angle.
            The mouse position is stored when the user holds down the mouse button and reset when the button is released.
            We use the distance the mouse travelled to move the index within the min/max values of the calculated range.
            The result is scaled to the available number of angles.

            Derived example:
            maxAngleIndex = 20; currentIndex = 0; distance = 100; scaleBy = 100 / 20
            If mouse moves 50px right and releases:
              min = 0; max = 20; delta = 50 / (100 / 20)
              -> new index is (0 + 10) = 10
            Then, if mouse moves 25px left:
              min = -10; max = 10; delta = -25 / (100 / 20); currentIndex = 10
              -> new index is (10 + -5) = 5
        */
        const pointerPosition = event.target.getStage().getPointerPosition();
        const distance = stageWidth / 2; // how far the mouse can travel on the X-axis (virtual slider width)
        const scaleBy = distance / volumeShape[0];
        const currentIndex = previousSliderIdx.current; // the index before mouse started moving
        const delta = Math.round((pointerPosition.x - mouseDelta.x) / scaleBy); // distance travelled
        const mn = 0 - currentIndex; // lower boundary based on currentIndex
        const mx = volumeShape[0]-1 - currentIndex; // upper boundary based on currentIndex
        const diff = clampNumber(delta, mn, mx);
        const newIndex = currentIndex + diff;
        if (newIndex !== sliderIdx) setSliderIdx(newIndex);
      }
    },
    [isLoading, mouseDelta, volumeShape, sliderIdx, stageWidth]
  );

  // TODO add invert setting and zoom sensitivity
  const zoomWithDrag  = useCallback((event)=>{
    const canZoom = mouseDelta !== null && !isLoading && previousZoomByScale.current;
    if(!canZoom) return;
    const pointerPosition = event.target.getStage().getPointerPosition();
    // set default scale factor per unit
    // TODO: UX design choice whether to make scale factor relative to width or absolute
    const zoomPerUnit = 1.02;
    let scale = previousZoomByScale.current.scale;
    // just using y axis for now
    const dy = pointerPosition.y - mouseDelta.y;
    scale *= Math.pow(zoomPerUnit, -dy);
    setZoomByScale({
      scale:scale,
      point:mouseDelta
    });
  },[isLoading, mouseDelta, stageWidth, stageHeight, previousZoomByScale]);

  // TODO: let this handle changes to modifier keys whilst dragging

  const mouseEventEqual = useCallback((event, binding)=>{
    let currElems = new Set();
    if(mouseState.length) currElems = new Set(mouseState.split('+'));
    if(event.type === 'mousedown'){
      currElems.add('mb'+event.evt.button);
    }
    else if(event.type === 'mouseup'){
      const buttonString = 'mb'+event.evt.button;
      if(currElems.has(buttonString)) currElems.delete(buttonString);
    }
    // sort is not necessary but nice for debugging
    const newMouseState = (new Array(...currElems)).sort().join('+');
    if(newMouseState !== mouseState) setMouseState(newMouseState);
    if(event.evt.altKey) currElems.add(MODIFIERKEYS.alt);
    if(event.evt.metaKey) currElems.add(MODIFIERKEYS.meta);
    if(event.evt.ctrlKey) currElems.add(MODIFIERKEYS.control);
    if(event.evt.shiftKey) currElems.add(MODIFIERKEYS.shift);
    const bindingElems = binding.split('+');
    if(bindingElems.length !== currElems.size) return false;
    for(const elem of bindingElems){
      if(!(currElems.has(elem))) return false;
    }
    return true;
  }, [mouseState]);

  //TODO  update this callback with annoType
  const getViewerMode = useCallback((event, mouseState)=>{
    let volumeIdentifier = '';
    switch(annoType){
    case ANNOTYPES.cpr:
      volumeIdentifier = 'cpr';
      break;
    case ANNOTYPES.mpr:
      volumeIdentifier = 'mpr';
      break;
    case ANNOTYPES.lmpr:
      volumeIdentifier = 'lmpr';
      break;
    case ANNOTYPES.ct:
      volumeIdentifier = 'ct';
      break;
    default:
      return console.error('bad anno type', annoType);
    }
    const zoomBinding = getSetting(`bindings.viewer.${volumeIdentifier}.zoom`);
    const panBinding = getSetting(`bindings.viewer.${volumeIdentifier}.pan`);
    const windowBinding = getSetting(`bindings.viewer.${volumeIdentifier}.window`);
    const scrollViewsBinding = getSetting(`bindings.viewer.${volumeIdentifier}.scrollViews`);
    // evaluates settings in order zoom -> pan -> window->slice
    // only noticeable if no enforcement of unique values
    if(mouseEventEqual(event, zoomBinding)) return BINDINGSMODE.zoom;
    if(mouseEventEqual(event, panBinding)) return BINDINGSMODE.pan;
    if(mouseEventEqual(event, windowBinding)) return BINDINGSMODE.window;
    if(mouseEventEqual(event, scrollViewsBinding)) return BINDINGSMODE.scrollViews;
    return BINDINGSMODE.none;
  }, [annoType, mouseEventEqual, getSetting])

  const newPointerCapture = useCallback((event)=>{
    const pointerPosition = event.target.getStage().getPointerPosition();
    previousWindowLevel.current = windowLevel;
    previousWindowWidth.current = windowWidth;
    previousSliderIdx.current = sliderIdx;
    previousZoomByScale.current = zoomByScale;
    if(usingBindings){
      const tpBindingsMode = getViewerMode(event);
      if(tpBindingsMode === BINDINGSMODE.pan) setDragStage(true);
      else setDragStage(false);
      setMouseDelta((tpBindingsMode!==BINDINGSMODE.none)?pointerPosition:null);
      setCurrentBindingsMode(tpBindingsMode);
      return;
    }
    // handle using context menu form of controls
    if (isLeftMouseButton(event)){
      if(event.type === 'mousedown') setMouseDelta(pointerPosition);
      else setMouseDelta(null);
    }
  },[currentMode, windowLevel, windowWidth, sliderIdx, getViewerMode, usingBindings]);

  const clearPointerCapture = useCallback(()=>{
    if(usingBindings) setCurrentBindingsMode(BINDINGSMODE.none);
    previousWindowLevel.current = windowLevel;
    previousWindowWidth.current = windowWidth;
    previousSliderIdx.current = sliderIdx;
    setDragStage(false);
    setMouseDelta(null);
    setMouseState('');
  },[windowLevel, windowWidth, sliderIdx, usingBindings]);

  const handleStageMouseMove = useCallback((event)=>{
    if(usingBindings){
      //TODO add check that modifier keys haven't changed
      switch (currentBindingsMode) {
      case BINDINGSMODE.zoom:
        zoomWithDrag(event);
        break;
      case BINDINGSMODE.window:
        changeWindowWithDrag(event);
        break;
      case BINDINGSMODE.scrollViews:
        rotateAngleWithDrag(event);
        break;
      }
    }
    else{
      switch(currentMode){
      case MODE.slice:
        rotateAngleWithDrag(event);
        break;
      case MODE.window:
        changeWindowWithDrag(event);
      }
    }
  }, [
    currentMode,
    currentBindingsMode,
    usingBindings,
    rotateAngleWithDrag,
    changeWindowWithDrag,
    zoomWithDrag
  ]);

  const handleStageContextMenu = useCallback(
    (event) => {
      if(isLoading) return;
      event.evt.preventDefault();
      if(usingBindings) return;
      contextMenu.show({
        id: 'menu',
        event: event.evt,
      });
    },
    [isLoading, usingBindings]
  );
  const handleStageMouseDown = (event) => {
    newPointerCapture(event);
  }
  const handleStageMouseUp = (event) => newPointerCapture(event);
  const handleStageMouseLeave = (event) => clearPointerCapture();

  //------------------------------------------------------------------------------------------------
  // WINDOW CALLBACKS
  //------------------------------------------------------------------------------------------------

  const handleWindowMove = useCallback((hue, e)=>{
    HURef.current && HURef.current.setValue(hue);
  },[HURef]);

  const handleWindowLeave = useCallback(()=>{
    HURef.current && HURef.current.setValue(null);
  },[HURef]);

  //------------------------------------------------------------------------------------------------
  // STATE CHANGES
  //------------------------------------------------------------------------------------------------
  // load data on mount

  //some obscure bug with resizing after reloading different vessel data
  const resizeHandler = useCallback(()=>{
    if(!thisNode || stageAspect === -1 || !volumeShape) return;
    const width = thisNode.parentNode.offsetWidth;
    const height = width*stageAspect;
    setStageWidth(width);
    setScaleFactor(width/volumeShape[2]);
    setStageHeight(height);
  },[thisNode, stageAspect, volumeShape]);

  // add listener for window resize on mount
  // assert this only occurs once with [] dependency
  useEffect(() =>{
    window.addEventListener('resize', resizeHandler);
    return () => window.removeEventListener('resize', resizeHandler)
  },[resizeHandler]);

  // update with every prop update
  useEffect(()=>{
    setWindowLevel(props.windowLevel);
    setWindowWidth(props.windowWidth);
  },[props.windowWidth, props.windowLevel]);

  useEffect(()=>{
    setAnnoType(props.annoType);
  },[props.annoType]);

  useEffect(()=>{
    if(isLoading) return;
    if(!volumeData.current) return;
    if(shownSliderIdx === null) return;
    if(!(0 <= sliderIdx && sliderIdx < volumeData.current.length)) return;
    setSliceData(volumeData.current[shownSliderIdx]);
  },[shownSliderIdx, volumeData,  isLoading]);

  // init stageArea
  useEffect(()=>{
    if(!thisNode || stageAspect === -1 || !volumeShape) return;
    const width = thisNode.parentNode.offsetWidth;
    setStageWidth(width);
    setScaleFactor(width/volumeShape[2]);
    setStageHeight(width*stageAspect);
  },[thisNode, stageAspect, volumeShape]);

  //uncertain if prop updates forcing re-renders will be an issue
  //TODO investigate this
  return (
    <div className="cprWrapper">
      {!isLoading && (
        <>
          <Button
            className="button"
            icon={(showAnnos) ? 'eye': 'eye slash'}
            onClick={(e)=>setShowAnnos(!showAnnos)}
          />
          <HUDisplay ref={HURef}/>
        </>
      )}
      <div className={(isLoading)?"":"cprStage"} ref={thisRef}>
      {isLoading && (
        <Loader active inline='centered' style={{marginTop: "20%"}}>
          Loading Data
        </Loader>
      )}
      {!isLoading && (
        <>
        <WrapperStyled>
          <SliceContainerStyled>
            <Stage
              dragStage={dragStage}
              zoomByScale={zoomByScale}
              mode={isLoading ? null : currentMode}
              width={stageWidth}
              height={stageHeight}
              isLoading={false}
              onStageWheel={moveSliceIdxWithWheel}
              onStageMouseDown={handleStageMouseDown}
              onStageMouseMove={handleStageMouseMove}
              onStageMouseUp={handleStageMouseUp}
              onStageMouseLeave={handleStageMouseLeave}
              onStageContextMenu={handleStageContextMenu}
            >
              <WindowView
                width={stageWidth}
                sliceData={sliceData}
                onMouseMove={handleWindowMove}
                onMouseLeave={handleWindowLeave}
                windowLevel={windowLevel}
                windowWidth={windowWidth}
                onCanvasUpdate={()=>setAnnoSliderIdx(shownSliderIdx)}
                useShape
              />
              {showAnnos && annoType==='CPR' &&(
                <CprAnnotations
                  scaleFactor={scaleFactor}
                  sliderIdx={annoSliderIdx}
                  width={stageWidth}
                  height={stageHeight}
                  points={clCoords.current[annoSliderIdx]}
                  sliceIdx={sliceIdx}
                  numSlices={numSlices}
                  changeState={changeState}
                />
                )
              }
              </Stage>
          </SliceContainerStyled>
        </WrapperStyled>
        {!usingBindings && (
          <ContextMenu id={'menu'}>
            <ContextItem onClick={setCurrentMode.bind(null, MODE.slice)}>
              <ModeIcon mode={MODE.slice} />
            </ContextItem>
            <ContextItem onClick={setCurrentMode.bind(null, MODE.zoom)}>
              <ModeIcon mode={MODE.zoom} />
            </ContextItem>
            <ContextItem onClick={setCurrentMode.bind(null, MODE.window)}>
              <ModeIcon mode={MODE.window} />
            </ContextItem>
          </ContextMenu>
        )}
        </>
      )}
      </div>
    </div>
  );
 }
//
const HUDisplay = forwardRef((props, ref) =>{
  const [val, setVal] = useState(null);
  useImperativeHandle(ref, ()=>({
    setValue:x=>setVal(x)
  }));
  return (
    <>
    {val && (
      <div className="huContainer">
        <div className="huLabel">
          HU Value: {val}
        </div>
        <div className="huBackground" />
      </div>

    )}
    </>
  );
});

ViewLoader.propTypes = {
  patientID: PropTypes.string.isRequired,
  runID: PropTypes.string.isRequired,
  local: PropTypes.bool,
  vesselID: PropTypes.string.isRequired,
  volumeFile: PropTypes.string.isRequired,
  volumeDir: PropTypes.string.isRequired,
  volumeKey: PropTypes.string.isRequired,
  annoKey: PropTypes.string.isRequired,
  viewAxis: PropTypes.number.isRequired,
  sliceAxis: PropTypes.number.isRequired,
  sliceIdx: PropTypes.number,
  annoType: PropTypes.string.isRequired,
  transpose: PropTypes.bool,
  initialMode: PropTypes.string,
  windowLevel: PropTypes.number.isRequired,
  windowWidth: PropTypes.number.isRequired,
  changeStateDct: PropTypes.func.isRequired
};

class CprViewer extends React.Component {
  render () {
    return(
      <ViewLoader
        //className={"slicer-cpr"}
        patientID={this.props.patientID}
        runID={this.props.runID}
        local={this.props.local}
        vesselID={this.props.vesselID}
        volumeFile={"cpr.h5"}
        volumeDir={"cpr"}
        volumeKey={"views"}
        volumeDirKey={"data"}
        annoKey={"cl_anno"}
        viewAxis={0}
        sliceAxis={0}
        sliceIdx={this.props.sliceidx}
        annoType={ANNOTYPES.cpr}
        transpose={true}
        scrollY={false}
        windowlevel={this.props.windowlevel}
        windowwidth={this.props.windowwidth}
        changeStateDct={this.props.changeStateDct}
        {...this.props}
      />
    )
  }
}

export {
  ViewLoader,
  CprViewer
}
