import React, { Component } from 'react';
import {
  HashRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import Patient from './patient'
import './css/general.css'
import './css/help.css'
import './css/patient.css'
import './css/dashboard.css'
import './css/account.css'
import Dashboard from './dashboard'
import Navbar from './components/navbar'
import Test from './test'
import Help from './help'
import Account from './account'
import Amplify, { Auth } from 'aws-amplify';
import { withAuthenticator } from 'aws-amplify-react'; // or 'aws-amplify-react-native'
import ErrorPage from './error'
import { decryptDashboardData, clearAccessToken } from './utils'
//import { delimiter } from 'path';
import isEqual from 'lodash/isEqual'

console.log(process.env)
Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
  },
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_S3_BUCKET,
      region: process.env.REACT_APP_REGION,
    },
  },
});

const PERMISSION_CHECK_INTERVAL = 1000 * 30

const ProppedRoute = ({ render: C, props: childProps, ...rest }) => (
     <Route {...rest} render={rProps => <C {...rProps} {...childProps} />} />
);

const Routes = ({ childProps }) => (
  <Switch>
    <ProppedRoute
      exact
      path="/"
      render={Dashboard}
      props={childProps}
    />
    <ProppedRoute
      exact
      path="/dashboard"
      render={Dashboard}
      props={childProps}
    />
    <ProppedRoute
      exact
      path="/patient/:id"
      render={Patient}
      props={childProps}
    />
    <ProppedRoute
      exact
      path="/help"
      render={Help}
      props={childProps}
    />
    <ProppedRoute
      exact
      path="/help/:key"
      render={Help}
      props={childProps}
    />
    <ProppedRoute
      exact
      path="/account"
      render={Account}
      props={childProps}
    />
    <ProppedRoute
      exact
      path="/error"
      render={ErrorPage}
      props={childProps}
    />
     <ProppedRoute
      exact
      path="/test"
      render={Test}
      props={childProps}
    />
    <ProppedRoute //catch 404s
    render={Error}
    props={childProps}
    />
  </Switch>
);


class App extends Component {
    constructor(props) {
        super(props);

        let cognitoGroups = []
        try {
            cognitoGroups = props.authData.signInUserSession.accessToken.payload['cognito:groups']
        } catch(e) {
            console.error('Error getting Cognito groups.')
        }

        this.state = {
            patientID: null,
            errorPage: false,
            permissionError: false,
            cognitoGroups
        }

        this.changeState = this.changeState.bind(this)
        this.sessionRefreshInterval = null

        /*
            console.log("SETTING TIMER");
            this.timerID = setInterval(
            () => this.tick(),
            4000
            );
        */

        window.addEventListener("keypress", this.keyPress)
    }

  tick() {
    console.log("tick");
    this.loadDashboard();
		this.render();
	}

	keyPress(e){
		if (e.shiftKey){
			if (e.code == 'Digit0'){
				document.documentElement.setAttribute('data-theme', null)
			}
			else if ([
				'Digit1', 'Digit2', 'Digit3', 'Digit4',
				'Digit5', 'Digit6', 'Digit7', 'Digit8', 'Digit9'
				].includes(e.code)){
					document.documentElement.setAttribute('data-theme', e.code[e.code.length - 1])
			}
		}
	}

    changeState(k, v){
        console.log("CHANGING APP STATE", k, v)
        this.setState({[k]: v})
    }

    async fetchWrapper(url, options, stateKey) {
      try {
        console.log('fetching', url)
        const response = await fetch(url, options);
        console.log('response', response)
        const json = await response.json();
        console.log('json', json)

        let data = response.ok ? json : Promise.reject(json)
        // decrypt the patient_cypher
        data = await decryptDashboardData(data);
        console.log("Post decrypt: data is now: ", data);

        if (stateKey){
          this.setState({[stateKey]: data})
        }
        else {
          return data
        }
      }
      catch (e){
        console.error('fetchWrapper error', e)
        this.setState({permissionError: true, dashboardData: null })
      }
    }

    refreshSession = async () => {
        try {
            const cognitoUser = await Auth.currentAuthenticatedUser()
            const currentSession = await Auth.currentSession()

            return new Promise((resolve, reject) => {
                cognitoUser.refreshSession(currentSession.refreshToken, (error, session) => {
                    if (error) {
                        reject(error)
                        return
                    }
                    console.log('Refreshed session:', session)
                    resolve(session)
                });
            })
          } catch (e) {
            throw new Error(e)
          }
    }

    performPermissionCheck = async () => {
        try {
            const session = await this.refreshSession()
            const cognitoGroups = session.getAccessToken().payload['cognito:groups']

            // If the cognitoGroups changed since login (the app was mounted) OR
            // if the user has no groups assigned (`cognito:groups` property is undefined, i.e. not present in the payload)
            // log the user out.
            if (
                !Array.isArray(cognitoGroups) ||
                !isEqual(this.state.cognitoGroups, cognitoGroups)
            ) {
                Auth.signOut();
            }
        } catch (e) {
            // If we fail to do a permission check for any reason, log the user out.
            console.log('Error performing permission check', JSON.stringify(e))
            Auth.signOut();
        }
    }

    async loadDashboard() {
      //load dashboard data - local or s3
      if (process.env.REACT_APP_USE_LOCAL === 'true'){
        console.log('using local data source (not s3)')
        let resp = await fetch('http://localhost:3000/s3_mirror/dashboard_data.json')
        let dashboardData = await resp.json()

        // decrypt the patient_cypher
        dashboardData = await decryptDashboardData(dashboardData);
        console.log("Local decrypt: data is now: ", dashboardData);

        this.setState({dashboardData: dashboardData})
      }
      else {
        console.log('loading from s3')
        Amplify.Storage.get('dashboard_data.json')
          .then (url => {
            this.setState({permissionError: false})
            this.fetchWrapper(url, null, 'dashboardData')
        })
          .catch(err => {
            this.setState({permissionError: true, dashboardData: null })
            console.log('ERROR', err)
          });
      }
    }

    async componentDidMount(){
        //load dashboard data
        await this.loadDashboard()

        this.sessionRefreshInterval = setInterval(this.performPermissionCheck, PERMISSION_CHECK_INTERVAL);
    }

    componentWillUnmount() {
        clearInterval(this.timerID);
        clearInterval(this.sessionRefreshInterval);
    }

    render(){

      console.log("--- this.state: ", this.state);
      let patientName = this.state.patientID;
      if (this.state.dashboardData) {
        if (this.state.dashboardData[this.state.patientID]) {
          patientName = this.state.dashboardData[this.state.patientID]['patient_name'];
        }
      }
      console.log("*** Setting patient name to: ", patientName);

      let childProps = {
          local: process.env.REACT_APP_USE_LOCAL === 'true',
          patientID: this.state.patientID,
          patientName: patientName,
          changeState: this.changeState,
          dashboardData: this.state.dashboardData,
          errorPage: this.state.errorPage,
          onDashboard: this.state.onDashboard,
          permissionError: this.state.permissionError
      };

      console.log("DASHBOARD DATA", this.state.dashboardData)

      return (
          <Router>
              <div className={`content-wrap page`}>
                  <Navbar {...childProps}/>
                  <Routes childProps={childProps}/>
                  {/*
                  <div className="watermarkLogo">
                      <Image src={require('../public/logo_full.png')} className="navBarLogo"/>
                  </div>
                  */}
                  <div className="footer">
                    <p>
                      &copy; Artrya Pty Ltd {(new Date().getFullYear())}
                    </p>
                  </div>
              </div>
          </Router>
      );
      }
  }

//export default withAuthenticator(App, true);
//export default App

const MyTheme = {
  html: { style: "background-color: 'pink';" },
  // A bit of a hack to set the background colour of the whole page ...
  container: { backgroundColor: "#161e30", paddingTop: "40px", top: "-40px", height: "1200px", position: "relative"},
  inputLabel: { color: "white"},
  // this does "No account?" bit:
  sectionFooter: { color: "white"},
  // this does "forget your password" bit:
  hint: { color: "white"},
  formSection: { backgroundColor: "#333950" },
  sectionHeader: { color: "white"},
  button: { backgroundColor: "#ff6969" },
  signInButtonIcon: { display: "none" },
  a: { color: "#ff6969" },
};

///*
export default withAuthenticator(App,
  {
    includeGreetings: false,
    theme: MyTheme
  });
  //*/
