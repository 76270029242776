import React from 'react';
import Amplify from 'aws-amplify';
import * as hdf5 from '../jsfive/index';


async function getH5Data(url, key, local){
    try {
        if (local){
            url = `http://localhost:3000/s3_mirror/${url}`
        }
        var aBuffer = await getArrayBuff(url, local)
        //console.log("ABUFFER", aBuffer)
        var file = new hdf5.File(aBuffer, "");
        //console.log("FILE", file)
        if (key){
            return file.get(key).value
        }
        else {
            return file
        }
    }
    catch {
        console.log("Error reading h5data", url, key, local)
    }
}

async function getArrayBuff(url, local){
    let aBuffer
    if (local) {
        let resp = await fetch(url)
        aBuffer = await resp.arrayBuffer()
    }
    else {
        let data = await Amplify.Storage.get(url, { download: true })
        aBuffer = data.Body.buffer
    }
    return aBuffer
}

export { getH5Data }
