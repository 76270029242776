import { reshape } from 'mathjs';
import Logger from './logger';

export const DEVELOPMENT =
  process.env.REACT_APP_ENV === 'development' ||
  process.env.NODE_ENV === 'development';
export const STAGING =
  process.env.REACT_APP_ENV === 'staging' || process.env.NODE_ENV === 'test';
export const PRODUCTION =
  process.env.REACT_APP_ENV === 'production' ||
  process.env.NODE_ENV === 'production';

/**
 * Instantiates a simple all zeros 2d matrix
 * @param {number} rows
 * @param {number} columns
 * @param {*} [fill=0] - value
 * @returns {fill[][]} 2d matrix
 */
export function create2dMatrix(rows, columns, fill = 0) {
  return new Array(rows).fill().map(() => new Array(columns).fill(fill));
}

export function reshape1dArray(data, sizes) {
  return reshape(data, sizes);
}

export function getWindowBoundaryRect(
  WindowView,
  scaleBy = 1,
  padding = { x: 0, y: 0 }
) {
  const position = WindowView.getAbsolutePosition();
  const boundaryRect = {
    width: (WindowView.width() - padding.x) * scaleBy,
    height: (WindowView.height() - padding.y) * scaleBy,
    top: position.y + padding.y * scaleBy,
    get right() {
      return position.x + this.width;
    },
    get bottom() {
      return position.y + this.height;
    },
    left: position.x + padding.x * scaleBy,
  };
  return boundaryRect;
}

/**
 * Returns a number whose value is limited to the given range.
 *
 * Example: limit the output of this computation to between 0 and 255
 * (x * 255).clamp(0, 255)
 *
 * @param {number} number The number to clamp
 * @param {number} min The lower boundary of the output range
 * @param {number} max The upper boundary of the output range
 * @returns A number in the range [min, max]
 * @type Number
 */
export function clampNumber(number, min, max) {
  return Math.min(Math.max(number, min), max);
}

/**
 * Returns whether or not a given event came from a left mouse button click.
 *
 * @param {Event} event
 * @returns {boolean} boolean
 */
export function isLeftMouseButton(event) {
  if (typeof event !== 'object') return false;

  // Konva stores the original event object in `evt`
  const evt = event.evt || event || window.event;
  if (!evt) return false;

  if ('button' in evt) {
    // Left button (primary)      0
    // Middle button (auxillary)  1
    // Right button (secondary)   2
    // X1 button (back)           3
    // X2 button (forward)        4
    return evt.button === 0;
  }
  Logger.Error('Unable to determine button.');
  return false;
}
