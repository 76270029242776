import React, { Component } from 'react';
import Amplify from 'aws-amplify';
import * as utils from './utils'

// This component takes a provided colourmap file, and creates a legend of
// continous color and plots numbers on said legend, which is of a given size
class Legend extends Component {

	constructor(props){
		super(props);

		// Must load it here so its available for JSX construction in first
		// render pass!

    // Use a given colour map, generate a new colormap
    this.mapping = require("../legends/" + this.props.mapFile);
    this.valuemin = this.mapping[0].value;
    this.valuemax = this.mapping[this.mapping.length - 1].value;
    this.valuerange = this.valuemax - this.valuemin;

    // Get the position of where to place markers, and correspinding labels
		// on the canvas
    this.markerheights = Array();
    for(var i = 0; i < this.mapping.length; i++) {
      let ratio = (this.mapping[i].value - this.valuemin) / this.valuerange;
      let y = Math.floor((1 - ratio) * (this.props.height));
			y = Math.min(Math.max(y, 0), this.props.height - 1);
      this.markerheights.push(y);
    }

		// For console testing
		window.test_legend = this;
	}

	componentDidMount() {
		// Create a new canvas of desired size and edit pixels row by row using
		// required color map
		this.canvas = this.refs.canvas;
		this.canvas.width  = this.props.width;
		this.canvas.height = this.props.height;
		this.ctx = this.canvas.getContext("2d");

		// Setup colouring on bar
		this.buildLegend();

		// Rerender
		this.render();
	}

	writeToBuffer(buffer) {
		// Draw matrix into buffer in range [0, 255] depending on windowing
		for(var y = 0; y < this.props.height; y++) {
			let ratio = 1 - ((y + 1) / this.props.height);
			let value = this.valuemin + ratio * this.valuerange;
			let color = utils.linear1dColorMap(this.mapping, value);

			// Now fill this horizontally
  		for(var x = 0; x < this.props.width; x++) {
  			var bufpos = (y * this.props.width + x) * 4;

        // Need to convert between height into the canvas, to value within
        // the colour map. Negating because we want to draw from bottom to top
        // not top to bottom

  			// RGBA values respectively
  			buffer[bufpos+0] = color.r * 255;
  			buffer[bufpos+1] = color.g * 255;
  			buffer[bufpos+2] = color.b * 255;
  			buffer[bufpos+3] = 255;
  		}
		}

    // Draw in white lines at each colour change segment changeover
    for(var i = 0; i < this.markerheights.length; i++) {
      for (var x = 0; x < this.props.width; x++) {
  			let bufpos = (this.markerheights[i] * this.props.width + x) * 4;

  			// RGBA values respectively
  			buffer[bufpos+0] = 255;
  			buffer[bufpos+1] = 255;
  			buffer[bufpos+2] = 255;
  			buffer[bufpos+3] = 255;
      }
    }

		return buffer;
	}

	writeToCanvas(buffer) {
		// Create imageData object, set buffer as source and update canvas
		var idata = this.ctx.createImageData(this.props.width, this.props.height);
		idata.data.set(buffer);
		this.ctx.putImageData(idata, 0, 0);

		// Outline with a border
		this.ctx.lineWidth = 2;
		this.ctx.strokeStyle = "white";

    	//this.ctx.strokeRect(0, 0, this.canvas.width, this.canvas.height);
	}

	buildLegend() {
		let buf = utils.getEmptyBuffer(this.props.width, this.props.height);
		buf = this.writeToBuffer(buf);
		this.writeToCanvas(buf);
	}

	createLabelsAsJSX() {
		/*
		// The first immediate render will try to draw on the marker heights before
		// they are defined - skip this, it isn't even drawn to the screen anyway
		if (this.markerheights == null) {
			//console.log("Marker heights not defined on first pass")
			return <div></div>;
		}
		*/

		// Create the JSX to line the labels up to to the markers as JSX for
		// rendering
		let labelList = [];
		for (var i = 0; i < this.markerheights.length; i++) {
		//for (var i = 0; i < 5; i++) {
			let y = this.markerheights[i];
			let label = this.mapping[i].label
			let style = {
				position: "absolute",
				right: "20px",
				top: String(y - 9)+"px"
			}
			labelList.push(
				<div key={i} style={style}>
					{label}
				</div>
			)
		}

		return labelList;
	}

	render() {
    // Can also have header:   <div className="dropdown">{this.props.header}</div>
  	return <div className={"legend"}>
	      <div className="legend-markers">
				{this.createLabelsAsJSX()}
	      </div>
	      <canvas ref="canvas"/>
	    </div>
  }
}

export default Legend;
