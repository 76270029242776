export const colors = {
  white: '#fff',
  black: '#000',
  black20: 'rgba(0, 0, 0, 0.2)',
  black30: 'rgba(0, 0, 0, 0.3)',
  black50: 'rgba(0, 0, 0, 0.5)',

  greyLight: '#E4E7E8',
  greyDark: '#5b606f',
  greyLightest: '#ebebeb',

  blue: '#30d1fd',
  blueMedium: '#2a3047',
  blueDark: '#161e30',

  green: '#44e9b5',
  yellow: '#fbd655',
  pink: '#c761fc',
  orange: '#fc8622',
  red: '#fc696c',

  get brandPrimary() {
    return this.blue;
  },

  get info() {
    return this.blue;
  },
  get success() {
    return this.green;
  },
  get warning() {
    return this.orange;
  },
  get error() {
    return this.red;
  },

  get buttonColor() {
    return this.blueMedium;
  },

  get panelBackground() {
    return this.blueDark;
  },

  get cardBackground() {
    return this.blueMedium;
  },

  backgroundStart: '#343b52',
  backgroundStop: '#1c2031',
};
