import React from 'react';
import {Menu, Button, Form, Dropdown, Loader, Icon} from 'semantic-ui-react'
import { API, graphqlOperation } from 'aws-amplify'
import { len, getDdOptions } from './components/utils'

class C extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            suggestionMsg:false,
            betaFeedbackThanks:false,
            betaFeedback:{},
            msg:null,
            suggestions:"",
            suggestionTopic:"Any",
            active:'faq',
            faqCollapse:{}
        }
        this.suggestionTopics = [
            'Any', '3D Model',
            'Final Report', 'Vessel MPR Viewer', 
            'Scan Quality Report',  
        ]
        this.getCollapse = this.getCollapse.bind(this)
        this.toggleCollapse = this.toggleCollapse.bind(this)
        this.updateSuggestion = this.updateSuggestion.bind(this)
        this.submitSuggestion = this.submitSuggestion.bind(this)
    }

    componentDidUpdate(){
        if (len(this.state.suggestions)) {
            window.onbeforeunload = () => true
        } else {
            window.onbeforeunload = undefined
        }
    }

    async componentDidMount(){
        document.title = "Help"
        let key = this.props.match.params.key
        this.changeSection(key)
    }

    changeSection(key){
        if (key){
            this.props.history.push(`/help/${key}`)    
        }
        else {
            this.props.history.push(`/help`)    
        }
    }

    getCollapse(key, icon){
        let state = this.state.faqCollapse[key]
        if (icon){
            return  (state === 'show') ? 'chevron down' : 'chevron right'
        }
        else {
            return  (state === 'show') ? state : 'hide'
        }
    }

    toggleCollapse(key){
        let stateCopy = {...this.state.faqCollapse}
        let state = (stateCopy[key] === 'show') ? 'hide' : 'show'
        stateCopy[key] = state
        console.log('collapsing', key, state)
        this.setState({faqCollapse:stateCopy})
    }

    displaySection(){
        const key = this.props.match.params.key
        if (key === "contact"){
            return(
                <div className="contact">
                    <div className="contactTitle" >
                        <h3>Get in touch.</h3>
                        <hr/>
                    </div>
                    <div className="contactOptions">
                        <div className="contactEmail">
                            <Icon name="mail outline" size="large"/>
                            support@artrya.com
                        </div>
                    </div>
                </div>
            )
        }
        else if (key === "faq"){
            return(
                <div className="faq" style={{'width':'70%'}}>
                    <div className="faqSection">General<hr/></div>
                    <div className="faqQ">
                        <div onClick={() => this.toggleCollapse('q2')} style={{cursor:'pointer'}} >
                            <span>
                                <Button icon={this.getCollapse('q2', true)} className="transparent text"/>
                            </span>
                            <span>
                                Can I change the reporting format?
                            </span>
                        </div>
                        <div className={`ans ${this.getCollapse('q2')}`}>
                            ANS
                        </div>
                    </div>
                    <br/><br/><div className="faqSection">Analysis and Data<hr/></div>
                    <div className="faqQ">
                        <div onClick={() => this.toggleCollapse('q1')} style={{cursor:'pointer'}} >
                            <span>
                                <Button icon={this.getCollapse('q1', true)} className="transparent text"/>
                            </span>
                            <span>
                                How do I edit the vessel walls segmentation?
                            </span>
                        </div>
                        <div className={`ans ${this.getCollapse('q1')}`}>
                        ANS
                        </div>
                    </div>
                    <div className="faqQ">
                        <div onClick={() => this.toggleCollapse('q3')} style={{cursor:'pointer'}} >
                            <span>
                                <Button icon={this.getCollapse('q3', true)} className="transparent text"/>
                            </span>
                            <span>
                                How should I interpret the model confidence scores?
                            </span>
                        </div>
                        <div className={`ans ${this.getCollapse('q3')}`}>
                        ANS
                        </div>
                    </div>
                    <div className="faqQ">
                        <div onClick={() => this.toggleCollapse('q4')} style={{cursor:'pointer'}} >
                            <span>
                                <Button icon={this.getCollapse('q4', true)} className="transparent text"/>
                            </span>
                            <span>
                                How is Stenosis calculated?
                            </span>
                        </div>
                        <div className={`ans ${this.getCollapse('q4')}`}>
                        ANS
                        </div>
                    </div>
                    <div className="faqQ">
                        <div onClick={() => this.toggleCollapse('q5')} style={{cursor:'pointer'}} >
                            <span>
                                <Button icon={this.getCollapse('q5', true)} className="transparent text"/>
                            </span>
                            <span>
                                What methods are used to determine plaque composition?
                            </span>
                        </div>
                        <div className={`ans ${this.getCollapse('q5')}`}>
                        ANS
                        </div>
                    </div>
                    <br/><br/><div className="faqSection">Scan quality<hr/></div>
                    <div className="faqQ">
                        <div onClick={() => this.toggleCollapse('q6')} style={{cursor:'pointer'}} >
                            <span>
                                <Button icon={this.getCollapse('q6', true)} className="transparent text"/>
                            </span>
                            <span>
                                How is Scan Quality calculated?
                            </span>
                        </div>
                        <div className={`ans ${this.getCollapse('q6')}`}>
                        ANS
                        </div>
                    </div>
                    <div className="faqQ">
                        <div onClick={() => this.toggleCollapse('q7')} style={{cursor:'pointer'}} >
                            <span>
                                <Button icon={this.getCollapse('q7', true)} className="transparent text"/>
                            </span>
                            <span>
                                What determines your scan regection policy?
                            </span>
                        </div>
                        <div className={`ans ${this.getCollapse('q7')}`}>
                        ANS
                        </div>
                    </div>
                </div>
            )
        }
        else if (key === "suggestions"){
            let buttonText
            if (this.state.suggestionMsg === 'loading'){
                buttonText = (
                    <Loader active inline size="mini" style={{top:'20%'}}
                    className={`centered `}/>
                )
            }
            else if (this.state.suggestionMsg){
                buttonText = <div className="green">Thanks!</div>
            }
            else {
                buttonText = 'Make Suggestion'
            }
            return(
                <div className="suggestionForm">
                    <Form style={{"text-align":"left"}}>
                        <Form.Field width={8}>
                            <label>Topic</label>
                            <Dropdown selection defaultValue="Any" 
                            onChange={(e, {value}) => this.updateSuggestion('suggestionTopic', value)}
                            className="suggestionFormInput"
                            options={getDdOptions(null, this.suggestionTopics, 'list')}>
                            </Dropdown>
                        </Form.Field>
                        <Form.Field required={true}>
                            <label>Suggestion</label>
                                <Form.TextArea
                                    style={{minHeight:100}}
                                    onChange={(e) => this.updateSuggestion('suggestion', e.target.value)}
                                    className="suggestionFormInput"
                                    placeholder='...' 
                                />
                        </Form.Field>
                        <Button disabled={this.state.suggestionMsg}
                         onClick={this.submitSuggestion}>
                        {buttonText}</Button>
                        <div className="text">{this.state.msg}</div>
                    </Form>
                </div>
            )
        }
    }

    updateSuggestion(key, value){
        this.setState({[key]:value})
    }

    async submitSuggestion(){
        this.setState({suggestionMsg:'loading'})
        let row = {
            date:new Date(),
            type:'suggestion',
            data:JSON.stringify({
                suggestion:this.state.suggestion,
                suggestionTopic:this.state.suggestionTopic
            })
        }
        // TODO save to s43
        //let suggestion = this.state.suggestion
        let suggestion = true
        if (suggestion && suggestion.length){ 
            this.setState({suggestionMsg:true})
            console.log('saving suggestion', suggestion)
        }
        else {
            this.setState({suggestionMsg:false})
        }
    }

    render(){
        let key = this.props.match.params.key
        let contactCls = ((key === 'contact') ?  "active":null)
        let faqCls = ((key === 'faq') ?  "active":null)
        let suggestionCls = ((key === 'suggestions') ?  "active":null)
       
        return(
        <div>
            <div className="pageBody">
                <Menu widths={4} secondary className="helpButtons transparent">
                    <Menu.Item>
                    <Button 
                    onClick={() => this.changeSection('contact')}
                    className={contactCls}
                        >CONTACT</Button>
                    </Menu.Item>
                    <Menu.Item>
                    <Button 
                    onClick={() => this.changeSection('faq')}
                    className={faqCls}
                        >FAQ</Button>
                    </Menu.Item>
                    <Menu.Item>
                    <Button 
                    onClick={() => this.changeSection('suggestions')}
                    className={suggestionCls}
                        >SUGGESTIONS</Button>
                    </Menu.Item>
                </Menu>
                <div className="centeredBody">
                    {this.displaySection()}
                </div>
            </div>
        </div>
        )
    }
}

export default C