import React from 'react'
import { Menu, Icon, Image, Dropdown } from 'semantic-ui-react'
import { Link } from 'react-router-dom';

//import Amplify, { Storage } from 'aws-amplify';
//import awsconfig from '../aws-exports';
//Amplify.configure(awsconfig);
//Storage.configure({ level: 'public' });


class C extends React.Component {
    constructor(props) {
        super(props);
        this.state = {table: null}
    }

    componentDidMount(){

        if (this.props.data){
            console.log('scan quality data', this.props)
        }

        let headerRow = ["Vessel"]
        let rows = []
        
        this.props.vessels.map((vessel) => {
            let data = this.props.data[vessel]
            let row = [<td>{vessel}</td>]
            Object.entries(data).map(([k, v]) => {
                if (!headerRow.includes(k)){
                    headerRow.push(k)
                }
                if (typeof v === 'string'){
                    row.push(<td>{v}</td>)
                }
                else if (v) { // not null
                    row.push(<td>{v.toFixed(2)}</td>)
                }
                else {
                    row.push(<td>{v}</td>)
                }
                
            })
            rows.push(<tr>{row}</tr>)
        })
        
        headerRow = (
            <tr>
                {headerRow.map((v) => <th>{v}</th>)}
            </tr>
        )

        const quality = (
            <table>
                {headerRow}
                <tbody>
                    {rows}
                </tbody>
            </table>
        )
        
        /* CONFIDENCE METRICS TEMPORARILY REMOVED FOR UI_SUPPORT.PY
        let tc = this.props.data['Tracking Confidence']
        tc = tc.charAt(0).toUpperCase() + tc.slice(1)
        let wc = this.props.data['Wall Segmentation Confidence']
        wc = wc.charAt(0).toUpperCase() + wc.slice(1)
        const confidence = (
            <table>
            <tbody>
            <tr>
            <td>Centerline Tracking</td>
            <td>{tc}</td>
            </tr>
            <tr>
            <td>Vessel Segmentation</td>
            <td>{wc}</td>
            </tr>
            </tbody>
            </table>
            )
       */

        this.setState({
            quality: quality,
            //confidence: confidence
        })
    }

    render() {
        return (
            <div>

                <h4>Scan Quality</h4>
                {this.state.quality}
                
                <br/>
                {/* CONFIDENCE METRICS TEMPORARILY REMOVED FOR UI_SUPPORT.PY
                <h4>Confidence</h4>
                {this.state.confidence}
                */}
            
            </div>
        )
    }
}

export default C