import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { FaDrawPolygon } from 'react-icons/fa';
import { FiLayers, FiZoomIn } from 'react-icons/fi';
import { GiHistogram } from 'react-icons/gi';
import { MODE } from '../view-loader';

const ModeIcon = memo(function ModeIcon({ mode, size, color }) {
  switch (mode) {
    case MODE.annotation:
      return (
        <FaDrawPolygon size={size} color={color} title="Annotation mode" />
      );
    case MODE.zoom:
      return <FiZoomIn size={size} color={color} title="Zoom mode" />;
    case MODE.slice:
      return <FiLayers size={size} color={color} title="Slice mode" />;
    case MODE.window:
      return <GiHistogram size={size} color={color} title="Window mode" />;
    default:
      return null;
  }
});

ModeIcon.propTypes = {
  color: PropTypes.string,
  mode: PropTypes.string,
  size: PropTypes.number,
};

ModeIcon.defaultProps = {
  size: 18,
};

export { ModeIcon };
