import styled from 'styled-components';

const GAP = 0;

// Wrapper that wraps multiple stage containers
export const WrapperStyled = styled.section`
  display: flex;
  flex-wrap: nowrap;
  padding: ${GAP / 2}px;
`;

export const SliceContainerStyled = styled.div`
  margin: ${GAP / 2}px;

  > * {
    margin-top: ${GAP}px;
  }

  > *:first-child {
    margin-top: 0;
  }
`;

SliceContainerStyled.displayName = 'SliceContainer';
