
import React from 'react';
import { Link } from 'react-router-dom';
import { Table, Icon, Button, Loader, Modal } from 'semantic-ui-react'
import { getPopup } from './components/utils'
import { RequestForm } from './components/request_form'
import Error from './error'

var _ = require('lodash/core');

class C extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        view: 'table',
        showPriority: null,
        sortBy: 'patient_id'
      }
      this.props.changeState('errorPage', false)
      this.changePriority = this.changePriority.bind(this)
      window.db = this;
    }

    changePriority(priority){
      this.setState({
        showPriority: this.state.showPriority === priority ? null : priority
      })
    }


	// to control navbar
	componentWillMount(){
		this.props.changeState('onDashboard', true)
	}

	// to control navbar
	componentWillUnmount(){
		this.props.changeState('onDashboard', false)
	}

    render(){
		let dashboardData = this.props.dashboardData
		if (dashboardData){

			console.log("RENDERING DB", Object.values(dashboardData))

			let sorted

			if (this.state.sortBy === 'patient_id'){
				sorted = _.sortBy(Object.values(dashboardData), 'patient_id')
			}
			else if (this.state.sortBy === 'last_scan'){
				sorted = _.sortBy(Object.values(dashboardData), 'last_scan')
			}
			else {
				// default is sort by patient ids
				sorted = _.sortBy(Object.values(dashboardData), 'patient_id')
			}

			console.log("SORTED", sorted)

			const patientIDs = sorted.map((dct) => dct.patient_id)

			console.log('sorted patientIDS', patientIDs)

			let viewIcon, altView, altState
			if (this.state.view === 'table'){
				altState = 'tile'
				viewIcon = 'table'
				altView = 'Tile'
			}
			else {
				altState = 'table'
				viewIcon = 'list'
				altView = 'Table'
			}

			let tableHeader = (
				<div className="dashboardHeader">

					<span className="title">
						<span>Patient Dashboard</span>
						<span>Envision Wembley</span>
					</span>

					<span className="prioritiesLegend">
						<span className={`lowPriority
							${this.state.showPriority === "low" ? "selected" : null}
							`}
						onClick={() => this.changePriority('low')}
						>
							Low
						</span>
						<span className={`mediumPriority
							${this.state.showPriority === "medium" ? "selected" : null}
							`}
							onClick={() => this.changePriority('medium')}
						>
							Medium
							</span>
						<span className={`highPriority
							${this.state.showPriority === "high" ? "selected" : null}
							`}
							onClick={() => this.changePriority('high')}
						>
							High
						</span>
					</span>

					<Button.Group>
						<RequestForm/>
						{getPopup(
							<Button
							className="invert" icon={viewIcon} size="huge"
							onClick={() => this.setState({view: altState})}
							/>,
							`${altView} view`,
							{basic: true, position: "left center"}
						)}
					</Button.Group>

				</div>
			)

			if (this.state.view === 'table') {
				let alternate = 0
				let tableRows = []
				patientIDs.map((patientID) => {
					let data = dashboardData[patientID]

					let patientName = patientID;
					if (data['patient_plaintext']) {
						patientName = data['patient_plaintext'].split(',')[0];
						patientName = patientName.replace("^", ", ");
					}

					let dbStatus = data.status
					if (data.status_reason){
						dbStatus = getPopup(
							<div>{dbStatus}</div>,
							data.status_reason
						)
					}

					if (this.state.showPriority && this.state.showPriority !== data.priority){
						// do nothing
					}

					else {
						let row = (
						<tr className={alternate ? 'alternate': null}>
							{/* patient id */}
							<td>
								<Link to={`/patient/${patientID}`}>
									{/*patientID*/}
									{ patientName }
								</Link>
							</td>
							{/* last scan */}
							<td>
								<Link to={`/patient/${patientID}`}>
								{data.last_scan}
								</Link>
							</td>
							{/* priority */}
							<td className={`${data.priority}Priority`}>
								<Link to={`/patient/${patientID}`}>
								{data.priority.charAt(0).toUpperCase() + data.priority.slice(1)}
								</Link>
							</td>
							{/* status */}
							<td className={data.status}>
								<Link to={`/patient/${patientID}`}>
								{dbStatus}
								</Link>
							</td>
							{/* stenosis */}
							<td>
								<Link to={`/patient/${patientID}`}>
								{data.stenosis}
								</Link>
							</td>
							{/* ca plaque */}
							<td>
								<Link to={`/patient/${patientID}`}>
									{parseFloat(data.calcium_score).toFixed(2)}
								</Link>
							</td>
							{/* vulnerable plaque */}
							<td>
								<Link to={`/patient/${patientID}`}>
									{data.vulnerable_plaque}
								{/*
									{(data.vulnerable_plaque === 'Present' ?
										<Icon color='green' name='checkmark' size='large' /> :
										<Icon color='red' name='minus' size='large' />
									)}
								*/}
								</Link>
							</td>
						</tr>
					)
					alternate = alternate ? 0: 1
					tableRows.push(row)
					}
				})

				return(
					<div className="pageBody">
						<br/>
						{tableHeader}
						<table className="mainTable dashboardTable">
							<tbody>
								<tr className='headerRow'>
									<th
									className={(this.state.sortBy === 'patient_id') ? 'sortBy': null}
									onClick={() => this.setState({sortBy: 'patient_id'})}>
										<span>Patient</span>
										{(this.state.sortBy === 'patient_id') ? <Icon name="angle down"/> : null}
									</th>
									<th
									className={(this.state.sortBy === 'last_scan') ? 'sortBy': null}
									onClick={() => this.setState({sortBy: 'last_scan'})}>
										<span>Last Scan</span>
										{(this.state.sortBy === 'last_scan') ? <Icon name="angle down"/> : null}
									</th>
									<th
									className={(this.state.sortBy === 'priority') ? 'sortBy': null}
									onClick={() => this.setState({sortBy: 'priority'})}>
										<span>Priority</span>
										{(this.state.sortBy === 'priority') ? <Icon name="angle down"/> : null}
									</th>
									<th
									className={(this.state.sortBy === 'status') ? 'sortBy': null}
									onClick={() => this.setState({sortBy: 'status'})}>
										<span>Status</span>
										{(this.state.sortBy === 'status') ? <Icon name="angle down"/> : null}
									</th>
									<th
									className={(this.state.sortBy === 'stenosis') ? 'sortBy': null}
									onClick={() => this.setState({sortBy: 'stenosis'})}>
										<span>Stenosis</span>
										{(this.state.sortBy === 'stenosis') ? <Icon name="angle down"/> : null}
									</th>
									<th
									className={(this.state.sortBy === 'calcium_score') ? 'sortBy': null}
									onClick={() => this.setState({sortBy: 'calcium_score'})}>
										<span>Calcium Score</span>
										{(this.state.sortBy === 'calcium_score') ? <Icon name="angle down"/> : null}
									</th>
									<th
									className={(this.state.sortBy === 'vulnerbale_plaque') ? 'sortBy': null}
									onClick={() => this.setState({sortBy: 'vulnerbale_plaque'})}>
										<span>Vulnerable Plaque</span>
										{(this.state.sortBy === 'vulnerbale_plaque') ? <Icon name="angle down"/> : null}
									</th>
								</tr>
								{tableRows}
							</tbody>
						</table>
					</div>
				)
			}
			// TILE VIEW
			else if (this.state.view === 'tile'){
				let tileRows = []
				const lenTileRow = 5
				let row = []
				patientIDs.map((patientID) => {
					let data = dashboardData[patientID]

					let patientName = patientID;

					if (data['patient_plaintext']) {
						patientName = data['patient_plaintext'].split(',')[0];
						patientName = patientName.replace("^", ", ");
					}

					let dbStatus = data.status
					if (data.status_reason){
						dbStatus = getPopup(
							<div>{dbStatus}</div>,
							data.status_reason
						)
					}

					if (this.state.showPriority && this.state.showPriority !== data.priority){
						// do nothing
					}
					else {
						row.push(
							<Link to={`/patient/${patientID}`}>
								<div className={`tile ${data.priority}Priority`}>
									<h3 className="centered">{patientName}</h3>
									<Table fixed basic>
										<Table.Row>
											<Table.Cell> Last Scan </Table.Cell>
											<Table.Cell> {data.last_scan} </Table.Cell>
										</Table.Row>
										<Table.Row>
											<Table.Cell> Status </Table.Cell>
											<Table.Cell>
												{dbStatus}
											</Table.Cell>
										</Table.Row>
										<Table.Row>
											<Table.Cell> Stenosis </Table.Cell>
											<Table.Cell> {data.stenosis} </Table.Cell>
										</Table.Row>
										<Table.Row>
											<Table.Cell> Calcium Score </Table.Cell>
											<Table.Cell>
												{parseFloat(data.calcium_score).toFixed(2)}
											</Table.Cell>
										</Table.Row>
										<Table.Row>
											<Table.Cell> Vulnerable Plaque </Table.Cell>
											<Table.Cell>
												{data.vulnerable_plaque}
												{/*
												{(data.vulnerablePlaque === 'Present' ?
												<Icon name='checkmark' size='large' /> :
												<Icon name='minus' bordered={false} size='large' />
												)}
												*/}
											</Table.Cell>
										</Table.Row>
									</Table>
								</div>
							</Link>
						)
					}
					if (row.length === lenTileRow) {
						tileRows.push(
							<div className="tileRow">
								{row}
							</div>
						)
						row = []
					}
				})

				// push remaining row that didnt meet max length
				tileRows.push(row)

				return (
					<div className="pageBody dashboardTiles">
						{tableHeader}
						{tileRows}
					</div>
				)
			}
		}
		else if (this.props.permissionError) {
			return <Error {...this.props} />
		}
		else {
			return (
				<Loader active inline='centered' style={{marginTop: "20%"}}>
					Securely Loading Envision Wembley Dashboard
				</Loader>
			)
		}
	}
}

C.displayName = 'Dashboard'

export default C
