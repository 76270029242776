import React from 'react';
import { Icon } from 'semantic-ui-react'

export class DownloadViewer extends React.Component {
    constructor(props) {
        super(props);
        this.state = { speed: '', mousestop: false };
        this.stopClock = 0;
        this.imageAddr = "https://hatchd.cdn.prismic.io/hatchd/ff43848323813805813ca7f61a8b6be247603336_default-realted-item02.jpg";
        this.downloadSize = 211050; //bytes
        this.stopCheck = null;
    }

    MeasureConnectionSpeed = () => {
        var startTime = (new Date()).getTime();
        var endTime, cahceBuster, duration, bitsLoaded, bps, mbps, kbps;
        var download = new Image();
        download.onload = () => {
            endTime = (new Date()).getTime();
            duration = (endTime - startTime) / 1000;
            bitsLoaded = this.downloadSize * 8;
            bps = (bitsLoaded / duration).toFixed(2);
            kbps = (bps / 1024).toFixed(2);
            mbps = (kbps / 1024).toFixed(2);
            this.setState({speed: `${kbps} Kbps`});
        }

        cahceBuster = "?nnn=" + startTime;
        download.src = this.imageAddr + cahceBuster;
    }

    InitiateSpeedDetection = () => {
        setTimeout(this.MeasureConnectionSpeed, 1);
    }

    componentDidMount() {
        this.InitiateSpeedDetection();
    }

    render() {
        return (
            <div className="testing">
                { this.state.speed ? (
                    <>
						<Icon name="download"></Icon>
                        {this.state.speed}
                    </>
                ) : (
                    <Icon loading name="spinner"></Icon>
                )}
            </div>
        );
    }
}