export const timings = {
  instant: 100,
  faster: 200,
  fast: 250,
  medium: 350,
  slow: 500,
};

export const timingsMs = {
  instant: `${timings.instant}ms`,
  faster: `${timings.faster}ms`,
  fast: `${timings.fast}ms`,
  medium: `${timings.medium}ms`,
  slow: `${timings.slow}ms`,
};
